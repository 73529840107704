aside.cow-preview {
    background-color: var(--dashboard-background);
    padding: 0.2rem;
    display: grid;
    grid-template-areas:
        "header"
        "graph";

    grid-column-start: 1;
    grid-column-end: -1;
    position: relative;
    width: calc(100% + 2rem);
    box-sizing: border-box;
}

aside.cow-preview > header {
    grid-area: header;

    display: grid;
    grid-template-areas:
        "title title fullscreen-button"
        "title title identity"
        "title title breed-birthdate-place"
        "tags sessions .";
    grid-template-columns: 0.6fr 1fr 0.5fr;
    grid-template-rows: 1fr 1.4fr 2fr 3fr;
    padding: 0.1rem;
}

aside.cow-preview > div.body {
    grid-area: graph;
    /* display: grid;
    grid-template-areas: "picker" "chart";
    grid-template-rows: 2rem auto; */
}

aside.cow-preview > header > h1,
aside.cow-preview > header h4 {
    font-weight: 300;
    white-space: nowrap;
    margin: 0;
}
aside.cow-preview > header > h4 {
    font-size: 0.8em;
    place-self: start end;
}

aside.cow-preview > header > h1 {
    grid-area: title;
    font-size: 1.8rem;
    overflow: hidden;
    text-overflow: ellipsis;
}
aside.cow-preview header > h1 > strong {
    margin-right: 0.5rem;
}
aside.cow-preview header > span.fullscreen-button {
    grid-area: fullscreen-button;
    background-image: url(../../assets/normalScreen.svg);
    background-repeat: no-repeat;
    background-size: 1.6rem;
    width: 1.6rem;
    height: 1.6rem;
    justify-self: end;
    margin-top: 0.3rem;
    margin-right: 0.3rem;
}
aside.cow-preview header > span.fullscreen-button.off {
    background-image: url(../../assets/fullScreen.svg);
}
aside.cow-preview > header > h4.identity-number {
    grid-area: identity;
}

aside.cow-preview > header > div.breed-birthdate-place {
    grid-area: breed-birthdate-place;
    display: flex;
    flex-direction: row;
    font-size: 0.8em;
    justify-self: end;
}
aside.cow-preview > header > .sessions {
    grid-area: sessions;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}
aside.cow-preview > header > div.sessions h4 {
    font-size: 0.8em;
    margin: 0;
}
aside.cow-preview .pickers {
    grid-area: picker;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
aside.cow-preview .pickers > div {
    display: flex;
    flex-direction: row;
    gap: 0.4rem;
}
aside.cow-preview .pickers .graph-picker {
    background-color: #151515;
    background-blend-mode: multiply;
    border-radius: 12px;
    width: auto;
    height: min-content;
    padding: 0 1em 0 1em;
    line-height: 1.8rem;
    cursor: pointer;
}
aside.cow-preview .pickers .graph-picker.disabled {
    opacity: 0.5;
    pointer-events: none;
}
aside.cow-preview div.sessions .session:before {
    width: 1em;
    height: 1em;
    content: "\00a0";
    display: inline-block;
    margin-right: 0.25em;
    background-image: linear-gradient(180deg, #35505a 0%, #556970 100%);
    border-radius: 0.2em;
    vertical-align: middle;
    margin-bottom: 0.1em;
}
aside.cow-preview div.sessions .session.stable:before {
    background-image: linear-gradient(180deg, #629f26 0%, #146c3c 100%);
}
aside.cow-preview div.sessions .session.unstable:before {
    background-image: linear-gradient(180deg, #f3492c 0%, #bc4040 100%);
}

aside.cow-preview > header > div.sessions h4 strong {
    cursor: pointer;
    text-decoration: underline;
}
aside.cow-preview > header > .tags {
    grid-area: tags;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
}
aside.cow-preview > header > .tags span {
    font-weight: 300;
    margin-left: 0.3em;
}
aside.cow-preview header > .tags li:before {
    width: 1em;
    height: 1em;
    content: "\00a0";
    display: inline-block;
    margin-right: 0.25em;
    background-image: var(--dead-cow);
    /*For Safari flushing problem*/
    background-image: linear-gradient(180deg, #35505a 0%, #556970 100%);
    border-radius: 0.2em;
    vertical-align: middle;
    margin-bottom: 0.1em;
}
aside.cow-preview header > .tags li {
    display: inline-block;
}

aside.cow-preview header > .tags > li.tracked:before,
aside.cow-preview header > .tags > li.pregnant:before,
aside.cow-preview header > .tags > li.post-partum:before,
aside.cow-preview header > .tags > li.estrous:before,
aside.cow-preview header > .tags > li.inseminated:before {
    background-image: var(--good-cow);
    /*For Safari flushing problem*/
    background-image: linear-gradient(180deg, #629f26 0%, #146c3c 100%);
}

aside.cow-preview header > .tags > li.calving:before {
    background-image: var(--calving-cow);
    /*For Safari flushing problem*/
    background-image: linear-gradient(180deg, #2d9cdb 0%, #134561 100%);
}

aside.cow-preview header > .tags > li.hypothermia:before,
aside.cow-preview header > .tags > li.fever:before,
aside.cow-preview header > .tags > li.heat:before {
    background-image: var(--bad-cow);
    /*For Safari flushing problem*/
    background-image: linear-gradient(180deg, #f3492c 0%, #bc4040 100%);
}
aside.cow-preview header > .tags > li.due:before {
    background-image: (--due-cow);
    background-image: linear-gradient(180deg, #83d2ff 0%, #2d9cdb 100%);
}
aside.cow-preview header > .tags > li.overdue:before {
    background-image: var(--bad-cow-handled);
    /*For Safari flushing problem*/
    background-image: linear-gradient(180deg, #ac2e10 0%, #551717 100%);
}
@media (max-width: 35rem) {
    aside.cow-preview {
        min-height: 19.6rem;
        background-color: var(--dashboard-background);
        padding: 0;
        z-index: 5;
    }
    aside.cow-preview div.graph {
        display: grid;
        grid-template-areas:
            "header"
            "graph";
        box-sizing: border-box;
        min-height: 19.6rem;
        background-color: var(--dashboard-background);
    }
    aside.cow-preview div.event-group {
        display: grid;
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
        min-height: 19.6rem;
        background-color: var(--dashboard-background);
        box-sizing: border-box;
    }
    aside.cow-preview div.event-group div.cow-events {
        align-self: start;
    }
    aside.cow-preview div.cow-options {
        display: grid;
        grid-template-areas:
            "options-header"
            "buttons";
        background-color: var(--dashboard-background);
        box-sizing: border-box;
        min-height: 19.6rem;
    }

    aside.cow-preview div.graph > div {
        grid-area: graph;
        border-left: none;
        background-color: var(--dashboard-background);
        height: 9rem;
        align-self: end;
    }

    aside.cow-preview div.graph header {
        grid-area: header;
        display: grid;
        grid-template-areas:
            "title fullscreen-button"
            "identity temperature"
            "breed-birthdate-place temperature"
            "sessions temperature"
            "tags temperature"
            "picker picker";
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 2.4rem 1.4rem 1.4rem 1.8rem 2.4rem 0.2rem;
        padding-left: 0.2rem;
        background-color: var(--dashboard-background);
    }
    /* aside.cow-preview header div.pickers {
        grid-area: picker;
    } */
    aside.cow-preview div.graph > header > h1 {
        grid-area: title;
        font-size: 1.8rem;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    aside.cow-preview div.graph > header > h4.identity-number {
        grid-area: identity;
        margin: 0.6rem 0.4rem 0 0;
        justify-self: start;
        font-weight: 500;
    }
    aside.cow-preview div.graph > header > .temperature {
        grid-area: temperature;
        align-self: center;
        font-size: 1em;
    }
    aside.cow-preview div.graph > header > data.temperature {
        font-size: 3em;
        padding: 0.1rem;
        place-self: start end;
        font-kerning: normal;
        pointer-events: none;
        text-align: end;
        align-self: center;
    }
    aside.cow-preview div.graph > header > data.temperature time {
        font-size: 1rem;
        display: grid;
        align-self: end;
    }
    aside.cow-preview
        div.graph
        > header
        > data.temperature
        .temperature-sign::after {
        content: "°C";
        font-weight: lighter;
        letter-spacing: -0.1em;
        margin-right: 0.1em;
    }
    aside.cow-preview div.graph > header > div.breed-birthdate-place {
        grid-area: breed-birthdate-place;
        height: 1rem;
        display: flex;
        flex-direction: row;
        font-size: 0.8em;
        justify-self: start;
        margin: 0.2rem 0.4rem 0 0;
    }
    aside.cow-preview div.graph > header > div.breed-birthdate h4 {
        font-weight: 500;
    }
    aside.cow-preview div.graph > header > .sessions {
        grid-area: sessions;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-self: start;
        margin-right: 0.4rem;
    }
    aside.cow-preview div.graph > header > .sessions .session {
        margin-top: 0.3rem;
    }
    aside.cow-preview div.graph > header > div.breed-birthdate-place h4 {
        font-size: 0.8em;
    }

    aside.cow-preview div.graph > header > h1,
    aside.cow-preview div.graph > header h4 {
        font-weight: 300;
        white-space: nowrap;
        margin: 0;
    }
    aside.cow-preview div.graph > header > h4 {
        font-size: 0.8em;
        place-self: start end;
    }

    aside.cow-preview div.graph > header > div.sessions h4 {
        font-size: 1em;
        margin: 0;
    }
    aside.cow-preview div.graph > header > .tags {
        grid-area: tags;
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
    }
    aside.cow-preview div.graph > header > .tags span {
        font-weight: 300;
        margin-left: 0.3em;
    }
    aside.cow-preview div.graph > header > a.fullscreen-button {
        grid-area: fullscreen-button;
        background-image: url(./../../assets/fullScreen.svg);
        background-repeat: no-repeat;
        border: none;
        height: 1.8rem;
        width: 1.8rem;
        justify-self: end;
        margin-top: 0.5rem;
    }
    aside.cow-preview div > header > div.sessions h4 strong {
        cursor: pointer;
        text-decoration: underline;
    }
    aside.cow-preview div.graph > header > .tags > li.calving:before {
        background-image: var(--calving-cow);
        background-image: linear-gradient(180deg, #2d9cdb 0%, #134561 100%);
    }

    aside.cow-preview div.graph > header > .tags > li.hypothermia:before,
    aside.cow-preview div.graph > header > .tags > li.fever:before {
        background-image: var(--bad-cow);
        /*For Safari flushing problem*/
        background-image: linear-gradient(180deg, #f3492c 0%, #bc4040 100%);
    }
    aside.cow-preview div.graph > header > .tags > li.tracked:before,
    aside.cow-preview div.graph > header > .tags > li.pregnant:before,
    aside.cow-preview div.graph > header > .tags > li.estrous:before,
    aside.cow-preview div.graph > header > .tags > li.inseminated:before {
        background-image: var(--good-cow);
        /*For Safari flushing problem*/
        background-image: linear-gradient(180deg, #629f26 0%, #146c3c 100%);
    }

    aside.cow-preview div.graph > header > .tags > li.due:before {
        background-image: var(--due-cow);
        /*For Safari flushing problem*/
        background-image: linear-gradient(180deg, #83d2ff 0%, #2d9cdb 100%);
    }

    aside.cow-preview div.graph > header > .tags > li.overdue:before {
        background-image: var(--bad-cow-handled);
        /*For Safari flushing problem*/
        background-image: linear-gradient(180deg, #ac2e10 0%, #551717 100%);
    }
    aside.cow-preview div.cow-options header {
        grid-area: options-header;
        display: grid;
        grid-template-areas:
            "title ."
            "title ."
            "tags sessions"
            "tags sessions";
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1.4rem 1.5rem 1.4rem 3rem;
        padding-left: 0.2rem;
        background-color: var(--dashboard-background);
    }
    aside.cow-preview div.cow-options div {
        grid-area: buttons;
        border-left: none;
        background-color: var(--dashboard-background);
    }
    aside.cow-preview div.cow-options > header > h1 {
        grid-area: title;
        font-size: 1.8rem;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    aside.cow-preview div.cow-options > header > .sessions {
        grid-area: sessions;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-self: end;
        margin-right: 0.4rem;
        height: max-content;
    }
    aside.cow-preview div.cow-options > header > .sessions .session {
        margin-top: 0.3rem;
    }

    aside.cow-preview div.cow-options > header > h1,
    aside.cow-preview div.cow-options > header h4 {
        font-weight: 300;
        white-space: nowrap;
        margin: 0;
    }
    aside.cow-preview div.cow-options > header > h4 {
        font-size: 0.8em;
        place-self: start end;
    }

    aside.cow-preview div.cow-options > header > div.sessions h4 {
        font-size: 1em;
        margin: 0;
    }
    aside.cow-preview div.cow-options > header > .tags {
        grid-area: tags;
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
        height: max-content;
    }
    aside.cow-preview div.cow-options > header > .tags span {
        font-weight: 300;
    }
    aside.cow-preview div.cow-options > header > .tags li:before {
        width: 1em;
        height: 1em;
        content: "\00a0";
        display: inline-block;
        margin-right: 0.25em;
        background-image: var(--dead-cow);
        /*For Safari flushing problem*/
        background-image: linear-gradient(180deg, #35505a 0%, #556970 100%);
        border-radius: 0.2em;
        vertical-align: middle;
        margin-bottom: 0.1em;
    }
    aside.cow-preview div.cow-options > header > .tags li {
        display: inline-block;
    }
    aside.cow-preview div.cow-options > header > .tags > li.calving:before {
        background-image: var(--calving-cow);
        background-image: linear-gradient(180deg, #2d9cdb 0%, #134561 100%);
    }

    aside.cow-preview div.cow-options > header > .tags > li.hypothermia:before,
    aside.cow-preview div.cow-options > header > .tags > li.fever:before {
        background-image: var(--bad-cow);
        /*For Safari flushing problem*/
        background-image: linear-gradient(180deg, #f3492c 0%, #bc4040 100%);
    }
    aside.cow-preview div.cow-options > header > .tags > li.tracked:before,
    aside.cow-preview div.cow-options > header > .tags > li.pregnant:before,
    aside.cow-preview div.cow-options > header > .tags > li.estrous:before,
    aside.cow-preview div.cow-options > header > .tags > li.inseminated:before {
        background-image: var(--good-cow);
        /*For Safari flushing problem*/
        background-image: linear-gradient(180deg, #629f26 0%, #146c3c 100%);
    }
    aside.cow-preview div.cow-options > header > .tags > li.due:before {
        background-image: var(--due-cow);
        /*For Safari flushing problem*/
        background-image: linear-gradient(180deg, #83d2ff 0%, #2d9cdb 100%);
    }
    aside.cow-preview div.cow-options > header > .tags > li.overdue:before {
        background-image: var(--bad-cow-handled);
        /*For Safari flushing problem*/
        background-image: linear-gradient(180deg, #ac2e10 0%, #551717 100%);
    }
    aside.cow-preview div.cow-options div button {
        background-repeat: no-repeat;
        margin: 0;
        margin-left: 0.5rem;
        margin-bottom: 0.3rem;
        background-position: top;
        background-color: var(--dashboard-background);
        background-size: 2.3rem;
        border: solid 1px var(--dashboard-box-border);
        height: 3.5rem;
        font-size: 0.9rem;
        font-weight: 200;
        padding-top: 2rem;
    }
    aside.cow-preview div.cow-options div .edit {
        --edit: url("../../assets/Edit.svg");
        background-image: var(--edit);
        min-width: 4rem;
    }
    aside.cow-preview div.cow-options div .earTag {
        --note: url("../../assets/EarTag.svg");
        background-image: var(--note);
    }
    aside.cow-preview div.cow-options div .Tsens {
        --note: url("../../assets/Tsens.svg");
        background-image: var(--note);
    }

    aside.cow-preview::-webkit-scrollbar {
        display: none;
    }
}

@media (min-width: 29rem) and (max-width: 40rem) {
    aside.cow-preview {
        height: 16rem;
        z-index: 1;
    }
    aside.cow-preview > div {
        border-left: none;
    }
    aside.cow-preview > header {
        grid-template-areas:
            "title fullscreen-button"
            "title identity"
            "tags breed-birthdate-place"
            "tags sessions";
        grid-template-columns: 14rem 1fr;
        grid-template-rows: 1.4rem 1.4rem 1.3rem;
    }
    aside.cow-preview > header > h4.identity-number {
        margin: 0.6rem 0.4rem 0 0;
        justify-self: end;
    }
    aside.cow-preview > header > div.breed-birthdate-place {
        margin: 0.2rem 0.4rem 0 0;
    }
    aside.cow-preview > header > .sessions {
        flex-direction: column;
        justify-self: end;
        margin-right: 0.4rem;
    }
    aside.cow-preview > header > .sessions .session {
        margin-top: 0.3rem;
    }
    aside.cow-preview > header > div.breed-birthdate-place h4 {
        font-size: 0.8em;
    }
    aside.cow-preview > header > .sessions .session {
        margin-top: 0.3rem;
    }
    aside.cow-preview > header > div.breed-birthdate h4 {
        font-size: 0.8em;
    }
}
@media (min-width: 40rem) and (max-width: 52rem), (min-width: 64rem) {
    aside.cow-preview {
        grid-template-areas: "header graph";
        grid-template-columns: min-content 1fr;
        grid-column-gap: 1rem;
        z-index: 1;
    }
    aside.cow-preview > header {
        grid-template-areas:
            "title fullscreen-button"
            "identity identity"
            "breed-birthdate-place ."
            "tags tags"
            "sessions sessions";
        grid-template-columns: max-content auto;
        grid-template-rows: 2.2rem 1rem 2rem 5rem auto;
    }
    aside.cow-preview > header h4,
    aside.cow-preview > header div.breed-birthdate-place {
        justify-self: start;
    }
    main.dashboard.fullscreen aside.cow-preview > header .identity-number,
    main.dashboard.fullscreen
        aside.cow-preview
        > header
        div.breed-birthdate
        h4 {
        font-size: 1.2rem;
    }
    aside.cow-preview > header > .tags {
        justify-self: left center;
    }
    main.dashboard aside.cow-preview header > span.fullscreen-button {
        position: absolute;
    }
    main.dashboard.fullscreen
        aside.cow-preview
        header
        > span.fullscreen-button {
        position: relative;
    }
    aside.cow-preview > header > h1 {
        width: 10rem;
    }
    main.dashboard aside.cow-preview div .data-graph {
        height: 7.6rem;
    }
}

aside.cow-preview > header > h4.session > button {
    background-color: var(--dashboard-widget-background);
    color: var(--dashboard-background);
    border: none;
    margin: 0 0.5em;
    border-radius: 0.25em;
    outline: none;
}

aside.cow-preview > header > h4.session > button:hover {
    background-color: var(--dashboard-text-color);
    color: var(--dashboard-background);
}

/* Full Screen */
main.dashboard.fullscreen aside.cow-preview {
    grid-template-areas:
        "header"
        "graph";
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    margin-left: 0;
    width: 100%;
    height: 100vh;
}
main.dashboard.fullscreen aside.cow-preview > header {
    grid-template-areas:
        "title fullscreen-button"
        "title identity"
        ". breed-birthdate-place"
        "tags tags"
        "sessions sessions";
    grid-template-columns: auto auto;
    grid-template-rows: min-content 1.8rem 0.8rem 3.5rem auto;
}
main.dashboard.fullscreen aside.cow-preview > header > h4 {
    justify-self: end;
}
main.dashboard.fullscreen aside.cow-preview > header > div.breed-birthdate {
    justify-self: end;
}
main.dashboard.fullscreen aside.cow-preview > header > div.breed-birthdate h4 {
    font-weight: 500;
}
main.dashboard.fullscreen aside.cow-preview > header > h4.identity-number {
    margin-top: 0.5rem;
    font-weight: 500;
}
main.dashboard.fullscreen aside.cow-preview div .data-graph {
    height: 52vh;
}
@media screen and (orientation: portrait) {
    main.dashboard.fullscreen aside.cow-preview {
        transform: rotate(90deg);
        transform-origin: left top;
        height: 100vw;
        width: 100vh;
        overflow-x: hidden;
        position: absolute;
        top: 0;
        left: 100vw;
    }
    main.dashboard.fullscreen aside.cow-preview > header .identity-number,
    main.dashboard.fullscreen
        aside.cow-preview
        > header
        div.breed-birthdate
        h4 {
        font-size: 1.2rem;
    }
    main.dashboard.fullscreen aside.cow-preview div .data-graph {
        height: 52vw;
    }
}
/* Safari */
@media not all and (min-resolution: 0.001dpcm) {
    @media (max-width: 35rem) {
        aside.cow-preview div.graph {
            padding-left: 0.1rem;
            padding-right: 0.1rem;
        }
        aside.cow-preview div.event-group {
            padding-left: 0.1rem;
            padding-right: 0.1rem;
        }
    }
    @media (orientation: landscape) {
        main.dashboard.fullscreen aside.cow-preview {
            width: 100vw;
        }
        aside.cow-preview > div {
            align-self: end;
        }
    }
    @media (min-width: 48rem) and (orientation: portrait),
        (min-width: 64rem) and (orientation: landscape) {
        main.dashboard.fullscreen aside.cow-preview div .data-graph {
            height: 20rem;
        }
        aside.cow-preview div .data-graph data.temperature {
            font-size: 3.3em;
            text-align: end;
        }
        aside.cow-preview div .data-graph data.temperature time {
            font-size: 1rem;
        }
        main.dashboard.fullscreen
            aside.cow-preview
            div
            .data-graph
            data.temperature {
            font-size: 4em;
        }
        main.dashboard.fullscreen
            aside.cow-preview
            div
            .data-graph
            data.temperature
            time {
            font-size: 1.5rem;
        }
    }
}

/* For iPad */
@media (min-width: 48rem) and (orientation: portrait),
    (min-width: 64rem) and (orientation: landscape) {
    aside.cow-preview {
        min-height: 24rem;
        background-color: var(--dashboard-background);
        padding: 0;
        z-index: 5;
    }
    aside.cow-preview div.graph {
        display: grid;
        grid-template-areas:
            "header"
            "graph";
        box-sizing: border-box;
        min-height: 24rem;
        background-color: var(--dashboard-background);
        width: 100vw;
    }
    aside.cow-preview div.event-group {
        display: grid;
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
        min-height: 24rem;
        background-color: var(--dashboard-background);
        box-sizing: border-box;
        width: 100vw;
    }
    aside.cow-preview div.event-group div.cow-events {
        align-self: start;
    }
    aside.cow-preview div.cow-options {
        display: grid;
        grid-template-areas:
            "options-header"
            "buttons";
        background-color: var(--dashboard-background);
        box-sizing: border-box;
        min-height: 24rem;
        width: 100vw;
    }

    aside.cow-preview .fullscreen-button {
        position: absolute;
        top: 0.3rem;
        right: 0.3rem;
    }

    aside.cow-preview div.graph > div {
        grid-area: graph;
        border-left: none;
        background-color: var(--dashboard-background);
        height: 12.1rem;
        align-self: end;
    }

    aside.cow-preview div.graph header {
        grid-area: header;
        display: grid;
        grid-template-areas:
            "title fullscreen-button"
            "identity temperature"
            "breed-birthdate temperature"
            "sessions temperature"
            "tags temperature"
            "picker picker";
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 2.4rem 2rem 2rem 2rem 3.3rem 0.2rem;
        padding-left: 0.2rem;
        background-color: var(--dashboard-background);
    }
    /* aside.cow-preview header div.pickers {
        grid-area: picker;
    } */
    aside.cow-preview div.graph > header > h1 {
        grid-area: title;
        font-size: 2rem;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    aside.cow-preview div.graph > header > h4.identity-number {
        grid-area: identity;
        margin: 0.6rem 0.4rem 0 0;
        justify-self: start;
        font-weight: 500;
        font-size: larger;
    }
    aside.cow-preview div.graph > header > .temperature {
        grid-area: temperature;
        align-self: center;
        font-size: 1em;
    }
    aside.cow-preview div.graph > header > data.temperature {
        font-size: 3em;
        padding: 0.1rem;
        place-self: start end;
        font-kerning: normal;
        pointer-events: none;
        text-align: end;
        align-self: center;
    }
    aside.cow-preview div.graph > header > data.temperature time {
        font-size: 1rem;
        display: grid;
        align-self: end;
    }
    aside.cow-preview
        div.graph
        > header
        > data.temperature
        .temperature-sign::after {
        content: "°C";
        font-weight: lighter;
        letter-spacing: -0.1em;
        margin-right: 0.1em;
    }
    aside.cow-preview div.graph > header > div.breed-birthdate {
        grid-area: breed-birthdate;
        height: 1rem;
        display: flex;
        flex-direction: row;
        font-size: 1.2em;
        justify-self: start;
        margin: 0.2rem 0.4rem 0 0;
    }
    aside.cow-preview div.graph > header > div.breed-birthdate h4 {
        font-weight: 500;
    }
    aside.cow-preview div.graph > header > .sessions {
        grid-area: sessions;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-self: start;
        margin-right: 0.4rem;
        font-size: larger;
    }
    aside.cow-preview div.graph > header > .sessions .session {
        margin-top: 0.3rem;
    }
    aside.cow-preview div.graph > header > div.breed-birthdate h4 {
        font-size: 0.8em;
    }

    aside.cow-preview div.graph > header > h1,
    aside.cow-preview div.graph > header h4 {
        font-weight: 300;
        white-space: nowrap;
        margin: 0;
    }
    aside.cow-preview div.graph > header > h4 {
        font-size: 0.8em;
        place-self: start end;
    }

    aside.cow-preview div.graph > header > div.sessions h4 {
        font-size: 1em;
        margin: 0;
    }
    aside.cow-preview div.graph > header > .tags {
        grid-area: tags;
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
        font-size: larger;
    }
    aside.cow-preview div.graph > header > .tags span {
        font-weight: 300;
        margin-left: 0.3em;
    }
    aside.cow-preview div.graph > header > a.fullscreen-button {
        grid-area: fullscreen-button;
        background-image: url(./../../assets/fullScreen.svg);
        background-repeat: no-repeat;
        border: none;
        height: 1.8rem;
        width: 1.8rem;
        justify-self: end;
        margin-top: 0.5rem;
    }
    aside.cow-preview div > header > div.sessions h4 strong {
        cursor: pointer;
        text-decoration: underline;
    }
    aside.cow-preview div.graph > header > .tags > li.calving:before,
    aside.cow-preview div.graph > header > .tags > li.hypothermia:before,
    aside.cow-preview div.graph > header > .tags > li.fever:before,
    aside.cow-preview div.graph > header > .tags > li.due:before {
        background-image: var(--bad-cow);
        /*For Safari flushing problem*/
        background-image: linear-gradient(180deg, #f3492c 0%, #bc4040 100%);
    }
    aside.cow-preview div.graph > header > .tags > li.tracked:before,
    aside.cow-preview div.graph > header > .tags > li.pregnant:before,
    aside.cow-preview div.graph > header > .tags > li.estrous:before,
    aside.cow-preview div.graph > header > .tags > li.inseminated:before {
        background-image: var(--good-cow);
        /*For Safari flushing problem*/
        background-image: linear-gradient(180deg, #629f26 0%, #146c3c 100%);
    }
    aside.cow-preview div.graph > header > .tags > li.due:before,
    aside.cow-preview div.graph > header > .tags > li.overdue:before {
        background-image: var(--bad-cow-handled);
        /*For Safari flushing problem*/
        background-image: linear-gradient(180deg, #ac2e10 0%, #551717 100%);
    }
    aside.cow-preview div.cow-options header {
        grid-area: options-header;
        display: grid;
        grid-template-areas:
            "title ."
            "title ."
            "tags sessions"
            "tags sessions";
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1.4rem 1.5rem 1.4rem 3rem;
        padding-left: 0.2rem;
        background-color: var(--dashboard-background);
    }
    aside.cow-preview div.cow-options div {
        grid-area: buttons;
        border-left: none;
        background-color: var(--dashboard-background);
    }
    aside.cow-preview div.cow-options > header > h1 {
        grid-area: title;
        font-size: 1.8rem;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    aside.cow-preview div.cow-options > header > .sessions {
        grid-area: sessions;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-self: end;
        margin-right: 0.4rem;
        height: max-content;
    }
    aside.cow-preview div.cow-options > header > .sessions .session {
        margin-top: 0.3rem;
    }

    aside.cow-preview div.cow-options > header > h1,
    aside.cow-preview div.cow-options > header h4 {
        font-weight: 300;
        white-space: nowrap;
        margin: 0;
    }
    aside.cow-preview div.cow-options > header > h4 {
        font-size: 0.8em;
        place-self: start end;
    }

    aside.cow-preview div.cow-options > header > div.sessions h4 {
        font-size: 1em;
        margin: 0;
    }
    aside.cow-preview div.cow-options > header > .tags {
        grid-area: tags;
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
        height: max-content;
    }
    aside.cow-preview div.cow-options > header > .tags span {
        font-weight: 300;
    }
    aside.cow-preview div.cow-options > header > .tags li:before {
        width: 1em;
        height: 1em;
        content: "\00a0";
        display: inline-block;
        margin-right: 0.25em;
        background-image: var(--dead-cow);
        /*For Safari flushing problem*/
        background-image: linear-gradient(180deg, #35505a 0%, #556970 100%);
        border-radius: 0.2em;
        vertical-align: middle;
        margin-bottom: 0.1em;
    }
    aside.cow-preview div.cow-options > header > .tags li {
        display: inline-block;
    }
    aside.cow-preview div.cow-options > header > .tags > li.calving:before,
    aside.cow-preview div.cow-options > header > .tags > li.hypothermia:before,
    aside.cow-preview div.cow-options > header > .tags > li.fever:before,
    aside.cow-preview div.cow-options > header > .tags > li.due:before {
        background-image: var(--bad-cow);
        /*For Safari flushing problem*/
        background-image: linear-gradient(180deg, #f3492c 0%, #bc4040 100%);
    }
    aside.cow-preview div.cow-options > header > .tags > li.tracked:before,
    aside.cow-preview div.cow-options > header > .tags > li.pregnant:before,
    aside.cow-preview div.cow-options > header > .tags > li.estrous:before,
    aside.cow-preview div.cow-options > header > .tags > li.inseminated:before {
        background-image: var(--good-cow);
        /*For Safari flushing problem*/
        background-image: linear-gradient(180deg, #629f26 0%, #146c3c 100%);
    }
    aside.cow-preview div.cow-options > header > .tags > li.due:before,
    aside.cow-preview div.cow-options > header > .tags > li.overdue:before {
        background-image: var(--bad-cow-handled);
        /*For Safari flushing problem*/
        background-image: linear-gradient(180deg, #ac2e10 0%, #551717 100%);
    }
    aside.cow-preview div.cow-options div button {
        background-repeat: no-repeat;
        margin: 0;
        margin-left: 0.5rem;
        margin-bottom: 0.3rem;
        background-position: top;
        background-color: var(--dashboard-background);
        background-size: 2.3rem;
        border: solid 1px var(--dashboard-box-border);
        height: 3.5rem;
        font-size: 0.9rem;
        font-weight: 200;
        padding-top: 2rem;
    }
    aside.cow-preview div.cow-options div .edit {
        --edit: url("../../assets/Edit.svg");
        background-image: var(--edit);
        min-width: 4rem;
    }
    aside.cow-preview div.cow-options div .earTag {
        --note: url("../../assets/EarTag.svg");
        background-image: var(--note);
    }
    aside.cow-preview div.cow-options div .Tsens {
        --note: url("../../assets/Tsens.svg");
        background-image: var(--note);
    }

    aside.cow-preview::-webkit-scrollbar {
        display: none;
    }

    main.dashboard aside.cow-preview div .data-graph {
        height: 12.1rem;
    }
}
