form.login {
    background: var(--dashboard-widget-background);
    padding: 0.5rem;
    display: grid;
    grid-template-areas: "field button";
    grid-template-columns: 1fr 2rem;
    grid-template-rows: 2rem;
    grid-gap: 0.5rem;
}
div.login {
    background: var(--dashboard-widget-background);
    padding: 0.5rem;
    padding-top: 0;
    display: grid;
    grid-template-areas:
        "try-another-email resend-email"
        "open-email open-email";
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 3rem auto;
    grid-gap: 0;
    grid-column-gap: 0;
}
div.login > button.try-another-email {
    background-color: var(--dashboard-background);
    grid-area: try-another-email;
    cursor: pointer;
    align-self: center;
    min-height: 2rem;
    height: auto;
}
div.login > button.resend-email {
    background-color: var(--dashboard-background);
    grid-area: resend-email;
    cursor: pointer;
    align-self: center;
    min-height: 2rem;
    height: auto;
}
div.login > button.open-email {
    background-color: var(--dashboard-background);
    grid-area: open-mail;
    grid-column: 1 / span 2;
    cursor: pointer;
    align-self: center;
    min-height: 2rem;
    height: auto;
}
.login-form-box.pending.retry > div.login > button.resend-email {
    border-radius: 75%;
    background-size: 0;
    border-style: dotted;
    background-position: center;
    border-color: var(--button-action-color);
    border-width: 4.5px;
    width: 2rem;
    height: 2rem;
    justify-self: center;
    animation: rotation linear 2s infinite;
}
form.login input[type="email"] {
    background: var(--dashboard-background);
    color: inherit;
    border: solid 1px gray;
    border-radius: 0.2rem;
    padding: 0 0.5rem;
    grid-area: field-start / field-start / button-end / button-end;
    outline: none;
    transition: border-color 1s;
    font-size: 0.95rem;
}

form.login input[type="email"]:focus,
.login-form-box.failed > form.login input[type="email"]:focus {
    border-color: white;
}
.login-form-box.failed > form.login input[type="email"] {
    box-shadow: 0 0 0 3px #d30;
    border-color: #d30;
}

form.login input[type="submit"] {
    grid-area: button;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border: solid 1px var(--dashboard-background);
    border-radius: 0.1rem;
    background-color: transparent;
    background-image: url(./Submit.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1.6rem;
    color: transparent;
    transition-property: all;
    transition-duration: 0.5s;
    align-self: center;
    justify-self: center;
    outline: none;
    opacity: 0.3;
}

form.login:valid input[type="submit"] {
    opacity: 0.7;
}
form.login:valid input[type="submit"]:hover {
    opacity: 1;
}

.login-form-box.pending > form.login input[type="submit"] {
    border-radius: 50%;
    background-size: 0;
    border-style: dotted;
    border-color: var(--button-action-color);
    border-width: 4.5px;
    opacity: 1;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    animation: rotation linear 2s infinite;
}

.login-form-box.pending > form.login input[type="email"] {
    transition: all 0.5s ease-in;
    padding-left: 90%;
    overflow-x: hidden;
    color: transparent;
}

/* GOOGLE LOGIN*/
.google-login {
    padding: 0 0.5rem 0 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.google-login > button.google {
    display: flex;
    width: 100%;
    border-radius: 0.4rem;
    line-height: 2rem;
    padding: 0.5rem;
    margin: 0.5rem;
    background-color: var(--dashboard-background);
    border: 3px solid var(--dashboard-widget-background);
    cursor: pointer;
}

.google-login > button.google.disabled {
    opacity: 0.5;
}

.google-login > button.google > span.icon {
    display: inline-block;
    content: "";
    width: 2rem;
    height: 2rem;
    background-image: url(./../../assets//Google.svg);
    background-repeat: no-repeat;
    background-size: 1.6rem;
    background-position: left;
    vertical-align: middle;
}
.google-login > button.google > span.text {
    margin-left: 2rem;
    font-size: 1.2em;
    color: var(--dashboard-text-color);
}

.grecaptcha-badge {
    visibility: hidden;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* For iPads */
@media (min-width: 48rem) and (orientation: portrait),
    (min-width: 64rem) and (orientation: landscape) {
    form.login {
        grid-template-columns: 1fr 4rem;
        grid-template-rows: 3rem;
    }
    form.login input[type="email"],
    div.login > button.try-another-email,
    div.login > button.resend-email {
        font-size: 1.4rem;
    }
    form.login input[type="submit"] {
        background-size: 2.5rem;
    }
    .login-form-box.pending > form.login input[type="submit"] {
        border-radius: 90%;
        width: calc(50% - 6px);
        height: calc(80% - 6px);
    }
    .login-form-box.pending.retry > div.login > button.resend-email {
        border-radius: 90%;
        width: calc(2% - 15px);
        height: calc(85% - 6px);
    }
}
