@import url(https://use.typekit.net/lna6dbu.css);
body {
    margin: 0;
    padding: 0;
    --dashboard-background: #333;
    --dashboard-widget-background: #4f4f4f;
    --dashboard-text-color: #f7f7f7;
    --dashboard-box-border: #e0e0e0;
    background: var(--dashboard-background);
    color: var(--dashboard-text-color);
    font-family: "lato", sans-serif;

    --button-action-color: #2d9cdb;
    --checked-color: #2d9cdb;

    --signal-color: #f2c94c;

    --tag-color: #2e4759;
    --tag-parameter-color: rgb(54, 109, 67);
    --tag-logic-color: #a73d3d;
    --tag-sort-color: #2e4759;

    --good-cow: linear-gradient(180deg, #629f26 0%, #146c3c 100%);
    --weird-cow: linear-gradient(180deg, #f0960e 0%, #6c5914 100%);
    --bad-cow: linear-gradient(180deg, #f3492c 0%, #bc4040 100%);
    --bad-cow-handled: linear-gradient(180deg, #ac2e10 0%, #551717 100%);
    --dead-cow: linear-gradient(180deg, #35505a 0%, #556970 100%);
    --unstable-cow: linear-gradient(180deg, #f0960e 0%, #6c5914 100%);
    --heat-cow: linear-gradient(180deg, #f59619 0%, #f55f19 100%);
    --potential-cow: linear-gradient(180deg, #3fd1ff 0%, #2d9cdb 100%);
    --calving-cow: linear-gradient(180deg, #2d9cdb 0%, #134561 100%);
    --due-cow: linear-gradient(180deg, #83d2ff 0%, #2d9cdb 100%);

    --idle-sensor: linear-gradient(180deg, #35505a 0%, #556970 100%);
    --stable-sensor: linear-gradient(180deg, #629f26 0%, #146c3c 100%);
    --unstable-sensor: linear-gradient(180deg, #f0960e 0%, #6c5914 100%);
    --bad-sensor: linear-gradient(180deg, #f3492c 0%, #bc4040 100%);

  --idle-sensor: linear-gradient(180deg, #35505A 0%, #556970 100%);
  --stable-sensor: linear-gradient(180deg, #629F26 0%, #146C3C 100%);
  --unstable-sensor: linear-gradient(180deg, #F0960E 0%, #6C5914 100%);
  --bad-sensor: linear-gradient(180deg, #F3492C 0%, #BC4040 100%);
  
  --diagonal-stripes: repeating-linear-gradient(
      45deg,
      transparent,
      transparent 1em,
      rgba(0, 0, 0, 0.2) 1em,
      rgba(0, 0, 0, 0.2) 2em
      );
    
    -webkit-tap-highlight-color: transparent;
}

.navigating {
    pointer-events: none;
}
body main{
    width: 100vw !important;
    min-height: 100vh !important;
    border-radius: 0 !important;
}

.placeholder {
    background-image: var(--diagonal-stripes);
    /*For Safari flushing problem*/
    background-image: repeating-linear-gradient(
        45deg,
        transparent,
        transparent 1em,
        rgba(0, 0, 0, 0.2) 1em,
        rgba(0, 0, 0, 0.2) 2em
    );
}

.disable-transform {
    -webkit-transform: none !important;
            transform: none !important;
}

[hidden] { 
    display: none !important;
}


div.toast{
    position: fixed;
    top: 0px;
    left: 0;
    z-index: 999;
    width: 100vw;
    height: 2.4rem;
    background-color: var(--tag-logic-color);
    /* visibility: hidden; */
    transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
div.toast.slide-in {
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
}

div.toast.slide-out {
    animation: slide-out 0.5s forwards;
    -webkit-animation: slide-out 0.5s forwards;
}

@keyframes slide-in {
    100% { -webkit-transform: translateY(0%); transform: translateY(0%); }
}

@-webkit-keyframes slide-in {
    100% { -webkit-transform: translateY(0%); }
}
    
@keyframes slide-out {
    0% { -webkit-transform: translateY(0%); transform: translateY(0%); }
    100% { -webkit-transform: translateY(-100%); transform: translateY(-100%); }
}

@-webkit-keyframes slide-out {
    0% { -webkit-transform: translateY(0%); }
    100% { -webkit-transform: translateY(-100%); }
}
button.button {
    background-color: var(--dashboard-background);
    height: 2rem;
    border-radius: .25rem;
    color: var(--dashboard-text-color);
    border: none;
    font-size: 1rem;
    margin: 1rem;
    
    transition-property: box-shadow;
    transition-duration: .3s;
    
    cursor: pointer;
    
    outline: none;
}

button.action {
    background-color: var(--button-action-color);
}
.tooltip-wrapper{
    visibility: hidden;
}
.tooltip-wrapper.visible{
    visibility: visible;
    transition:  .5s ease-in;
}

.tooltip-wrapper.bottom{
    position: relative;
    width: -webkit-min-content;
    width: min-content;
}
.tooltip-wrapper.bottom .tooltip-common{
    position: absolute;
    width: auto;
    display: grid;
    z-index: 999;

    grid-template-areas: "indicator" "content";
    grid-template-rows: 1rem auto;
}
.tooltip-wrapper.bottom .tooltip-common .indicator{
    grid-area: indicator;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    height: 1rem;
    padding: 0;
}

.tooltip-wrapper.bottom .tooltip-common .indicator div {
    width: 100%;
    height: 1rem;
}

.tooltip-wrapper.bottom .tooltip-common .indicator div.start path,
.tooltip-wrapper.bottom .tooltip-common .indicator div.end path {
    stroke: aliceblue;
    stroke-width: .14rem;
}

.tooltip-wrapper.bottom .tooltip-common .indicator div.middle path {
    stroke: aliceblue;
    stroke-width: .05rem;
    fill-opacity: 0.8;
}
.tooltip-wrapper.bottom .tooltip-common .content {
    /* position: absolute; */
    grid-area: content;
    width: auto;

    background-color: rgb(0, 0, 0, .9);
    /* border-radius: 0 0 .4rem .4rem; */
    
}


/* Tooltip right */

.tooltip-wrapper.right{
    position: absolute;
    width: 0;
    height: 0;
}
.tooltip-wrapper.right .tooltip-common{
    position: absolute;
    width: auto;
    display: grid;

    grid-template-areas: "indicator content";
    grid-template-columns: 1rem auto;
}
.tooltip-wrapper.right .tooltip-common .indicator{
    grid-area: indicator;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
}

.tooltip-wrapper.right .tooltip-common .indicator div {
    height: 100%;
}

.tooltip-wrapper.right .tooltip-common .indicator div.start path,
.tooltip-wrapper.right .tooltip-common .indicator div.end path {
    stroke: aliceblue;
    stroke-width: .14rem;
}

.tooltip-wrapper.right .tooltip-common .indicator div.middle path {
    stroke: aliceblue;
    stroke-width: .05rem;
    fill-opacity: 0.8;
}
.tooltip-wrapper.right .tooltip-common .content {
    /* position: absolute; */
    grid-area: content;
    width: auto;

    background-color: rgb(0, 0, 0, .9);
    /* border-radius: 0 0 .4rem .4rem; */
    
}



/* Tooltip left */

.tooltip-wrapper.left{
    position: absolute;
    width: -webkit-min-content;
    width: min-content;
}
.tooltip-wrapper.left .tooltip-common{
    position: absolute;
    width: auto;
    display: grid;

    grid-template-areas: "content indicator";
    grid-template-columns: auto 1rem;
}
.tooltip-wrapper.left .tooltip-common .indicator{
    grid-area: indicator;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
}

.tooltip-wrapper.left .tooltip-common .indicator div {
    height: 100%;
}

.tooltip-wrapper.left .tooltip-common .indicator div.start path,
.tooltip-wrapper.left .tooltip-common .indicator div.end path {
    stroke: aliceblue;
    stroke-width: .14rem;
}

.tooltip-wrapper.left .tooltip-common .indicator div.middle path {
    stroke: aliceblue;
    stroke-width: .05rem;
    fill-opacity: 0.8;
}
.tooltip-wrapper.left .tooltip-common .content {
    /* position: absolute; */
    grid-area: content;
    width: auto;

    background-color: rgb(0, 0, 0, .9);
    /* border-radius: 0 0 .4rem .4rem; */
    
}


/* Tooltip up */
.tooltip-wrapper.up{
    position: absolute;
    width: -webkit-min-content;
    width: min-content;
}
.tooltip-wrapper.up .tooltip-common{
    position: absolute;
    width: auto;
    display: grid;

    grid-template-areas: "content" "indicator";
    grid-template-rows: auto 1rem;
}
.tooltip-wrapper.up .tooltip-common .indicator{
    grid-area: indicator;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    height: 1rem;
    padding: 0;
}

.tooltip-wrapper.up .tooltip-common .indicator div {
    width: 100%;
    height: 1rem;
}

.tooltip-wrapper.up .tooltip-common .indicator div.start path,
.tooltip-wrapper.up .tooltip-common .indicator div.end path {
    stroke: aliceblue;
    stroke-width: .14rem;
}

.tooltip-wrapper.up .tooltip-common .indicator div.middle path {
    stroke: aliceblue;
    stroke-width: .05rem;
    fill-opacity: 0.8;
}
.tooltip-wrapper.up .tooltip-common .content {
    /* position: absolute; */
    grid-area: content;
    width: auto;

    background-color: rgb(0, 0, 0, .9);
    /* border-radius: 0 0 .4rem .4rem; */
    
}

/* Safari */
@media not all and (min-resolution:.001dpcm) { 
    @media {
        .tooltip-wrapper.right .tooltip-common .indicator div {
            height: 2.87rem;
        }
        .basestation .tooltip-wrapper.right .tooltip-common .indicator div,
        .basestation .tooltip-wrapper.left .tooltip-common .indicator div {
            height: 2.87rem;
        }
    }
}

/* For iPads */
@media (min-width: 48rem) and (orientation: portrait),
        (min-width: 64rem) and (orientation: landscape) {
    .tooltip-wrapper.right .tooltip-common .indicator div,
    .basestation .tooltip-wrapper.right .tooltip-common .indicator div {
        height: 4.19rem;
    }
}
/* DayPicker styles */

.DayPicker {
    display: inline-block;
    font-size: 1rem;
  }
  
  .DayPicker-wrapper {
    position: relative;
  
    -webkit-flex-direction: row;
  
            flex-direction: row;
    padding-bottom: 1em;
  
    -webkit-user-select: none;
  
            user-select: none;
  }
  
  .DayPicker-Months {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: center;
            justify-content: center;
  }
  
  .DayPicker-Month {
    display: table;
    margin: 0 1em;
    margin-top: 1em;
    border-spacing: 0;
    border-collapse: collapse;
  
    -webkit-user-select: none;
  
            user-select: none;
  }
  
  .DayPicker-NavBar {
  }
  
  .DayPicker-NavButton {
    position: absolute;
    top: 1em;
    right: 1.5em;
    left: auto;
  
    display: inline-block;
    margin-top: 2px;
    width: 1.25em;
    height: 1.25em;
    background-position: center;
    background-size: 50%;
    background-repeat: no-repeat;
    color: #8B9898;
    cursor: pointer;
  }
  
  .DayPicker-NavButton:hover {
    opacity: 0.8;
  }
  
  .DayPicker-NavButton--prev {
    margin-right: 1.5em;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC');
  }
  
  .DayPicker-NavButton--next {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==');
  }
  
  .DayPicker-NavButton--interactionDisabled {
    display: none;
  }
  
  .DayPicker-Caption {
    display: table-caption;
    margin-bottom: 0.5em;
    padding: 0 0.5em;
    text-align: left;
  }
  
  .DayPicker-Caption > div {
    font-weight: 500;
    font-size: 1.15em;
  }
  
  .DayPicker-Weekdays {
    display: table-header-group;
    margin-top: 1em;
  }
  
  .DayPicker-WeekdaysRow {
    display: table-row;
  }
  
  .DayPicker-Weekday {
    display: table-cell;
    padding: 0.5em;
    color: #8B9898;
    text-align: center;
    font-size: 0.875em;
  }
  
  .DayPicker-Weekday abbr[title] {
    border-bottom: none;
    text-decoration: none;
  }
  
  .DayPicker-Body {
    display: table-row-group;
  }
  
  .DayPicker-Week {
    display: table-row;
  }
  
  .DayPicker-Day {
    display: table-cell;
    padding: 0.5em;
    border-radius: 50%;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
  }
  
  .DayPicker-WeekNumber {
    display: table-cell;
    padding: 0.5em;
    min-width: 1em;
    border-right: 1px solid #EAECEC;
    color: #8B9898;
    vertical-align: middle;
    text-align: right;
    font-size: 0.75em;
    cursor: pointer;
  }
  
  .DayPicker--interactionDisabled .DayPicker-Day {
    cursor: default;
  }
  
  .DayPicker-Footer {
    padding-top: 0.5em;
  }
  
  .DayPicker-TodayButton {
    border: none;
    background-color: transparent;
    background-image: none;
    box-shadow: none;
    color: var(--checked-color);
    font-size: 0.875em;
    cursor: pointer;
  }
  
  /* Default modifiers */
  
  .DayPicker-Day--today {
    color: #D0021B;
    font-weight: 700;
  }
  
  .DayPicker-Day--outside {
    color: #8B9898;
    cursor: default;
  }
  
  .DayPicker-Day--disabled {
    color: rgb(116, 117, 117);
    cursor: default;
    /* background-color: #eff1f1; */
  }
  
  /* Example modifiers */
  
  .DayPicker-Day--sunday {
    background-color: #F7F8F8;
  }
  
  .DayPicker-Day--sunday:not(.DayPicker-Day--today) {
    color: #DCE0E0;
  }
  
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    position: relative;
  
    background-color: var(--checked-color);
    color: #F0F8FF;
  }
  
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: #51A0FA;
  }
  
  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: var(--dashboard-widget-background);
  }
  
  /* DayPickerInput */
  
  .DayPickerInput {
    display: inline-block;
  }
  .DayPickerInput input{
    background-color: var(--dashboard-background);
    background-blend-mode: overlay;
    background-position: .2rem;
    padding-right: 1rem;
    padding-left: 1rem;
    border-radius: .4rem;
    border: solid 1.4px var(--dashboard-box-border);
    font-size: 1rem;
    color: var(--dashboard-text-color);
  }
  
  .DayPickerInput-OverlayWrapper {
    position: relative;
  }
  
  .DayPickerInput-Overlay {
    position: absolute;
    left: 0;
    z-index: 1;
  
    background: var(--dashboard-background);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    border: 2px solid #bbb;
    border-top: none;
    border-radius: 0 0 .25rem .25rem;

    /* width: 100%; */
  }

  div.top .DayPickerInput-OverlayWrapper {
    margin-top: -19.8rem;
}
  div.top .DayPickerInput-Overlay {
    border-top: 2px solid #bbb;
    border-bottom: none;
  }

  .DayPicker-Caption select{
    display: inline;
    /* padding: .6em 1.4em .5em .8em; */
    -webkit-appearance: none;
    appearance: none;
    background-color: var(--dashboard-background);
    color: var(--dashboard-text-color);
    font-size: 1rem;
    margin-left: .6rem;
    border: none;
    cursor: pointer;
    /* border: solid .8px var(--dashboard-box-border); */
  }
  

/* For iPads */
@media (min-width: 48rem) and (orientation: portrait),
        (min-width: 64rem) and (orientation: landscape) {
  .DayPicker-Weekday {
    font-size: 1em;
  }
  div.top .DayPickerInput-OverlayWrapper {
    margin-top: -21.8rem;
}
.DayPicker-Caption select{
  font-size: 1.1rem;
}
.DayPicker-Day {
  font-size: 1rem;
}
}
.custom-select{
    background: url(/static/media/CowPictogram.e6c42c7e.svg) no-repeat;
    background-color: var(--dashboard-background);
    background-blend-mode: overlay;
    background-position: .2rem;
    padding-left: 2.6rem;
    border-radius: .4rem;
    border: solid 1.4px var(--dashboard-box-border);
    font-size: 1rem;
    color: var(--dashboard-text-color);
}

.custom-select .custom-select__single-value{
    color: var(--dashboard-text-color);
}

.custom-select .custom-select__control{
    background-color: var(--dashboard-background);
    border: none;
    height: 100%;
}

.custom-select .custom-select__menu{
    background-color: var(--dashboard-background);
    width: 82%;
    border: 2px solid #bbb;
    border-top: none;
    border-radius: 0 0 .25rem .25rem;
}
.custom-select .custom-select__option{
    color: var(--dashboard-text-color);
    background-color: var(--dashboard-background);
}
.custom-select .custom-select__option:hover{
    background-color: var(--dashboard-widget-background);
}


div.validation-wrapper{
    position: relative;
}

div.validation-wrapper span{
    position: absolute;
    z-index: 0;
    border: solid 1.4px rgb(219, 205, 205);
    background-color: #582261;
    height: -webkit-min-content;
    height: min-content;
    padding: .2rem;
    width: 97%;
    border-radius: 0 0 .4rem .4rem;
}

div.validation-wrapper span.validate.required{
    background-color: #4D191A;
}

div.time {
    display: grid;
    grid-template-rows: 3rem 3fr;
    margin-top: 0; 
}
div.time > time{
    padding-bottom: .2rem;
    font-size: .9rem;
    text-align: center;
}

.date-picker {
    background-color: var(--dashboard-background);
    margin: .3rem 0 .3rem 0;
    border-radius: .2rem;
    background-blend-mode: overlay;
    text-align: center;
    line-height: 2.2rem;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    width: 8rem;
}
.date-picker input {
    height: 1.8rem;
    width: 5rem;
}

.date-picker .before {
    background: url(/static/media/up.996fd44b.svg) no-repeat;
    background-blend-mode: overlay;
    background-position: center;
    background-size: .8rem;
    height: 2.4rem;
    width: 2.4rem;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
}

.date-picker .after {
    background: url(/static/media/down.93b7d06b.svg) no-repeat;
    background-blend-mode: overlay;
    background-position: center;
    background-size: .8rem;
    height: 2.4rem;
    width: 2.4rem;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
}


.time-picker {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.time-picker .time-part{
    display: grid;
    grid-template-areas: "up" "body" "down";
    grid-template-rows: .5fr 1fr .5fr;
    background-color: var(--dashboard-background);
    /* border: solid 1px; */
    border-radius: .3rem;
    width: 50%;
    height: -webkit-min-content;
    height: min-content;
    margin: 0 .1rem 0 .1rem;
}

.time-picker .time-part .before{
    background: url(/static/media/up.996fd44b.svg) no-repeat;
    background-blend-mode: overlay;
    background-position: center;
    background-size: .8rem;
    grid-area: up;
    height: 1.6rem;
}

.time-picker .time-part .after{
    background: url(/static/media/down.93b7d06b.svg) no-repeat;
    background-blend-mode: overlay;
    background-position: center;
    background-size: .8rem;
    grid-area: down;
    height: 1.6rem;
}

.time-picker .time-part .body{
    grid-area: body;
    border: solid 1px;
    text-align: center;
    font-size: 1.4em;
}
.time-picker .time-part.first .body{
    border-right: none;
    border-radius: .3rem 0 0 .3rem;
}
.time-picker .time-part.second .body{
    border-left: none;
    border-radius: 0 .3rem .3rem 0;
}
.time-picker .time-part .body span{
    cursor: pointer;
}
.time-picker .time-part .time-input{
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    outline: none;
    border: none;
    width: 80%;
    height: 80%;
    font-size: 1.4rem;
    color: var(--dashboard-text-color);
    text-align: center;
}


.smart-note {
    display: grid;
    grid-template-areas: "note" "envelope";
    /* grid-template-columns: 1fr 2rem; */
    grid-template-rows: auto auto;
    background: var(--dashboard-background);
    color: inherit;
    border: solid 2px gray;
    border-radius: 0.2rem;
    padding: 0.3rem 0.3rem;
    outline: none;
    transition: border-color 1s;
    width: 100%;
    height: auto;
    /* overflow: hidden; */
    font-size: 16px;
}
.smart-note .envelope {
    grid-area: envelope;
    width: 1.6rem;
    height: 1.6rem;
    background-image: url(/static/media/SmartNoteEnvelope.fb6e7783.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1.6rem;
    color: transparent;
    /* opacity: .3; */
    justify-self: end;
}
.smart-note .content {
    background-color: rgb(0, 0, 0, 0.8);
    padding: 0.4rem 0.8rem 0.8rem 0.8rem;
    margin-top: 0.1rem;
    border-radius: 0 0 0.8rem 0.8rem;
}
.smart-note .content button {
    min-width: 5rem;
    width: 100%;
    margin: 0;
    margin-top: 0.4rem;
    border: solid 1px;
    border-radius: 4px;
    display: block;
    white-space: nowrap;
}

.smart-note .envelope.completed {
    cursor: pointer;
}

.option-checkbox {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}
.option-checkbox {
    white-space: nowrap;
    cursor: pointer;
    margin-bottom: 0.05rem;
}
.option-checkbox:before {
    content: "";
    display: inline-block;
    height: 1rem;
    width: 1rem;
    border: 1px solid;
    border-radius: 0.3rem;
    margin-top: 0.15rem;
}
.option-checkbox.checked:before {
    background-color: var(--checked-color);
    border-color: var(--checked-color);
}

.smart-note .note {
    grid-area: note;
    display: grid;
    grid-template-rows: -webkit-min-content -webkit-min-content -webkit-min-content;
    grid-template-rows: min-content min-content min-content;
}
.smart-note .note.result {
    opacity: 0.8;
    pointer-events: none;
}
.smart-note .note .variable-container {
    display: grid;
    grid-template-areas: "label variable";
    grid-template-columns: 1fr 50%;
}
.smart-note .note .variable-container .health-complication {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}
.smart-note .note .variable-container h4 {
    grid-area: label;
    display: inline-block;
    margin: 0.5rem 0.4rem;
}
.smart-note .note .variable-container.group .variables {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 97%;
    grid-column-gap: 0.4rem;
    -webkit-column-gap: 0.4rem;
            column-gap: 0.4rem;
}
.smart-note .note .variable {
    grid-area: variable;
    width: 95%;
    height: 1.7rem;
    border: 0.12em solid #f7f7ff;
    border-radius: 0.4em;
    text-align: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin: 0.2rem 0;
    cursor: pointer;
}
.smart-note .note .variable.selected {
    background-color: var(--button-action-color);
}

.smart-note .note.result .variable.selected {
    background-color: var(--dashboard-color);
}

.smart-note .note .variable.placeholder {
    color: var(--button-action-color);
}
.smart-note .note .variable.placeholder:before {
    content: "‹";
    color: #828282;
}
.smart-note .note .variable.placeholder:after {
    content: "›";
    color: #828282;
}
.smart-note .envelope {
    opacity: 0.5;
    cursor: pointer;
    pointer-events: none;
}
.smart-note .envelope.completed {
    opacity: 1;
    pointer-events: all;
}

li.event.read > h6,
li.event.marked > h6 {
    opacity: 0.6;
}

li.event {
    --warning: url(/static/media/Warning.82cf904a.svg);
    --thermometer: url(/static/media/Thermometer.3d935352.svg);
    --tsens: url(/static/media/Tsens.faf7b00a.svg);
    --calf: url(/static/media/Calf.c028e539.svg);
    --note: url(/static/media/Note.23316198.svg);

    --sidebar-gradient: var(--dead-cow);
    --pictogram: var(--warning);
    background: var(--dashboard-widget-background);
    margin: 0;
    padding: 0.2rem 0;
    border-bottom: solid 1px var(--dashboard-background);
    padding-left: 1rem;
    background-image: linear-gradient(180deg, #35505a 0%, #556970 100%),
        url(/static/media/Warning.82cf904a.svg);
    background-size: 1rem 100%, 3rem;
    background-position: left, 1rem;
    background-repeat: no-repeat;

    min-height: 2rem;
    max-height: 10rem;

    display: grid;

    grid-template-columns: 3rem 1fr 1rem;
    grid-template-rows: 2fr 1fr;
    grid-template-areas:
        "pictogram message  markbutton"
        "pictogram timestamp  markbutton"
        "action-buttons action-buttons markbutton"
        "confirmed confirmed  markbutton"
        "smartnote smartnote  markbutton";

    -webkit-animation-name: pop-event;

            animation-name: pop-event;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;

    -webkit-transform-origin: top;

            transform-origin: top;
    /*it breaks functionality of z index */
    /* transform: rotateX(0); */
    /* overflow: hidden; */
}
li.event.unfocused {
    opacity: 0.5;
}
li.event.close {
    min-height: 0.1rem;
    background-image: linear-gradient(180deg, #35505a 0%, #556970 100%);
}
li.event.open {
    background-image: linear-gradient(180deg, #35505a 0%, #556970 100%),
        url(/static/media/Warning.82cf904a.svg);
}
li.event.half-open {
    grid-template-areas: "pictogram message timestamp markbutton";
    grid-template-columns: 3rem 1fr auto 1rem;
    grid-template-rows: 2rem;
    max-height: 2rem;
    background-size: 1rem 100%, 2.5rem;
    background-position: left, 1.3rem;
}
li.event.half-open > h6 {
    grid-area: message;
    align-self: center;
}
li.event.half-open > time {
    -webkit-align-self: center;
            align-self: center;
}
li.event.smart-note-submitted {
    grid-template-rows: 2fr;
}
li.event.smart {
    background-position: left, left 1rem top 1rem;
    min-height: 8rem;
    max-height: -webkit-min-content;
    max-height: min-content;
    /* max-height: 20rem; */
}

li.event > .smart-note {
    grid-area: smartnote;
    width: 82%;
    margin-left: 3rem;
}
li.event > h6 {
    font-weight: inherit;
    font-size: inherit;
    grid-area: message;
    margin: 0;
    align-self: end;
    overflow: hidden;
}

li.event > h6 > blockquote {
    margin: 0;
    padding: 0;
}

li.event > time {
    opacity: 0.8;
    font-size: 0.8rem;
    grid-area: timestamp;
    display: block;
    margin: 0;
    align-self: start;
}
li.event > button.mark-read {
    grid-area: markbutton;
    align-self: center;
    justify-self: right;
}
li.event > div.action-buttons {
    margin-left: 3rem;
    margin-right: 1rem;
    margin-top: 0.3rem;
    grid-area: action-buttons;

    display: -webkit-flex;

    display: flex;
    grid-column-gap: 0.2rem;
}
li.event.potential-calvings-detected > div.action-buttons {
    grid-template-areas: "button";
    grid-template-columns: 1fr;
}
li.event > div.action-buttons button.action {
    -webkit-align-self: stretch;
            align-self: stretch;
    justify-self: start;
    background-color: var(--dashboard-background);
    color: var(--dashboard-text-color);
    border: none;
    border-radius: 0.25em;
    transition-property: opacity, box-shadow;
    transition-duration: 0.25s;
    outline: none;
    margin: 0;
    width: 100%;
    font-size: 0.8rem;
}

li.event > span.confirmed {
    grid-area: confirmed;
    margin-left: 3rem;
    font-size: 0.8em;
    text-decoration: underline;
}

li.event > button.visible {
    visibility: visible;
}

li.event > button.action.done {
    opacity: 0.2;
    text-decoration: line-through;
}

@media (hover: hover) {
    li.event > div.action-buttons button.action {
        opacity: 0.5;
    }
    li.event > div.action-buttons button.action:enabled:hover {
        opacity: 1;
        box-shadow: currentColor 0 0 0 0.25em;
    }
}

@media (pointer: fine) {
    li.event > button.action {
        -webkit-align-self: center;
                align-self: center;
        padding: 0.5em;
    }
}
li.event.dashed {
    grid-template-areas: "dashed";
    grid-template-columns: 100%;
    min-height: 0;
    height: 0;
    background-image: linear-gradient(
        180deg,
        #4f4f4f 0%,
        #4f4f4f 100%
    ) !important;
    background-color: var(--dashboard-background) !important;
    padding-top: 0.1rem;
}
li.event.dashed h6 {
    grid-area: dashed;
    border: 0.1rem dashed var(--dashboard-widget-background);
    width: 100%;
}

li.event.potential-calvings-detected {
    background-image: linear-gradient(180deg, #35505a 0%, #556970 100%),
        url(/static/media/Note.23316198.svg);
}

li.event.tsens-problem-detected.close,
li.event.tsens-installed.close,
li.event.session-created.close,
li.event.session-completed.close,
li.event.tsens-problem-over.close,
li.event.tsens-removed.close {
    background-image: linear-gradient(180deg, #629f26 0%, #146c3c 100%);
}

li.event.tsens-problem-detected.earTag.close,
li.event.tsens-installed.earTag.close,
li.event.session-created.earTag.close,
li.event.session-completed.earTag.close,
li.event.tsens-problem-over.earTag.close,
li.event.tsens-removed.earTag.close,
li.event.fever-over.close {
    background-image: linear-gradient(180deg, #629f26 0%, #146c3c 100%);
}

li.event.hypothermia-detected.close,
li.event.hypothermia-over.close,
li.event.fever-detected.close {
    background-image: linear-gradient(180deg, #f3492c 0%, #bc4040 100%);
}

li.event.calving-detected.close {
    background-image: linear-gradient(180deg, #2d9cdb 0%, #134561 100%);
}

li.event.calving-due.close {
    background-image: linear-gradient(180deg, #83d2ff 0%, #2d9cdb 100%);
}

li.event.labor-detected.close,
li.event.delivery-detected.close,
li.event.delivery-completed.close,
li.event.calving-overdue.close,
li.event.heat-detected.close,
li.event.sensor-fall-detected.close {
    background-image: linear-gradient(180deg, #f3492c 0%, #bc4040 100%);
}

li.event.calving-completed.close {
    background-image: linear-gradient(180deg, #629f26 0%, #146c3c 100%);
}
li.event.calving-completed.open {
    background-image: linear-gradient(180deg, #629f26 0%, #146c3c 100%),
        url(/static/media/Calf.c028e539.svg);
}

li.event.note-submitted.close,
li.event.smart-note-submitted.close {
    background-image: linear-gradient(180deg, #35505a 0%, #556970 100%);
}
li.event.note-submitted.open,
li.event.smart-note-submitted.open,
li.event.called-user-activated {
    background-image: linear-gradient(180deg, #35505a 0%, #556970 100%),
        url(/static/media/Note.23316198.svg);
}
li.event.tsens-problem-detected,
li.event.fever-detected,
li.event.hypothermia-detected,
li.event.labor-detected,
li.event.delivery-detected,
li.event.calving-overdue,
li.event.heat-detected,
li.event.sensor-fall-detected {
    background-image: linear-gradient(180deg, #f3492c 0%, #bc4040 100%),
        url(/static/media/Warning.82cf904a.svg);
}

li.event.calving-due {
    background-image: linear-gradient(180deg, #83d2ff 0%, #2d9cdb 100%),
        url(/static/media/Warning.82cf904a.svg);
}

li.event.calving-detected {
    background-image: linear-gradient(180deg, #2d9cdb 0%, #134561 100%),
        url(/static/media/Warning.82cf904a.svg);
}

li.event.tsens-installed,
li.event.session-created,
li.event.session-completed,
li.event.tsens-problem-over,
li.event.fever-over,
li.event.hypothermia-over,
li.event.delivery-completed,
li.event.calving-completed {
    background-image: linear-gradient(180deg, #629f26 0%, #146c3c 100%),
        url(/static/media/Warning.82cf904a.svg);
}

li.event.tsens-problem-detected,
li.event.tsens-installed,
li.event.session-created,
li.event.session-completed,
li.event.tsens-problem-over,
li.event.tsens-removed {
    background-image: linear-gradient(180deg, #629f26 0%, #146c3c 100%),
        url(/static/media/Tsens.faf7b00a.svg);
}

li.event.tsens-problem-detected.earTag,
li.event.tsens-installed.earTag,
li.event.session-created.earTag,
li.event.session-completed.earTag,
li.event.tsens-problem-over.earTag,
li.event.tsens-removed.earTag {
    background-image: linear-gradient(180deg, #629f26 0%, #146c3c 100%),
        url(/static/media/EarTag.a4d09280.svg);
}

li.event.hypothermia-detected,
li.event.hypothermia-over,
li.event.fever-detected {
    background-image: linear-gradient(180deg, #f3492c 0%, #bc4040 100%),
        url(/static/media/Thermometer.3d935352.svg);
}

li.event.fever-over {
    background-image: linear-gradient(180deg, #629f26 0%, #146c3c 100%),
        url(/static/media/Thermometer.3d935352.svg);
}

li.event.calving-detected {
    background-image: linear-gradient(180deg, #2d9cdb 0%, #134561 100%),
        url(/static/media/Calf.c028e539.svg);
}

li.event.calving-due {
    background-image: linear-gradient(180deg, #83d2ff 0%, #2d9cdb 100%),
        url(/static/media/Calf.c028e539.svg);
}

li.event.labor-detected,
li.event.delivery-detected,
li.event.delivery-completed,
li.event.calving-overdue {
    background-image: linear-gradient(180deg, #f3492c 0%, #bc4040 100%),
        url(/static/media/Calf.c028e539.svg);
}

li.event.heat-detected {
    background-image: linear-gradient(180deg, #f3492c 0%, #bc4040 100%),
        url(/static/media/estrous.e3fa64f4.svg);
}
li.event.calving-completed {
    background-image: linear-gradient(180deg, #629f26 0%, #146c3c 100%),
        url(/static/media/Calf.c028e539.svg);
}

li.event.note-submitted,
li.event.smart-note-submitted {
    max-height: -webkit-min-content;
    max-height: min-content;
    background-image: linear-gradient(180deg, #35505a 0%, #556970 100%),
        url(/static/media/Note.23316198.svg);
}
@-webkit-keyframes pop-event {
    from {
        min-height: 0;
        max-height: 0;
        -webkit-transform: rotateX(90deg);
                transform: rotateX(90deg);
    }
}
@keyframes pop-event {
    from {
        min-height: 0;
        max-height: 0;
        -webkit-transform: rotateX(90deg);
                transform: rotateX(90deg);
    }
}

/* For iPads */
@media (min-width: 48rem) and (orientation: portrait),
        (min-width: 64rem) and (orientation: landscape) {
    li.event.half-open {
        grid-template-columns: 3rem 1fr auto 2rem;
    }
    li.event > h6,
    li.event > div.action-buttons button.action {
        font-size: 1.2rem;
    }
    li.event > time {
        font-size: 1rem;
    }
}
ol.events > div.cow-events{
    margin-bottom: .5rem;
}
ol.events {
    list-style: none;
    padding: 0;
    margin: 0;
}
ol.events.examine div.cow-events:not(.focused) {
    opacity: .1;
}
li.older {
    text-align: center;
    font-size: smaller;
    padding: 1rem;
    opacity: .3;
    background: var(--dashboard-widget-background);
    cursor: pointer;
}

li.older:not(.pending):hover {
    opacity: .6;
}

li.older.pending {
    background-image: repeating-linear-gradient(
        45deg,
        transparent,
        transparent 1em,
        rgba(255, 255, 255, 0.2) 1em,
        rgba(255, 255, 255, 0.2) 2em
        );
    -webkit-animation-name: barber;
            animation-name: barber;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    background-size: 2.82em;
}

@-webkit-keyframes barber {
    to {
        background-position: -2.82em;
    }
}

@keyframes barber {
    to {
        background-position: -2.82em;
    }
}

li.older.failed {
    background-image: repeating-linear-gradient(
        45deg,
        transparent,
        transparent 1em,
        rgba(0, 0, 0, 0.2) 1em,
        rgba(0, 0, 0, 0.2) 2em
        );
    background-size: 2.82em;
}

/* For iPads */
@media (min-width: 48rem) and (orientation: portrait),
        (min-width: 64rem) and (orientation: landscape) {
    li.older {
        font-size: 1rem;
    }
}
form.note {
    background: var(--dashboard-widget-background);
    padding: 0.5rem;
    display: grid;
    grid-template-areas: "field button";
    grid-template-columns: 1fr 2rem;
    grid-template-rows: 2rem;
    grid-gap: .5rem;
}

form.note input[type="text"] {
    background: var(--dashboard-background);
    color: inherit;
    border: solid 1px gray;
    border-radius: .2rem;
    padding: 0 .5rem;
    grid-area: field-start / field-start / button-end / button-end;
    outline: none;
    transition: border-color 1s;
}

form.note input[type="text"]:focus {
    border-color: white;
}
form.note.failed input[type="text"] {
    box-shadow: 0 0 0 3px #d30;
}

form.note input[type="submit"] {
    grid-area: button;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border: solid 1px white;
    border-radius: .1rem;
    background-color: transparent;
    background-image: url(/static/media/Envelope.4c7f3e8d.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1.6rem;
    color: transparent;
    transition-property: all;
    transition-duration: .5s;
    align-self: center;
    justify-self: center;
    outline: none;
    opacity: 0.3
}


form.note:valid input[type="submit"] { opacity: .7; }
form.note:valid input[type="submit"]:hover { opacity: 1; }

form.note.pending input[type="submit"] {
    border-radius: 50%;
    background-size: 0;
    border-style: dashed;
    border-color: #379;
    border-width: 3px;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    -webkit-animation: rotation linear 2s infinite;
            animation: rotation linear 2s infinite;
}

form.note.pending input[type="text"] {
    transition: all .5s ease-in;
    padding-left: 90%;
    overflow-x: hidden;
    color: transparent;
}

@-webkit-keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

/* For iPads */
@media (min-width: 48rem) and (orientation: portrait),
        (min-width: 64rem) and (orientation: landscape) {
    form.note {
        grid-template-columns: 1fr 4rem;
        grid-template-rows: 2.5rem;
    }
    form.note input[type="text"] {
        font-size: 1.2rem;
    }
    form.note input[type="submit"] {
        background-size: 2.3rem;
    }
}
div.lifecycle.lifecycle-default-box {
    width: 100%;
    height: 100%;
    background: transparent;
    padding: 1em;
}

.lifecycle.pending {
    background-image: repeating-linear-gradient(
        45deg,
        transparent,
        transparent 1em,
        rgba(255, 255, 255, 0.2) 1em,
        rgba(255, 255, 255, 0.2) 2em
        );
    -webkit-animation-name: barber;
            animation-name: barber;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    background-size: 2.82em;
}

@-webkit-keyframes barber {
    to {
        background-position: -2.82em;
    }
}

@keyframes barber {
    to {
        background-position: -2.82em;
    }
}

.lifecycle.failed {
    background-image: repeating-linear-gradient(
        45deg,
        transparent,
        transparent 1em,
        rgba(0, 0, 0, 0.2) 1em,
        rgba(0, 0, 0, 0.2) 2em
        );
    background-size: 2.82em;
}

svg#tooltip-clicked {
    visibility: hidden;
    width: 100%;
}
svg#tooltip-clicked.visible {
    visibility: visible;
}

svg#tooltip-clicked .info .indicator{ 
    /* vertical-line */
    stroke: var(--dashboard-text-color);
    stroke-width: 3;
    fill-opacity: .8;
    fill: black;

}

svg#tooltip-clicked .info div.content{
    background-color: rgba(0,0,0,0.8);
    width: 100%;
    height: 100%;
}

svg#tooltip-clicked .info div.content div{
    margin: 0;
    width: 100%;
    height: 100%;
}
div.data-graph {
    display: grid;
    grid-template-columns: repeat(2, -webkit-min-content 1fr) -webkit-min-content;
    grid-template-columns: repeat(2, min-content 1fr) min-content;
    justify-items: center;
    width: 100%;
    height: 100%;
}

div.data-graph > svg {
    grid-column: 1 / span 5;
    grid-row: 1 / span 1;
    fill: none;
    width: 100%;
    height: 100%;
}

div.data-graph path.thermal-gradient {
    fill: url(#thermal-gradient);
}

div.data-graph path.nominal-line,
div.data-graph path.data-line {
    stroke: var(--dashboard-text-color);
    stroke-width: 0.05rem;
    shape-rendering: geometricPrecision;
    stroke-linecap: round;
    vector-effect: non-scaling-stroke;
}

div.data-graph path.data-line.environment {
    stroke: var(--dashboard-text-color);
    stroke-width: 0.15rem;
    stroke-dasharray: 0, 5;
    opacity: 0.5;
    stroke-linecap: round;
}

div.data-graph path.data-line.activity {
    stroke: var(--signal-color);
    stroke-width: 0.1rem;
    shape-rendering: geometricPrecision;
    stroke-linecap: round;
    opacity: 0.7;
    vector-effect: non-scaling-stroke;
}

div.data-graph path.nominal-line {
    stroke-dasharray: 1 2;
}

div.data-graph svg#tooltip {
    z-index: 98;
}
div.data-graph svg#tooltip g .circle {
    fill: var(--dashboard-text-color);
    cursor: pointer;
}

div.data-graph svg#tooltip g.Tsens,
div.data-graph svg#tooltip g.earTag {
    visibility: hidden;
}

div.data-graph svg#tooltip g.visible {
    visibility: visible;
}

div.data-graph text.hover-text {
    fill: var(--dashboard-text-color);
    /* text-rendering: optimizeLegibility; */
    font-weight: 500;
    paint-order: stroke fill;
    stroke: var(--dashboard-background);
    stroke-width: 0.5em;
    /* For IOS & Safari flushing problem */
    background-color: var(--dashboard-background);
}

div.data-graph time {
    font-size: 0.8rem;
    font-weight: 300;
    grid-row: 1 / span 1;
    align-self: end;
    padding: 0.2em;
    white-space: nowrap;
}

div.data-graph time:nth-of-type(1) {
    grid-column-start: 1;
}
div.data-graph time:nth-of-type(2) {
    grid-column-start: 2;
    visibility: hidden;
}
div.data-graph time:nth-of-type(3) {
    grid-column-start: 3;
}
div.data-graph time:nth-of-type(4) {
    grid-column-start: 4;
    visibility: hidden;
}
div.data-graph time:nth-of-type(5) {
    grid-column-start: 5;
}

@media (min-width: 30rem) and (max-width: 40rem), (min-width: 52rem) {
    div.data-graph time:nth-of-type(2n) {
        visibility: visible;
    }
}

div.data-graph data.temperature {
    grid-row: 1 / span 1;
    grid-column: 1 / span 5;
    font-size: 2.5rem;
    padding: 0.1rem;
    place-self: start end;
    font-feature-settings: "kern";
    -webkit-font-kerning: normal;
            font-kerning: normal;
    pointer-events: none;
}
div.data-graph data time {
    display: grid;
    align-self: start;
}
div.data-graph > time {
    pointer-events: none;
}
div.data-graph data.temperature.high {
    -webkit-align-self: end;
            align-self: end;
    margin-bottom: 1rem;
}

div.data-graph data.temperature .temperature-sign::after {
    content: "°C";
    font-weight: lighter;
    letter-spacing: -0.1em;
    margin-right: 0.1em;
}

svg#tooltip svg#tooltip-clicked:hover {
    cursor: -webkit-grab;
    cursor: grab;
}
svg#tooltip svg#tooltip-clicked:active {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

svg#tooltip svg#tooltip-clicked div.content div {
    display: grid;
    grid-template-areas: "tsens" "eartag" "time";
    grid-template-rows: 2.4rem 2.4rem 1fr;
    background-repeat: no-repeat;
}

svg#tooltip svg#tooltip-clicked div.content div time {
    grid-area: time;
    justify-self: end;
    font-size: 1em;
    margin-right: 0.4rem;
}
svg#tooltip svg#tooltip-clicked div.content span {
    font-size: 1.4em;
}

svg#tooltip svg#tooltip-clicked div.content div.Tsens {
    grid-area: tsens;
    background-image: url(/static/media/Tsens.faf7b00a.svg);
    background-size: 2.4rem;
    background-position: 0.6rem 0.6rem;
    font-weight: 600;
}
svg#tooltip svg#tooltip-clicked div.content div.sensor.Tsens span {
    justify-self: center;
    padding-top: 0.6rem;
    padding-left: 2rem;
}
svg#tooltip svg#tooltip-clicked div.content div.earTag {
    grid-area: eartag;
    background-image: url(/static/media/EarTag.a4d09280.svg);
    background-size: 2.4rem;
    background-position: 0.6rem 0.6rem;
}
svg#tooltip svg#tooltip-clicked div.content div.sensor.earTag span {
    justify-self: center;
    padding-top: 0.6rem;
    padding-left: 2rem;
    font-weight: 300;
}

svg#tooltip svg#tooltip-clicked div.content div.environment {
    background-image: url(/static/media/OutsideTemp.4a96e3d3.svg);
    background-size: 1.5rem;
    background-position: 1.2rem 1rem;
    font-weight: 300;
}

svg#tooltip svg#tooltip-clicked div.content div.sensor.environment span {
    justify-self: center;
    padding-top: 0.8rem;
    padding-left: 2rem;
}

svg#tooltip svg#tooltip-clicked div.content div.sensor.activity {
    grid-area: eartag;
    background-size: 2.4rem;
    background-position: 0.6rem 0.6rem;
}

svg#tooltip svg#tooltip-clicked div.content div.sensor.activity span {
    justify-self: center;
    padding-top: 0.6rem;
    padding-left: 2rem;
    font-weight: 300;
}

@media (min-width: 20rem) and (max-width: 40rem) {
    div.data-graph data.temperature {
        font-size: 1.4em;
    }
    main.dashboard.fullscreen div.data-graph data.temperature {
        font-size: 2rem;
    }
    main.dashboard.fullscreen div.data-graph data.temperature {
        grid-column: 6 / span 1;
    }
    main.dashboard.fullscreen div.data-graph data.temperature.high {
        -webkit-align-self: start;
                align-self: start;
    }
    main.dashboard.fullscreen div.data-graph data.temperature.mid {
        -webkit-align-self: center;
                align-self: center;
    }
    main.dashboard.fullscreen div.data-graph data.temperature.low {
        -webkit-align-self: end;
                align-self: end;
    }
}
@media (min-width: 40rem) and (max-width: 52rem), (min-width: 72rem) {
    div.data-graph data.temperature {
        grid-column: 6 / span 1;
    }
    div.data-graph data.temperature.high {
        -webkit-align-self: start;
                align-self: start;
    }
    div.data-graph data.temperature.mid {
        -webkit-align-self: center;
                align-self: center;
    }
    div.data-graph data.temperature.low {
        -webkit-align-self: end;
                align-self: end;
    }
}

/* Safari */
@media not all and (min-resolution: 0.001dpcm) {
    @media {
        div.data-graph {
            height: 8.6rem;
        }
    }
}

/* For iPads */
@media (min-width: 48rem) and (orientation: portrait),
    (min-width: 64rem) and (orientation: landscape) {
    div.data-graph > time,
    div.data-graph data time {
        font-size: 1rem;
    }
    svg#tooltip svg#tooltip-clicked div.content div {
        grid-template-rows: 3rem 3rem 1fr;
    }
    div.data-graph data.temperature {
        grid-column: 1 / span 5;
        position: absolute;
        top: 3rem;
    }
    main.dashboard.fullscreen div.data-graph data.temperature {
        top: 10rem;
    }
    div.data-graph data.temperature.high,
    div.data-graph data.temperature.mid,
    div.data-graph data.temperature.low {
        -webkit-align-self: start;
                align-self: start;
    }

    svg#tooltip svg#tooltip-clicked div.content div.earTag,
    svg#tooltip svg#tooltip-clicked div.content div.Tsens {
        background-size: 3rem;
        background-position: 0.3rem 0.3rem;
    }
    div.data-graph > svg {
        grid-column: 1 / span 5;
        grid-row: 1 / span 1;
        fill: none;
        width: 100%;
        height: 100%;
    }
}

svg#icons div.badge-item {
    --gradient: var(--dead-cow);
    --badge-size: 32px;
    background-image: var(--icon), var(--gradient);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%; 
    height: var(--badge-size);
    width: var(--badge-size);
    border-radius: 50%;
    border: solid calc(var(--badge-size)/2*0.15) var(--dashboard-text-color);
    box-sizing: border-box;
}

svg#icons div.badge-item.Tsens {
    --icon: url(/static/media/Tsens.faf7b00a.svg);
}

svg#icons div.badge-item.earTag {
    --icon: url(/static/media/EarTag.a4d09280.svg);
}

svg#icons div.badge-item.cow {
    --icon: url(/static/media/CowPictogram.e6c42c7e.svg);
}


svg#icons div.badge-item.estrous {
    background-size: 2.2rem;
    --icon: url(/static/media/estrous.e3fa64f4.svg);
}
svg#icons div.badge-item.temperature {
    --icon: url(/static/media/temperatureIcon.567d59a2.svg);
}
svg#icons div.badge-item.heatOver {
    background-size: 2.2rem;
    --icon: url(/static/media/heatOver.03963a4c.svg);
}


svg#icons div.badge-item.Tsens.idle,
svg#icons div.badge-item.Tsens.assigned,
svg#icons div.badge-item.Tsens.pending,
svg#icons div.badge-item.Tsens.network-problem { --gradient: var(--idle-sensor) }
svg#icons div.badge-item.Tsens.active,
svg#icons div.badge-item.Tsens.stable { --gradient: var(--stable-sensor) }
svg#icons div.badge-item.Tsens.unstable,
svg#icons div.badge-item.Tsens.low-battery { --gradient: var(--unstable-sensor) }
svg#icons div.badge-item.Tsens.fault { --gradient: var(--bad-sensor) }

svg#icons div.badge-item.earTag.idle,
svg#icons div.badge-item.earTag.assigned,
svg#icons div.badge-item.earTag.pending,
svg#icons div.badge-item.earTag.network-problem { --gradient: var(--idle-sensor) }
svg#icons div.badge-item.earTag.active,
svg#icons div.badge-item.earTag.stable { --gradient: var(--stable-sensor) }
svg#icons div.badge-item.earTag.unstable,
svg#icons div.badge-item.earTag.low-battery { --gradient: var(--unstable-sensor) }
svg#icons div.badge-item.earTag.fault { --gradient: var(--bad-sensor) }

svg#icons div.badge-item.cow.pregnant { --gradient: var(--good-cow) }
svg#icons div.badge-item.cow.due,
svg#icons div.badge-item.cow.overdue { --gradient: var(--bad-cow-handled) }
svg#icons div.badge-item.cow.calving,
svg#icons div.badge-item.estrous.heat { --gradient: var(--bad-cow) }

svg#icons div.badge-item.temperature.fever,
svg#icons div.badge-item.temperature.hypothermia { --gradient: var(--bad-cow) }
aside.cow-preview {
    background-color: var(--dashboard-background);
    padding: 0.2rem;
    display: grid;
    grid-template-areas:
        "header"
        "graph";

    grid-column-start: 1;
    grid-column-end: -1;
    position: relative;
    width: calc(100% + 2rem);
    box-sizing: border-box;
}

aside.cow-preview > header {
    grid-area: header;

    display: grid;
    grid-template-areas:
        "title title fullscreen-button"
        "title title identity"
        "title title breed-birthdate-place"
        "tags sessions .";
    grid-template-columns: 0.6fr 1fr 0.5fr;
    grid-template-rows: 1fr 1.4fr 2fr 3fr;
    padding: 0.1rem;
}

aside.cow-preview > div.body {
    grid-area: graph;
    /* display: grid;
    grid-template-areas: "picker" "chart";
    grid-template-rows: 2rem auto; */
}

aside.cow-preview > header > h1,
aside.cow-preview > header h4 {
    font-weight: 300;
    white-space: nowrap;
    margin: 0;
}
aside.cow-preview > header > h4 {
    font-size: 0.8em;
    place-self: start end;
}

aside.cow-preview > header > h1 {
    grid-area: title;
    font-size: 1.8rem;
    overflow: hidden;
    text-overflow: ellipsis;
}
aside.cow-preview header > h1 > strong {
    margin-right: 0.5rem;
}
aside.cow-preview header > span.fullscreen-button {
    grid-area: fullscreen-button;
    background-image: url(/static/media/normalScreen.c8145bff.svg);
    background-repeat: no-repeat;
    background-size: 1.6rem;
    width: 1.6rem;
    height: 1.6rem;
    justify-self: end;
    margin-top: 0.3rem;
    margin-right: 0.3rem;
}
aside.cow-preview header > span.fullscreen-button.off {
    background-image: url(/static/media/fullScreen.3fadf4dc.svg);
}
aside.cow-preview > header > h4.identity-number {
    grid-area: identity;
}

aside.cow-preview > header > div.breed-birthdate-place {
    grid-area: breed-birthdate-place;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    font-size: 0.8em;
    justify-self: end;
}
aside.cow-preview > header > .sessions {
    grid-area: sessions;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}
aside.cow-preview > header > div.sessions h4 {
    font-size: 0.8em;
    margin: 0;
}
aside.cow-preview .pickers {
    grid-area: picker;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}
aside.cow-preview .pickers > div {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    grid-gap: 0.4rem;
    gap: 0.4rem;
}
aside.cow-preview .pickers .graph-picker {
    background-color: #151515;
    background-blend-mode: multiply;
    border-radius: 12px;
    width: auto;
    height: -webkit-min-content;
    height: min-content;
    padding: 0 1em 0 1em;
    line-height: 1.8rem;
    cursor: pointer;
}
aside.cow-preview .pickers .graph-picker.disabled {
    opacity: 0.5;
    pointer-events: none;
}
aside.cow-preview div.sessions .session:before {
    width: 1em;
    height: 1em;
    content: "\00a0";
    display: inline-block;
    margin-right: 0.25em;
    background-image: linear-gradient(180deg, #35505a 0%, #556970 100%);
    border-radius: 0.2em;
    vertical-align: middle;
    margin-bottom: 0.1em;
}
aside.cow-preview div.sessions .session.stable:before {
    background-image: linear-gradient(180deg, #629f26 0%, #146c3c 100%);
}
aside.cow-preview div.sessions .session.unstable:before {
    background-image: linear-gradient(180deg, #f3492c 0%, #bc4040 100%);
}

aside.cow-preview > header > div.sessions h4 strong {
    cursor: pointer;
    text-decoration: underline;
}
aside.cow-preview > header > .tags {
    grid-area: tags;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin: 0;
    padding: 0;
}
aside.cow-preview > header > .tags span {
    font-weight: 300;
    margin-left: 0.3em;
}
aside.cow-preview header > .tags li:before {
    width: 1em;
    height: 1em;
    content: "\00a0";
    display: inline-block;
    margin-right: 0.25em;
    background-image: var(--dead-cow);
    /*For Safari flushing problem*/
    background-image: linear-gradient(180deg, #35505a 0%, #556970 100%);
    border-radius: 0.2em;
    vertical-align: middle;
    margin-bottom: 0.1em;
}
aside.cow-preview header > .tags li {
    display: inline-block;
}

aside.cow-preview header > .tags > li.tracked:before,
aside.cow-preview header > .tags > li.pregnant:before,
aside.cow-preview header > .tags > li.post-partum:before,
aside.cow-preview header > .tags > li.estrous:before,
aside.cow-preview header > .tags > li.inseminated:before {
    background-image: var(--good-cow);
    /*For Safari flushing problem*/
    background-image: linear-gradient(180deg, #629f26 0%, #146c3c 100%);
}

aside.cow-preview header > .tags > li.calving:before {
    background-image: var(--calving-cow);
    /*For Safari flushing problem*/
    background-image: linear-gradient(180deg, #2d9cdb 0%, #134561 100%);
}

aside.cow-preview header > .tags > li.hypothermia:before,
aside.cow-preview header > .tags > li.fever:before,
aside.cow-preview header > .tags > li.heat:before {
    background-image: var(--bad-cow);
    /*For Safari flushing problem*/
    background-image: linear-gradient(180deg, #f3492c 0%, #bc4040 100%);
}
aside.cow-preview header > .tags > li.due:before {
    background-image: (--due-cow);
    background-image: linear-gradient(180deg, #83d2ff 0%, #2d9cdb 100%);
}
aside.cow-preview header > .tags > li.overdue:before {
    background-image: var(--bad-cow-handled);
    /*For Safari flushing problem*/
    background-image: linear-gradient(180deg, #ac2e10 0%, #551717 100%);
}
@media (max-width: 35rem) {
    aside.cow-preview {
        min-height: 19.6rem;
        background-color: var(--dashboard-background);
        padding: 0;
        z-index: 5;
    }
    aside.cow-preview div.graph {
        display: grid;
        grid-template-areas:
            "header"
            "graph";
        box-sizing: border-box;
        min-height: 19.6rem;
        background-color: var(--dashboard-background);
    }
    aside.cow-preview div.event-group {
        display: grid;
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
        min-height: 19.6rem;
        background-color: var(--dashboard-background);
        box-sizing: border-box;
    }
    aside.cow-preview div.event-group div.cow-events {
        -webkit-align-self: start;
                align-self: start;
    }
    aside.cow-preview div.cow-options {
        display: grid;
        grid-template-areas:
            "options-header"
            "buttons";
        background-color: var(--dashboard-background);
        box-sizing: border-box;
        min-height: 19.6rem;
    }

    aside.cow-preview div.graph > div {
        grid-area: graph;
        border-left: none;
        background-color: var(--dashboard-background);
        height: 9rem;
        align-self: end;
    }

    aside.cow-preview div.graph header {
        grid-area: header;
        display: grid;
        grid-template-areas:
            "title fullscreen-button"
            "identity temperature"
            "breed-birthdate-place temperature"
            "sessions temperature"
            "tags temperature"
            "picker picker";
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 2.4rem 1.4rem 1.4rem 1.8rem 2.4rem 0.2rem;
        padding-left: 0.2rem;
        background-color: var(--dashboard-background);
    }
    /* aside.cow-preview header div.pickers {
        grid-area: picker;
    } */
    aside.cow-preview div.graph > header > h1 {
        grid-area: title;
        font-size: 1.8rem;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    aside.cow-preview div.graph > header > h4.identity-number {
        grid-area: identity;
        margin: 0.6rem 0.4rem 0 0;
        justify-self: start;
        font-weight: 500;
    }
    aside.cow-preview div.graph > header > .temperature {
        grid-area: temperature;
        align-self: center;
        font-size: 1em;
    }
    aside.cow-preview div.graph > header > data.temperature {
        font-size: 3em;
        padding: 0.1rem;
        place-self: start end;
        font-feature-settings: "kern";
        -webkit-font-kerning: normal;
                font-kerning: normal;
        pointer-events: none;
        text-align: end;
        -webkit-align-self: center;
                align-self: center;
    }
    aside.cow-preview div.graph > header > data.temperature time {
        font-size: 1rem;
        display: grid;
        align-self: end;
    }
    aside.cow-preview
        div.graph
        > header
        > data.temperature
        .temperature-sign::after {
        content: "°C";
        font-weight: lighter;
        letter-spacing: -0.1em;
        margin-right: 0.1em;
    }
    aside.cow-preview div.graph > header > div.breed-birthdate-place {
        grid-area: breed-birthdate-place;
        height: 1rem;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        font-size: 0.8em;
        justify-self: start;
        margin: 0.2rem 0.4rem 0 0;
    }
    aside.cow-preview div.graph > header > div.breed-birthdate h4 {
        font-weight: 500;
    }
    aside.cow-preview div.graph > header > .sessions {
        grid-area: sessions;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
        justify-self: start;
        margin-right: 0.4rem;
    }
    aside.cow-preview div.graph > header > .sessions .session {
        margin-top: 0.3rem;
    }
    aside.cow-preview div.graph > header > div.breed-birthdate-place h4 {
        font-size: 0.8em;
    }

    aside.cow-preview div.graph > header > h1,
    aside.cow-preview div.graph > header h4 {
        font-weight: 300;
        white-space: nowrap;
        margin: 0;
    }
    aside.cow-preview div.graph > header > h4 {
        font-size: 0.8em;
        place-self: start end;
    }

    aside.cow-preview div.graph > header > div.sessions h4 {
        font-size: 1em;
        margin: 0;
    }
    aside.cow-preview div.graph > header > .tags {
        grid-area: tags;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        margin: 0;
        padding: 0;
    }
    aside.cow-preview div.graph > header > .tags span {
        font-weight: 300;
        margin-left: 0.3em;
    }
    aside.cow-preview div.graph > header > a.fullscreen-button {
        grid-area: fullscreen-button;
        background-image: url(/static/media/fullScreen.3fadf4dc.svg);
        background-repeat: no-repeat;
        border: none;
        height: 1.8rem;
        width: 1.8rem;
        justify-self: end;
        margin-top: 0.5rem;
    }
    aside.cow-preview div > header > div.sessions h4 strong {
        cursor: pointer;
        text-decoration: underline;
    }
    aside.cow-preview div.graph > header > .tags > li.calving:before {
        background-image: var(--calving-cow);
        background-image: linear-gradient(180deg, #2d9cdb 0%, #134561 100%);
    }

    aside.cow-preview div.graph > header > .tags > li.hypothermia:before,
    aside.cow-preview div.graph > header > .tags > li.fever:before {
        background-image: var(--bad-cow);
        /*For Safari flushing problem*/
        background-image: linear-gradient(180deg, #f3492c 0%, #bc4040 100%);
    }
    aside.cow-preview div.graph > header > .tags > li.tracked:before,
    aside.cow-preview div.graph > header > .tags > li.pregnant:before,
    aside.cow-preview div.graph > header > .tags > li.estrous:before,
    aside.cow-preview div.graph > header > .tags > li.inseminated:before {
        background-image: var(--good-cow);
        /*For Safari flushing problem*/
        background-image: linear-gradient(180deg, #629f26 0%, #146c3c 100%);
    }

    aside.cow-preview div.graph > header > .tags > li.due:before {
        background-image: var(--due-cow);
        /*For Safari flushing problem*/
        background-image: linear-gradient(180deg, #83d2ff 0%, #2d9cdb 100%);
    }

    aside.cow-preview div.graph > header > .tags > li.overdue:before {
        background-image: var(--bad-cow-handled);
        /*For Safari flushing problem*/
        background-image: linear-gradient(180deg, #ac2e10 0%, #551717 100%);
    }
    aside.cow-preview div.cow-options header {
        grid-area: options-header;
        display: grid;
        grid-template-areas:
            "title ."
            "title ."
            "tags sessions"
            "tags sessions";
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1.4rem 1.5rem 1.4rem 3rem;
        padding-left: 0.2rem;
        background-color: var(--dashboard-background);
    }
    aside.cow-preview div.cow-options div {
        grid-area: buttons;
        border-left: none;
        background-color: var(--dashboard-background);
    }
    aside.cow-preview div.cow-options > header > h1 {
        grid-area: title;
        font-size: 1.8rem;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    aside.cow-preview div.cow-options > header > .sessions {
        grid-area: sessions;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
        justify-self: end;
        margin-right: 0.4rem;
        height: -webkit-max-content;
        height: max-content;
    }
    aside.cow-preview div.cow-options > header > .sessions .session {
        margin-top: 0.3rem;
    }

    aside.cow-preview div.cow-options > header > h1,
    aside.cow-preview div.cow-options > header h4 {
        font-weight: 300;
        white-space: nowrap;
        margin: 0;
    }
    aside.cow-preview div.cow-options > header > h4 {
        font-size: 0.8em;
        place-self: start end;
    }

    aside.cow-preview div.cow-options > header > div.sessions h4 {
        font-size: 1em;
        margin: 0;
    }
    aside.cow-preview div.cow-options > header > .tags {
        grid-area: tags;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        margin: 0;
        padding: 0;
        height: -webkit-max-content;
        height: max-content;
    }
    aside.cow-preview div.cow-options > header > .tags span {
        font-weight: 300;
    }
    aside.cow-preview div.cow-options > header > .tags li:before {
        width: 1em;
        height: 1em;
        content: "\00a0";
        display: inline-block;
        margin-right: 0.25em;
        background-image: var(--dead-cow);
        /*For Safari flushing problem*/
        background-image: linear-gradient(180deg, #35505a 0%, #556970 100%);
        border-radius: 0.2em;
        vertical-align: middle;
        margin-bottom: 0.1em;
    }
    aside.cow-preview div.cow-options > header > .tags li {
        display: inline-block;
    }
    aside.cow-preview div.cow-options > header > .tags > li.calving:before {
        background-image: var(--calving-cow);
        background-image: linear-gradient(180deg, #2d9cdb 0%, #134561 100%);
    }

    aside.cow-preview div.cow-options > header > .tags > li.hypothermia:before,
    aside.cow-preview div.cow-options > header > .tags > li.fever:before {
        background-image: var(--bad-cow);
        /*For Safari flushing problem*/
        background-image: linear-gradient(180deg, #f3492c 0%, #bc4040 100%);
    }
    aside.cow-preview div.cow-options > header > .tags > li.tracked:before,
    aside.cow-preview div.cow-options > header > .tags > li.pregnant:before,
    aside.cow-preview div.cow-options > header > .tags > li.estrous:before,
    aside.cow-preview div.cow-options > header > .tags > li.inseminated:before {
        background-image: var(--good-cow);
        /*For Safari flushing problem*/
        background-image: linear-gradient(180deg, #629f26 0%, #146c3c 100%);
    }
    aside.cow-preview div.cow-options > header > .tags > li.due:before {
        background-image: var(--due-cow);
        /*For Safari flushing problem*/
        background-image: linear-gradient(180deg, #83d2ff 0%, #2d9cdb 100%);
    }
    aside.cow-preview div.cow-options > header > .tags > li.overdue:before {
        background-image: var(--bad-cow-handled);
        /*For Safari flushing problem*/
        background-image: linear-gradient(180deg, #ac2e10 0%, #551717 100%);
    }
    aside.cow-preview div.cow-options div button {
        background-repeat: no-repeat;
        margin: 0;
        margin-left: 0.5rem;
        margin-bottom: 0.3rem;
        background-position: top;
        background-color: var(--dashboard-background);
        background-size: 2.3rem;
        border: solid 1px var(--dashboard-box-border);
        height: 3.5rem;
        font-size: 0.9rem;
        font-weight: 200;
        padding-top: 2rem;
    }
    aside.cow-preview div.cow-options div .edit {
        --edit: url(/static/media/Edit.e29be074.svg);
        background-image: var(--edit);
        min-width: 4rem;
    }
    aside.cow-preview div.cow-options div .earTag {
        --note: url(/static/media/EarTag.a4d09280.svg);
        background-image: var(--note);
    }
    aside.cow-preview div.cow-options div .Tsens {
        --note: url(/static/media/Tsens.faf7b00a.svg);
        background-image: var(--note);
    }

    aside.cow-preview::-webkit-scrollbar {
        display: none;
    }
}

@media (min-width: 29rem) and (max-width: 40rem) {
    aside.cow-preview {
        height: 16rem;
        z-index: 1;
    }
    aside.cow-preview > div {
        border-left: none;
    }
    aside.cow-preview > header {
        grid-template-areas:
            "title fullscreen-button"
            "title identity"
            "tags breed-birthdate-place"
            "tags sessions";
        grid-template-columns: 14rem 1fr;
        grid-template-rows: 1.4rem 1.4rem 1.3rem;
    }
    aside.cow-preview > header > h4.identity-number {
        margin: 0.6rem 0.4rem 0 0;
        justify-self: end;
    }
    aside.cow-preview > header > div.breed-birthdate-place {
        margin: 0.2rem 0.4rem 0 0;
    }
    aside.cow-preview > header > .sessions {
        -webkit-flex-direction: column;
                flex-direction: column;
        justify-self: end;
        margin-right: 0.4rem;
    }
    aside.cow-preview > header > .sessions .session {
        margin-top: 0.3rem;
    }
    aside.cow-preview > header > div.breed-birthdate-place h4 {
        font-size: 0.8em;
    }
    aside.cow-preview > header > .sessions .session {
        margin-top: 0.3rem;
    }
    aside.cow-preview > header > div.breed-birthdate h4 {
        font-size: 0.8em;
    }
}
@media (min-width: 40rem) and (max-width: 52rem), (min-width: 64rem) {
    aside.cow-preview {
        grid-template-areas: "header graph";
        grid-template-columns: -webkit-min-content 1fr;
        grid-template-columns: min-content 1fr;
        grid-column-gap: 1rem;
        z-index: 1;
    }
    aside.cow-preview > header {
        grid-template-areas:
            "title fullscreen-button"
            "identity identity"
            "breed-birthdate-place ."
            "tags tags"
            "sessions sessions";
        grid-template-columns: -webkit-max-content auto;
        grid-template-columns: max-content auto;
        grid-template-rows: 2.2rem 1rem 2rem 5rem auto;
    }
    aside.cow-preview > header h4,
    aside.cow-preview > header div.breed-birthdate-place {
        justify-self: start;
    }
    main.dashboard.fullscreen aside.cow-preview > header .identity-number,
    main.dashboard.fullscreen
        aside.cow-preview
        > header
        div.breed-birthdate
        h4 {
        font-size: 1.2rem;
    }
    aside.cow-preview > header > .tags {
        justify-self: left center;
    }
    main.dashboard aside.cow-preview header > span.fullscreen-button {
        position: absolute;
    }
    main.dashboard.fullscreen
        aside.cow-preview
        header
        > span.fullscreen-button {
        position: relative;
    }
    aside.cow-preview > header > h1 {
        width: 10rem;
    }
    main.dashboard aside.cow-preview div .data-graph {
        height: 7.6rem;
    }
}

aside.cow-preview > header > h4.session > button {
    background-color: var(--dashboard-widget-background);
    color: var(--dashboard-background);
    border: none;
    margin: 0 0.5em;
    border-radius: 0.25em;
    outline: none;
}

aside.cow-preview > header > h4.session > button:hover {
    background-color: var(--dashboard-text-color);
    color: var(--dashboard-background);
}

/* Full Screen */
main.dashboard.fullscreen aside.cow-preview {
    grid-template-areas:
        "header"
        "graph";
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    margin-left: 0;
    width: 100%;
    height: 100vh;
}
main.dashboard.fullscreen aside.cow-preview > header {
    grid-template-areas:
        "title fullscreen-button"
        "title identity"
        ". breed-birthdate-place"
        "tags tags"
        "sessions sessions";
    grid-template-columns: auto auto;
    grid-template-rows: -webkit-min-content 1.8rem 0.8rem 3.5rem auto;
    grid-template-rows: min-content 1.8rem 0.8rem 3.5rem auto;
}
main.dashboard.fullscreen aside.cow-preview > header > h4 {
    justify-self: end;
}
main.dashboard.fullscreen aside.cow-preview > header > div.breed-birthdate {
    justify-self: end;
}
main.dashboard.fullscreen aside.cow-preview > header > div.breed-birthdate h4 {
    font-weight: 500;
}
main.dashboard.fullscreen aside.cow-preview > header > h4.identity-number {
    margin-top: 0.5rem;
    font-weight: 500;
}
main.dashboard.fullscreen aside.cow-preview div .data-graph {
    height: 52vh;
}
@media screen and (orientation: portrait) {
    main.dashboard.fullscreen aside.cow-preview {
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
        -webkit-transform-origin: left top;
                transform-origin: left top;
        height: 100vw;
        width: 100vh;
        overflow-x: hidden;
        position: absolute;
        top: 0;
        left: 100vw;
    }
    main.dashboard.fullscreen aside.cow-preview > header .identity-number,
    main.dashboard.fullscreen
        aside.cow-preview
        > header
        div.breed-birthdate
        h4 {
        font-size: 1.2rem;
    }
    main.dashboard.fullscreen aside.cow-preview div .data-graph {
        height: 52vw;
    }
}
/* Safari */
@media not all and (min-resolution: 0.001dpcm) {
    @media (max-width: 35rem) {
        aside.cow-preview div.graph {
            padding-left: 0.1rem;
            padding-right: 0.1rem;
        }
        aside.cow-preview div.event-group {
            padding-left: 0.1rem;
            padding-right: 0.1rem;
        }
    }
    @media (orientation: landscape) {
        main.dashboard.fullscreen aside.cow-preview {
            width: 100vw;
        }
        aside.cow-preview > div {
            -webkit-align-self: end;
                    align-self: end;
        }
    }
    @media (min-width: 48rem) and (orientation: portrait),
        (min-width: 64rem) and (orientation: landscape) {
        main.dashboard.fullscreen aside.cow-preview div .data-graph {
            height: 20rem;
        }
        aside.cow-preview div .data-graph data.temperature {
            font-size: 3.3em;
            text-align: end;
        }
        aside.cow-preview div .data-graph data.temperature time {
            font-size: 1rem;
        }
        main.dashboard.fullscreen
            aside.cow-preview
            div
            .data-graph
            data.temperature {
            font-size: 4em;
        }
        main.dashboard.fullscreen
            aside.cow-preview
            div
            .data-graph
            data.temperature
            time {
            font-size: 1.5rem;
        }
    }
}

/* For iPad */
@media (min-width: 48rem) and (orientation: portrait),
    (min-width: 64rem) and (orientation: landscape) {
    aside.cow-preview {
        min-height: 24rem;
        background-color: var(--dashboard-background);
        padding: 0;
        z-index: 5;
    }
    aside.cow-preview div.graph {
        display: grid;
        grid-template-areas:
            "header"
            "graph";
        box-sizing: border-box;
        min-height: 24rem;
        background-color: var(--dashboard-background);
        width: 100vw;
    }
    aside.cow-preview div.event-group {
        display: grid;
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
        min-height: 24rem;
        background-color: var(--dashboard-background);
        box-sizing: border-box;
        width: 100vw;
    }
    aside.cow-preview div.event-group div.cow-events {
        -webkit-align-self: start;
                align-self: start;
    }
    aside.cow-preview div.cow-options {
        display: grid;
        grid-template-areas:
            "options-header"
            "buttons";
        background-color: var(--dashboard-background);
        box-sizing: border-box;
        min-height: 24rem;
        width: 100vw;
    }

    aside.cow-preview .fullscreen-button {
        position: absolute;
        top: 0.3rem;
        right: 0.3rem;
    }

    aside.cow-preview div.graph > div {
        grid-area: graph;
        border-left: none;
        background-color: var(--dashboard-background);
        height: 12.1rem;
        align-self: end;
    }

    aside.cow-preview div.graph header {
        grid-area: header;
        display: grid;
        grid-template-areas:
            "title fullscreen-button"
            "identity temperature"
            "breed-birthdate temperature"
            "sessions temperature"
            "tags temperature"
            "picker picker";
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 2.4rem 2rem 2rem 2rem 3.3rem 0.2rem;
        padding-left: 0.2rem;
        background-color: var(--dashboard-background);
    }
    /* aside.cow-preview header div.pickers {
        grid-area: picker;
    } */
    aside.cow-preview div.graph > header > h1 {
        grid-area: title;
        font-size: 2rem;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    aside.cow-preview div.graph > header > h4.identity-number {
        grid-area: identity;
        margin: 0.6rem 0.4rem 0 0;
        justify-self: start;
        font-weight: 500;
        font-size: larger;
    }
    aside.cow-preview div.graph > header > .temperature {
        grid-area: temperature;
        align-self: center;
        font-size: 1em;
    }
    aside.cow-preview div.graph > header > data.temperature {
        font-size: 3em;
        padding: 0.1rem;
        place-self: start end;
        font-feature-settings: "kern";
        -webkit-font-kerning: normal;
                font-kerning: normal;
        pointer-events: none;
        text-align: end;
        -webkit-align-self: center;
                align-self: center;
    }
    aside.cow-preview div.graph > header > data.temperature time {
        font-size: 1rem;
        display: grid;
        align-self: end;
    }
    aside.cow-preview
        div.graph
        > header
        > data.temperature
        .temperature-sign::after {
        content: "°C";
        font-weight: lighter;
        letter-spacing: -0.1em;
        margin-right: 0.1em;
    }
    aside.cow-preview div.graph > header > div.breed-birthdate {
        grid-area: breed-birthdate;
        height: 1rem;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        font-size: 1.2em;
        justify-self: start;
        margin: 0.2rem 0.4rem 0 0;
    }
    aside.cow-preview div.graph > header > div.breed-birthdate h4 {
        font-weight: 500;
    }
    aside.cow-preview div.graph > header > .sessions {
        grid-area: sessions;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
        justify-self: start;
        margin-right: 0.4rem;
        font-size: larger;
    }
    aside.cow-preview div.graph > header > .sessions .session {
        margin-top: 0.3rem;
    }
    aside.cow-preview div.graph > header > div.breed-birthdate h4 {
        font-size: 0.8em;
    }

    aside.cow-preview div.graph > header > h1,
    aside.cow-preview div.graph > header h4 {
        font-weight: 300;
        white-space: nowrap;
        margin: 0;
    }
    aside.cow-preview div.graph > header > h4 {
        font-size: 0.8em;
        place-self: start end;
    }

    aside.cow-preview div.graph > header > div.sessions h4 {
        font-size: 1em;
        margin: 0;
    }
    aside.cow-preview div.graph > header > .tags {
        grid-area: tags;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        margin: 0;
        padding: 0;
        font-size: larger;
    }
    aside.cow-preview div.graph > header > .tags span {
        font-weight: 300;
        margin-left: 0.3em;
    }
    aside.cow-preview div.graph > header > a.fullscreen-button {
        grid-area: fullscreen-button;
        background-image: url(/static/media/fullScreen.3fadf4dc.svg);
        background-repeat: no-repeat;
        border: none;
        height: 1.8rem;
        width: 1.8rem;
        justify-self: end;
        margin-top: 0.5rem;
    }
    aside.cow-preview div > header > div.sessions h4 strong {
        cursor: pointer;
        text-decoration: underline;
    }
    aside.cow-preview div.graph > header > .tags > li.calving:before,
    aside.cow-preview div.graph > header > .tags > li.hypothermia:before,
    aside.cow-preview div.graph > header > .tags > li.fever:before,
    aside.cow-preview div.graph > header > .tags > li.due:before {
        background-image: var(--bad-cow);
        /*For Safari flushing problem*/
        background-image: linear-gradient(180deg, #f3492c 0%, #bc4040 100%);
    }
    aside.cow-preview div.graph > header > .tags > li.tracked:before,
    aside.cow-preview div.graph > header > .tags > li.pregnant:before,
    aside.cow-preview div.graph > header > .tags > li.estrous:before,
    aside.cow-preview div.graph > header > .tags > li.inseminated:before {
        background-image: var(--good-cow);
        /*For Safari flushing problem*/
        background-image: linear-gradient(180deg, #629f26 0%, #146c3c 100%);
    }
    aside.cow-preview div.graph > header > .tags > li.due:before,
    aside.cow-preview div.graph > header > .tags > li.overdue:before {
        background-image: var(--bad-cow-handled);
        /*For Safari flushing problem*/
        background-image: linear-gradient(180deg, #ac2e10 0%, #551717 100%);
    }
    aside.cow-preview div.cow-options header {
        grid-area: options-header;
        display: grid;
        grid-template-areas:
            "title ."
            "title ."
            "tags sessions"
            "tags sessions";
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1.4rem 1.5rem 1.4rem 3rem;
        padding-left: 0.2rem;
        background-color: var(--dashboard-background);
    }
    aside.cow-preview div.cow-options div {
        grid-area: buttons;
        border-left: none;
        background-color: var(--dashboard-background);
    }
    aside.cow-preview div.cow-options > header > h1 {
        grid-area: title;
        font-size: 1.8rem;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    aside.cow-preview div.cow-options > header > .sessions {
        grid-area: sessions;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
        justify-self: end;
        margin-right: 0.4rem;
        height: -webkit-max-content;
        height: max-content;
    }
    aside.cow-preview div.cow-options > header > .sessions .session {
        margin-top: 0.3rem;
    }

    aside.cow-preview div.cow-options > header > h1,
    aside.cow-preview div.cow-options > header h4 {
        font-weight: 300;
        white-space: nowrap;
        margin: 0;
    }
    aside.cow-preview div.cow-options > header > h4 {
        font-size: 0.8em;
        place-self: start end;
    }

    aside.cow-preview div.cow-options > header > div.sessions h4 {
        font-size: 1em;
        margin: 0;
    }
    aside.cow-preview div.cow-options > header > .tags {
        grid-area: tags;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        margin: 0;
        padding: 0;
        height: -webkit-max-content;
        height: max-content;
    }
    aside.cow-preview div.cow-options > header > .tags span {
        font-weight: 300;
    }
    aside.cow-preview div.cow-options > header > .tags li:before {
        width: 1em;
        height: 1em;
        content: "\00a0";
        display: inline-block;
        margin-right: 0.25em;
        background-image: var(--dead-cow);
        /*For Safari flushing problem*/
        background-image: linear-gradient(180deg, #35505a 0%, #556970 100%);
        border-radius: 0.2em;
        vertical-align: middle;
        margin-bottom: 0.1em;
    }
    aside.cow-preview div.cow-options > header > .tags li {
        display: inline-block;
    }
    aside.cow-preview div.cow-options > header > .tags > li.calving:before,
    aside.cow-preview div.cow-options > header > .tags > li.hypothermia:before,
    aside.cow-preview div.cow-options > header > .tags > li.fever:before,
    aside.cow-preview div.cow-options > header > .tags > li.due:before {
        background-image: var(--bad-cow);
        /*For Safari flushing problem*/
        background-image: linear-gradient(180deg, #f3492c 0%, #bc4040 100%);
    }
    aside.cow-preview div.cow-options > header > .tags > li.tracked:before,
    aside.cow-preview div.cow-options > header > .tags > li.pregnant:before,
    aside.cow-preview div.cow-options > header > .tags > li.estrous:before,
    aside.cow-preview div.cow-options > header > .tags > li.inseminated:before {
        background-image: var(--good-cow);
        /*For Safari flushing problem*/
        background-image: linear-gradient(180deg, #629f26 0%, #146c3c 100%);
    }
    aside.cow-preview div.cow-options > header > .tags > li.due:before,
    aside.cow-preview div.cow-options > header > .tags > li.overdue:before {
        background-image: var(--bad-cow-handled);
        /*For Safari flushing problem*/
        background-image: linear-gradient(180deg, #ac2e10 0%, #551717 100%);
    }
    aside.cow-preview div.cow-options div button {
        background-repeat: no-repeat;
        margin: 0;
        margin-left: 0.5rem;
        margin-bottom: 0.3rem;
        background-position: top;
        background-color: var(--dashboard-background);
        background-size: 2.3rem;
        border: solid 1px var(--dashboard-box-border);
        height: 3.5rem;
        font-size: 0.9rem;
        font-weight: 200;
        padding-top: 2rem;
    }
    aside.cow-preview div.cow-options div .edit {
        --edit: url(/static/media/Edit.e29be074.svg);
        background-image: var(--edit);
        min-width: 4rem;
    }
    aside.cow-preview div.cow-options div .earTag {
        --note: url(/static/media/EarTag.a4d09280.svg);
        background-image: var(--note);
    }
    aside.cow-preview div.cow-options div .Tsens {
        --note: url(/static/media/Tsens.faf7b00a.svg);
        background-image: var(--note);
    }

    aside.cow-preview::-webkit-scrollbar {
        display: none;
    }

    main.dashboard aside.cow-preview div .data-graph {
        height: 12.1rem;
    }
}

.cow-preview [data-cow-slider-container] {
  position: relative;
}

.cow-preview [data-cow-slider-overlay] {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: -webkit-transform 300ms ease-out;
  transition: transform 300ms ease-out;
  transition: transform 300ms ease-out, -webkit-transform 300ms ease-out;
  z-index: 3;
}
.cow-preview [data-cow-slider-overlay*="drag"] {
  transition: none;
  touch-action: pan-x;
  z-index: 4;
}
.cow-preview [data-cow-slider-overlay="slide-left-to-right"],
.cow-preview [data-cow-slider-overlay="slide-left-to-right"] *,
.cow-preview [data-cow-slider-overlay="slide-right-to-left"],
.cow-preview [data-cow-slider-overlay="slide-right-to-left"] *,
.cow-preview [data-cow-slider-overlay="right-to-left"],
.cow-preview [data-cow-slider-overlay="right-to-left"] *,
.cow-preview [data-cow-slider-overlay="left-to-right"],
.cow-preview [data-cow-slider-overlay="left-to-right"] *{
  z-index: 1;
}
.cow-preview [data-cow-slider-overlay="back-rest-to-left"],
.cow-preview [data-cow-slider-overlay="back-rest-to-right"]{
  z-index: 2;
}
.cow-preview [data-cow-slider-overlay="back-left-to-right"],
.cow-preview [data-cow-slider-overlay="back-right-to-left"]{
  z-index: 1;
}
.cow-preview [data-cow-slider-overlay="rest-to-left"]{
  -webkit-transform: translate(100%);
          transform: translate(100%);
  z-index: 4;
}
.cow-preview [data-cow-slider-overlay="rest-to-right"]{
  -webkit-transform: translate(-100%);
          transform: translate(-100%);
  z-index: 4;
}

.cow-preview [data-cow-slider-left]{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  min-height: 100%;
  z-index: 3;
  transition: -webkit-transform 300ms ease-out;
  transition: transform 300ms ease-out;
  transition: transform 300ms ease-out, -webkit-transform 300ms ease-out;
}
.cow-preview [data-cow-slider-left*="slide-left"] {
  transition: none;
  touch-action: pan-x;
  z-index: 4;
}
.cow-preview [data-cow-slider-left="slide-right-to-rest"],
.cow-preview [data-cow-slider-left="drag-right"],
.cow-preview [data-cow-slider-left="right-to-rest"],
.cow-preview [data-cow-slider-left="rest-to-right"] {
  z-index: 1;
}
.cow-preview [data-cow-slider-left="back-left-to-rest"],
.cow-preview [data-cow-slider-left="back-left-to-right"]{
  z-index: 2;
}
.cow-preview [data-cow-slider-left="back-right-to-rest"],
.cow-preview [data-cow-slider-left="back-rest-to-right"]{
  z-index: 1;
}
.cow-preview [data-cow-slider-left="left-to-rest"] {
  -webkit-transform: translate(-100%);
          transform: translate(-100%);
  z-index: 4;
}

.cow-preview [data-cow-slider-left="left-to-right"] {
  -webkit-transform: translate(100%);
          transform: translate(100%);
  z-index: 4;
}
.cow-preview [data-cow-slider-right] {
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  min-height: 100%;
  z-index: 3;
  transition: -webkit-transform 300ms ease-out;
  transition: transform 300ms ease-out;
  transition: transform 300ms ease-out, -webkit-transform 300ms ease-out;
}
.cow-preview [data-cow-slider-right*="slide-right"] {
  transition: none;
  touch-action: pan-x;
  z-index: 4;
}
.cow-preview [data-cow-slider-right="slide-left-to-rest"],
.cow-preview [data-cow-slider-right="drag-left"],
.cow-preview [data-cow-slider-right="left-to-rest"],
.cow-preview [data-cow-slider-right="rest-to-left"] {
  z-index: 1;
}
.cow-preview [data-cow-slider-right="back-right-to-rest"],
.cow-preview [data-cow-slider-right="back-right-to-left"]{
  z-index: 2;
}
.cow-preview [data-cow-slider-right="back-left-to-rest"],
.cow-preview [data-cow-slider-right="back-rest-to-left"] {
  z-index: 1;
}
.cow-preview [data-cow-slider-right="rest"]{
  -webkit-transform: translate(100%);
          transform: translate(100%);
  z-index: 1;
}
.cow-preview [data-cow-slider-right="right-to-rest"] {
  -webkit-transform: translate(100%);
          transform: translate(100%);
  z-index: 4;
}

.cow-preview [data-cow-slider-right="right-to-left"] {
  -webkit-transform: translate(-100%);
          transform: translate(-100%);
  z-index: 4;
}
aside.pairing-list-preview {
    background-color: var(--dashboard-background);
    display: grid;
    grid-template-areas: "header"
                          "pairing-main" ;
    grid-template-rows: 5rem auto;


    grid-column-start: 1;
    grid-column-end: -1;
    width: calc(100% + 2rem);
    box-sizing: border-box;
    z-index: 1;
}

aside.pairing-list-preview > header {
    grid-area: header;
    display: grid;
    grid-template-areas: "list-name"
                          "time-user"
                         "download-pdf";
    grid-template-rows: 2rem 2rem 1rem;
    padding: 0 1rem 0 1rem;
}
aside.pairing-list-preview > header > h1.list-name {
    grid-area: list-name;
    margin: .4rem 0 0 0;
    font-size: 1.8rem;
    font-weight: 300;
}
aside.pairing-list-preview > header > h1.list-name .list-id{
    font-weight: 600;
}
aside.pairing-list-preview > header > h3.time-user {
    grid-area: time-user;
    margin: .6rem 0 0 0;
    font-size: 0.9rem;
    font-weight: 300;
}

aside.pairing-list-preview > div {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;   
    grid-area: pairing-main;
    overflow: auto;
}

aside.pairing-list-preview > header > h2.download-pdf {
    font-size: 1rem;
    --pdf: url(/static/media/PdfChecklist.2296ec01.svg);
    opacity: 1;
    background-image: var(--pdf);
    align-self: center;
    padding: 0rem;
    margin-left: 1rem;
    padding-left: 3.0rem;
    background-size: 2.5rem;
    margin-bottom: 1.1rem;
    background-position: left;
    background-repeat: no-repeat;
    cursor: pointer;
    grid-area: download-pdf;
}
aside.pairing-list-preview > div > .pairing-list {
    grid-area: pairing-list;
    font-style: normal;
    font-weight: 300;
    font-size: 1.18rem;
    line-height: 1.18rem;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column wrap;
            flex-flow: column wrap;
    list-style-type: none;
    -webkit-flex: 1 1;
            flex: 1 1;
    margin: 0.4rem;
    padding: 0.6rem;
    height: 9rem;
    width: 14rem;
}

aside.pairing-list-preview > div > .pairing-list li:before{
    width: 1em;
    height: .88em;
    content: "\00a0";
    display: inline-block;
    margin-right: .25em;
    /*For Safari flushing problem*/
    background-image: linear-gradient(180deg, #35505A 0%, #556970 100%);
    border-radius: .29em;
    vertical-align: top;
    padding: 0.03em;
}

aside.pairing-list-preview > div > .pairing-list li{
    margin: 0.12rem;
    margin-right: 0.5rem;
}

aside.pairing-list-preview > div > .pairing-list > li.active:before {
    /*For Safari flushing problem*/
    background-image: linear-gradient(180deg, #629F26 0%, #146C3C 100%);
}

aside.pairing-list-preview > div > .pairing-list > li.archived:before {
    /*For Safari flushing problem*/
    background-image: linear-gradient(180deg, #35505A 0%, #556970 100%);
    opacity: .5;
}
@media (min-width: 35rem) {
    aside.pairing-list-preview {
        height: 11rem;
        grid-template-areas: "header pairing-main";
        grid-template-columns: -webkit-max-content auto;
        grid-template-columns: max-content auto;
        grid-template-rows: auto;
    }
    aside.pairing-list-preview > header {
         grid-template-rows: 2rem 3rem auto;
        /* grid-template-rows: 2rem 3rem 1rem; */
    }
    aside.pairing-list-preview > header > h2.download-pdf {
        grid-area: download-pdf;
        width: 9rem;
        margin-left: 0;
    }
    aside.pairing-list-preview > div > .pairing-list {
        margin: 0.4rem 0 0 0.5rem;
        height: 8.2rem;
    }
}
/* For iPads */
@media (min-width: 48rem) and (orientation: portrait),
        (min-width: 64rem) and (orientation: landscape) {
            aside.pairing-list-preview {
                grid-template-columns: 20rem auto;
                height: 15rem;
            }
            aside.pairing-list-preview > header {
                grid-template-rows: 3rem 3rem auto;
            }
            aside.pairing-list-preview > header > h1.list-name {
                font-size: 2.1rem;
            }
            aside.pairing-list-preview > header > h3.time-user {
                font-size: 1.1rem;
            }
            aside.pairing-list-preview > header > h2.download-pdf {
                background-size: 2.8rem;
                font-size: 1.3rem;
                padding-left: 3.5rem;
            }
            aside.pairing-list-preview > div > .pairing-list {
                font-size: 1.35rem;
            }
        }

aside.system-preview {
    background-color: var(--dashboard-background);
    padding: 0.2rem;
    display: grid;
    grid-template-areas: "wicow-server internet basestation repeaters tsens-summary eartag-summary";
    /* grid-template-columns: repeat(6, auto); */
    /* grid-template-columns: 10rem 10rem 10rem 10rem 10rem 10rem; */
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    position: relative;
    grid-column-start: 1;
    grid-column-end: -1;

    width: calc(100% + 2rem);
    min-height: 12rem;
    box-sizing: border-box;
    z-index: 1;
    font-size: 0.9em;
    font-weight: 300;
}

aside.system-preview label {
    grid-area: label;
    text-align: center;
}
aside.system-preview label:before {
    width: 1em;
    height: 1em;
    content: "\00a0";
    display: inline-block;
    margin-right: 0.25em;
    background-image: var(--idle-sensor);
    /*For Safari flushing problem*/
    background-image: linear-gradient(180deg, #35505a 0%, #556970 100%);
    border-radius: 0.2em;
    vertical-align: middle;
    margin-bottom: 0.1em;
}
aside.system-preview label.online:before,
aside.system-preview .wicow-server label:before,
aside.system-preview .internet label:before {
    background-image: var(--stable-sensor);
    /*For Safari flushing problem*/
    background-image: linear-gradient(180deg, #629f26 0%, #146c3c 100%);
}
aside.system-preview label.offline:before,
aside.system-preview .internet label.offline:before {
    background-image: var(--unstable-sensor);
    /*For Safari flushing problem*/
    background-image: linear-gradient(180deg, #f0960e 0%, #6c5914 100%);
}

aside.system-preview .single {
    display: grid;
    grid-template-areas: "icon" "label";
    grid-template-rows: 3fr 1fr;
}

aside.system-preview > .wicow-server {
    grid-area: wicow-server;
    background: url(/static/media/WicowServer.43bc25ef.svg) no-repeat;
    background-position: 50% 33%;
    background-size: 2rem;
}

aside.system-preview > .internet {
    grid-area: internet;
    background: url(/static/media/Internet.31d4bf73.svg) no-repeat;
    background-position: 50% 33%;
    background-size: 4rem;
}

aside.system-preview > .basestation {
    grid-area: basestation;
    background: url(/static/media/baseStation.c88d3b2c.svg) no-repeat;
    background-position: 50% 40%;
    background-size: 2.4rem;
}
aside.system-preview > .basestation label {
    cursor: pointer;
    -webkit-align-self: start;
            align-self: start;
    justify-self: center;
}
aside.system-preview .repeater .content,
aside.system-preview .basestation .content {
    display: grid;
    width: 10rem !important;
    height: 8rem !important;
    padding: 0.3rem;
}
aside.system-preview .basestation .content {
    grid-template-areas: "sensor" "status" "last-packet";
}

aside.system-preview > .repeaters {
    grid-area: repeaters;

    display: grid;
    grid-template-rows: repeat(auto, 2rem);
    grid-row-start: 1;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

aside.system-preview > .repeaters .repeater {
    background: url(/static/media/RepeaterSupervisor.86014434.svg) no-repeat;
    background-position: left;
    background-size: 2rem;
    display: grid;
    max-width: 6.4rem;
}
aside.system-preview > .repeaters .repeater.supervisor {
    background-image: url(/static/media/RepeaterSupervisor.86014434.svg);
}

aside.system-preview > .repeaters .repeater label {
    cursor: pointer;
    -webkit-align-self: start;
            align-self: start;
}

aside.system-preview > .tsens-summary,
aside.system-preview > .eartag-summary {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-self: center;
            align-self: center;
}
aside.system-preview > .tsens-summary span strong,
aside.system-preview > .eartag-summary span strong {
    display: inline-block;
    width: 2rem;
    text-align: right;
    margin-right: 0.3rem;
}

aside.system-preview > .tsens-summary span,
aside.system-preview > .eartag-summary span {
    padding-left: 0.8rem;
}

aside.system-preview > .tsens-summary span:first-child {
    background: url(/static/media/Tsens.faf7b00a.svg) no-repeat;
    background-position: left;
    background-size: 2rem;
}
aside.system-preview > .eartag-summary span:first-child {
    background: url(/static/media/EarTag.a4d09280.svg) no-repeat;
    background-position: left;
    background-size: 2rem;
}
aside.system-preview .repeater .content {
    grid-template-areas: "sensor" "status" "last-packet" "daily-traffic";
    grid-template-rows: 1fr 2fr 2fr 2fr;
}

aside.system-preview .repeater .content .sensor-data,
aside.system-preview .basestation .content .basestation-data {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
}
aside.system-preview .repeater .content .sensor-data label {
    margin-left: 0.2rem;
}

aside.system-preview .repeater .content .rssi:before {
    background: url(/static/media/Signal3.19db259a.svg) no-repeat;
    width: 1.4rem;
    height: 1.4rem;
    background-size: 1.4rem;
    background-position: center;
    margin: 0;
}
aside.system-preview .repeater .content .temperature:before,
aside.system-preview .basestation .content .temperature:before {
    background: url(/static/media/Thermometer.3d935352.svg) no-repeat;
    width: 1.4rem;
    height: 1.4rem;
    background-size: 1.4rem;
    background-position: right;
    background-position-x: 0.3rem;
    margin: 0;
}
aside.system-preview .basestation .content .temperature,
aside.system-preview .repeater .content .temperature {
    padding-right: 0.6rem;
}

aside.system-preview .repeater .content .rssi,
aside.system-preview .repeater .content .temperature,
aside.system-preview .basestation .content .temperature {
    grid-area: sensor;
    justify-self: end;
    cursor: default;
}
aside.system-preview .repeater .content .status,
aside.system-preview .basestation .content .status {
    grid-area: status;
    margin-left: 0.2rem;
    justify-self: left;
    cursor: default;
}
aside.system-preview .repeater .content .status > span > strong,
aside.system-preview .basestation .content .status > span > strong {
    margin-right: 0.3rem;
}
aside.system-preview .repeater .content .last-packet,
aside.system-preview .basestation .content .last-packet {
    grid-area: last-packet;
    margin-left: 1.6rem;
}
aside.system-preview .repeater .content .last-packet > span,
aside.system-preview .basestation .content .last-packet > span {
    display: block;
}
aside.system-preview .repeater .content .last-packet > span > strong,
aside.system-preview .basestation .content .last-packet > span > strong {
    margin-right: 0.3rem;
}
aside.system-preview .repeater .content .daily-traffic {
    grid-area: daily-traffic;
    margin-left: 1.6rem;
}
aside.system-preview .repeater .content .daily-traffic > span {
    display: block;
}

@media (min-width: 20rem) and (max-width: 40rem) {
    aside.system-preview {
        min-height: 16rem;
        grid-template-areas:
            "wicow-server internet basestation"
            "repeaters tsens-summary eartag-summary";
        grid-template-columns: repeat(3, auto);
    }
    aside.system-preview > .repeaters {
        grid-area: repeaters;
        margin-left: 1.4rem;
    }
    aside.system-preview > .repeaters .repeater {
        min-height: 2rem;
    }
}

/* For iPads */
@media (min-width: 48rem) and (orientation: portrait),
        (min-width: 64rem) and (orientation: landscape) {
    aside.system-preview {
        grid-template-columns: 1fr 1fr 1fr 1fr auto auto;
        min-height: 15rem;
    }
    aside.system-preview > .wicow-server {
        background-size: 3rem;
    }
    
    aside.system-preview > .internet {
        background-size: 5rem;
    }
    
    aside.system-preview > .basestation {
        background-size: 3.2rem;
    }
    aside.system-preview label{
        font-size: 1.2rem;
    }
    aside.system-preview .repeater .content,
    aside.system-preview .basestation .content{
        width: 13rem !important;
        height: 12rem !important;
        font-size: 1.1rem;
    }
    aside.system-preview > .repeaters .repeater > label{ 
        font-size: 1.2rem;
        justify-self: end;
    }
    aside.system-preview > .repeaters .repeater,
    aside.system-preview > .repeaters .repeater.supervisor{
        background-size: 2rem;
        max-width: 6.8rem;
    }
    aside.system-preview > .tsens-summary,
    aside.system-preview > .eartag-summary{
        font-size: 1.1rem;
        margin-right: 1rem;
    }
    aside.system-preview > .tsens-summary span,
    aside.system-preview > .eartag-summary span{
        padding-left: 1rem;
    }

    aside.system-preview > .tsens-summary span:first-child,
    aside.system-preview > .eartag-summary span:first-child {
        background-size: 2.6rem;
        background-position: -.5rem;
    }
}
@media (min-width: 64rem) and (orientation: landscape) {
    aside.system-preview {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }
    aside.system-preview > .tsens-summary,
    aside.system-preview > .eartag-summary{
        margin-right: 0;
    }
}
section.cows {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(6rem, 1fr));
    grid-template-rows: repeat(auto-fill, minmax(6rem, 1fr));
    place-items: center;
    grid-gap: 1rem;
    padding: 1rem;
    grid-auto-flow: row dense;
}

/* For iPads */
@media (min-width: 48rem) and (orientation: portrait),
        (min-width: 64rem) and (orientation: landscape) {
    section.cows {
        grid-template-columns: repeat(auto-fill, minmax(9rem, 1fr));
        grid-template-rows: repeat(auto-fill, minmax(10rem, 1fr));
    }
}
article.system {
    width: 6.4rem;
    height: 6.4rem;

    display: grid;
    grid-template-areas: "header" "image";
    grid-template-rows: 2rem auto;

    -webkit-align-items: center;

            align-items: center;
    justify-items: center;

    border: solid white .2rem;
    border-radius: 20%;
    background-image: linear-gradient(180deg, #629F26 0%, #146C3C 100%);
    background-blend-mode: normal;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;

    margin: .5rem;

    transition-property: opacity, background-image;
    transition-duration: 1s;

    cursor: s-resize;
}
article.system.warning {
    background-image: linear-gradient(180deg, #F0960E 0%, #6C5914 100%);
}
article.system.active{
    box-shadow: 0 5rem 0 .4rem var( --dashboard-background),
                0 0 0 .4rem var( --dashboard-background),
                0 .5rem 0 .4rem var( --dashboard-background);
}
article.system > h4{ grid-area: header; }

article.system .image{
    grid-area: image;
    width: 6.4rem;
    height: 6.4rem;
    --system: url(/static/media/system.c499751e.svg);
    background-image: var(--system);
    /*For Safari flushing problem*/
    background-image: url(/static/media/system.c499751e.svg);

    background-repeat: no-repeat;
    background-blend-mode: overlay, normal;
    background-position: top;
}

article.system > h4 { font-size: 5em; }

article.system.preview:not(.active) {
    opacity: .4;
}

@media(min-width: 25rem) {
    article.system {
        width: 7rem;
        height: 7rem;
    }
}

@media(min-width: 52rem) {
    article.system {
        width: 8rem;
        height: 8rem;
        border: solid white .25rem;
    }
    article.system > h5 { font-size: 1em; }
    article.system .image {
        background-position: center .3rem;
        background-size: 3.6rem;
    }
}

/* For iPads */
@media (min-width: 48rem) and (orientation: portrait),
        (min-width: 64rem) and (orientation: landscape) {
    article.system {
        width: 9rem;
        height: 9rem;
        border: solid white .25rem;
    }
    article.system > h5 {
        font-size: 1.2em;
    }
    article.system .image {
        background-position: center .3rem;
        background-size: 4rem;
    }
}


article.pairing-list {
    
    width: 6.4rem;
    height: 6.4rem;

    border: dotted white .4rem;
    border-radius: 20%;

    background-image: var(--dead-cow);
    /*For Safari flushing problem*/
    background-image: linear-gradient(180deg, #35505A 0%, #556970 100%);

    box-sizing: content-box;
    padding: 0;
    
    opacity: .4;

    transition-property: opacity, background-image;
    transition-duration: .3s;
    
    outline: none;
    
    cursor: s-resize;
}
article.pairing-list.active{
    box-shadow: 0 5rem 0 .4rem var( --dashboard-background),
                0 0 0 .4rem var( --dashboard-background),
                0 .5rem 0 .4rem var( --dashboard-background);
    opacity: 1;
}
article.pairing-list:hover {
    opacity: 1;
}

article.pairing-list h4 {
    font-size: 2.4rem;
    line-height: 4rem;
    text-align: center;
    font-weight: 300;
    margin: 0;
    padding: 0;
}

article.pairing-list h5 {
    font-size: 1rem;
    line-height: 2rem;
    text-align: center;
    font-weight: 700;
    margin: 0;
    padding: 0;
}


@media(min-width: 25rem) {
    article.pairing-list {
        width: 7rem;
        height: 7rem;
    }
}

@media(min-width: 52rem) {
    article.pairing-list {
        width: 8rem;
        height: 8rem;
    }
    article.pairing-list h4 {
        font-size: 3rem;
    }
}
/* For iPads */
@media (min-width: 48rem) and (orientation: portrait),
        (min-width: 64rem) and (orientation: landscape) {
    article.pairing-list {
        width: 9rem;
        height: 9rem;
    }
    article.pairing-list h4 {
        font-size: 2.8rem;
    }
    article.pairing-list h5 {
        font-size: 1.2em;
    }
}

.lds-ellipsis {
    display: inline-block;
    position: relative;
    left: 2rem;
    top: 1rem;
    width: 100%;
    height: 100%;
    opacity: .5;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 30%;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
            animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    -webkit-animation: lds-ellipsis1 0.6s infinite;
            animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    -webkit-animation: lds-ellipsis2 0.6s infinite;
            animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    -webkit-animation: lds-ellipsis2 0.6s infinite;
            animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    -webkit-animation: lds-ellipsis3 0.6s infinite;
            animation: lds-ellipsis3 0.6s infinite;
  }
  @-webkit-keyframes lds-ellipsis1 {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes lds-ellipsis1 {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @-webkit-keyframes lds-ellipsis3 {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
  }
  @-webkit-keyframes lds-ellipsis2 {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
    100% {
      -webkit-transform: translate(24px, 0);
              transform: translate(24px, 0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
    100% {
      -webkit-transform: translate(24px, 0);
              transform: translate(24px, 0);
    }
  }

  /* @media (min-width: 35rem) {
    .lds-ellipsis {
        left: 1.2rem;
    }
  }

  @media (min-width: 42rem) {
    .lds-ellipsis {
        left: 2.8rem;
    }
  } */

button.new-pairing-list {
    --plus: url(/static/media/Plus.52b4bcf6.svg);
    
    width: 6.8rem;
    height: 6.8rem;

    border: none;
    border-radius: 20%;

    background-image: url(/static/media/Plus.52b4bcf6.svg);

    background-color: rgba(0, 0, 0, 0.5);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 65%, contain;

    box-sizing: content-box;
    padding: 0;
    
    opacity: .4;

    transition-property: opacity, background-image;
    transition-duration: .3s;
    
    outline: none;
}

button.new-pairing-list.disabled {
    cursor: not-allowed;
    /* opacity: .3; */
}

div.loader {
    width: 6.8rem;
    height: 6.8rem;
}

@media(min-width: 25rem) {
    button.new-pairing-list {
        width: 7.2rem;
        height: 7.2rem;
    }
}

/* For iPads */
@media (min-width: 48rem) and (orientation: portrait),
        (min-width: 64rem) and (orientation: landscape) {
    button.new-pairing-list {
        width: 9.2rem;
        height: 9.2rem;
    }
}

article.automation {
    width: 6.4rem;
    height: 6.4rem;

    display: grid;
    grid-template-areas: "header"
                         "image";
    grid-template-rows: 2rem auto;

    -webkit-align-items: center;

            align-items: center;
    justify-items: center;

    border: solid white .2rem;
    border-radius: 20%;
    background-image: linear-gradient(180deg, #629F26 0%, #146C3C 100%);
    background-blend-mode: overlay, normal;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;

    margin: .5rem;

    transition-property: opacity, background-image;
    transition-duration: 1s;

    cursor: s-resize;
}
article.automation.active{
    box-shadow: 0 5rem 0 .4rem var( --dashboard-background),
                0 0 0 .4rem var( --dashboard-background),
                0 .5rem 0 .4rem var( --dashboard-background);
}
article.automation > h5{
    grid-area: header;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
}

article.automation .image{
    grid-area: image;
    width: 6.4rem;
    height: 6.4rem;
    --automation: url(/static/media/AutomationUnchecked.668dd19e.svg);
    background-image: var(--automation);
    /*For Safari flushing problem*/
    background-image: url(/static/media/AutomationUnchecked.668dd19e.svg);

    background-repeat: no-repeat;
    background-blend-mode: overlay, normal;
    background-position: center 1rem;
    transition-property: background;
    transition-duration: .2s;
}
article.automation.active .image {
    --automation-checked: url(/static/media/CheckedAutomation.cd3be607.svg);
    background-image: var(--automation-checked);
    /*For Safari flushing problem*/
    background-image: url(/static/media/CheckedAutomation.cd3be607.svg);
}

article.automation.preview:not(.active) {
    opacity: .4;
}

@media(min-width: 25rem) {
    article.automation {
        width: 7rem;
        height: 7rem;
    }
}

@media(min-width: 52rem) {
    article.automation {
        width: 8rem;
        height: 8rem;
        border: solid white .25rem;
    }
    article.automation > h5 { font-size: 1em; }
    article.automation .image {
        background-position: center 1.5rem;
        background-size: 3.6rem;
    }
}

/* For iPads */
@media (min-width: 48rem) and (orientation: portrait),
        (min-width: 64rem) and (orientation: landscape) {
    article.automation {
        width: 9rem;
        height: 9rem;
        border: solid white .25rem;
    }
    article.automation > h5 {
        font-size: 1.3em;
        width: 8rem;
    }
    article.automation .image {
        background-position: center 1.5rem;
        background-size: 3.6rem;
        transition-property: none;
    }
}
article.climate-sens {
    width: 6.4rem;
    height: 6.4rem;

    display: grid;
    grid-template-areas: "header"
                         "graph";
    grid-template-rows: 2rem auto;

    -webkit-align-items: center;

            align-items: center;
    justify-items: center;

    border: solid white .2rem;
    border-radius: 20%;

    --climate-sens: url(/static/media/barn.c3e7b4d2.svg);
    background-image: var(--climate-sens), linear-gradient(180deg, #629F26 0%, #146C3C 100%);


    background-blend-mode: overlay, normal;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;

    margin: .5rem;
    transition-property: opacity, background-image;
    transition-duration: 1s;

    cursor: s-resize;
}
article.climate-sens > h5 {
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
}
article.climate-sens.active{
    box-shadow: 0 5rem 0 .4rem var( --dashboard-background),
                0 0 0 .4rem var( --dashboard-background),
                0 .5rem 0 .4rem var( --dashboard-background);
}

article.climate-sens.preview:not(.active) {
    opacity: .4;
}


@media(min-width: 52rem) {
    article.climate-sens {
        width: 8rem;
        height: 8rem;
        border: solid white .25rem;
    }
    article.climate-sens > h5 { font-size: 1em; }
    article.climate-sens .image {
        background-position: center 1.5rem;
        background-size: 3.6rem;
    }
}

/* For iPads */
@media (min-width: 48rem) and (orientation: portrait),
        (min-width: 64rem) and (orientation: landscape) {
    article.climate-sens {
        width: 9rem;
        height: 9rem;
        border: solid white .25rem;
    }
    article.climate-sens > h5 {
        font-size: 1.1em;
        width: 8rem;
    }
}
.switch-group{
    display: grid;
    grid-template-areas: "switch";
    grid-template-columns: 5rem;
    grid-template-rows: -webkit-min-content;
    grid-template-rows: min-content;
    padding-bottom: 1rem;
}

.switch {
    grid-area: switch;
    position: relative;
    height: 2.5rem;
    width: 4.5rem;
    background: var( --dashboard-background);
    border: 3px solid var(--dashboard-box-border);
    border-radius: 8px;
}
.switch .switch-checkbox {
    position: absolute;
    opacity: 0;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    z-index: 2;
}
.switch .onoffswitch-slider .offswitch-inner {
    position: absolute;
    margin: 2px;
    z-index: 1;
    height: 1.4rem;
    width: 1.4rem;
    border: 2px solid var(--dashboard-box-border);
    border-radius: 16px;
}
.switch .onoffswitch-slider .onswitch-inner {
    position: absolute;
    margin: 3px;
    margin-top: 4px;
    margin-left: 17px;
    z-index: 1;
    height: 1.5rem;
    width: 0;
    border: 1.5px solid var(--dashboard-box-border);
    border-radius: 20px;
}
.switch .onoffswitch-slider {
    display: block;
    width: 30px; 
    height: 30px;
    margin: 3px;
    left: 0;
    background: var( --dashboard-background);
    border: 3px solid var(--dashboard-box-border);
    position: absolute;
    top: 0;
    bottom: 0;
    border-radius: 5px;
    transition-property: background, border, left;
    transition-duration: .5s;
}
.switch-checkbox.on.turning + .onoffswitch-slider{
    left: 0;
    width: 32px; 
    height: 32px;
    background: var( --dashboard-background);
    border: 1px dashed var(--dashboard-box-border);
}
.switch-checkbox.off.turning + .onoffswitch-slider{
    left: 2rem;
    width: 32px;
    height: 32px;
    background: var(--checked-color);
    border: 1px dashed var(--dashboard-box-border);
}
.switch-checkbox.on.pending + .onoffswitch-slider{
    left: 0;
    width: 32px; 
    height: 32px;
    background: var( --dashboard-background);
    border: 1px solid var(--dashboard-box-border);
}
.switch-checkbox.off.pending + .onoffswitch-slider{
    left: 2rem;
    width: 34px; 
    height: 34px;
    background: var(--checked-color);
    border: none;
}
.switch-checkbox.on + .onoffswitch-slider{
    left: 2rem;
    background: var(--checked-color);
    width: 34px;
    height: 34px;
    border: none;
}
.switch-checkbox.on + .onoffswitch-slider .offswitch-inner,
.switch-checkbox.off + .onoffswitch-slider .onswitch-inner,
.switch-checkbox.off.turning + .onoffswitch-slider .offswitch-inner,
.switch-checkbox.off.pending + .onoffswitch-slider .offswitch-inner,
.switch-checkbox.on.turning + .onoffswitch-slider .onswitch-inner,
.switch-checkbox.on.pending + .onoffswitch-slider .onswitch-inner {
    display: none;
}
.switch-checkbox.unknown + .onoffswitch-slider{
    background: var( --dashboard-background);
    margin: 0;
    left: 0;
    border: none;
    width: 100%; 
    height: 100%;
    transition-property: background;
}
.switch-checkbox.unknown + .onoffswitch-slider .offswitch-inner{
    display: block;
    margin-left: 9px;
    margin-top: 6px;
    opacity: .5;
}
.switch-checkbox.unknown + .onoffswitch-slider .onswitch-inner{
    display: block;
    margin-left: 2.9rem;
    margin-top: 7px;
    opacity: .5;
}

.switch-checkbox.error + .onoffswitch-slider{
    background: repeating-linear-gradient(
    45deg,
    var( --dashboard-widget-background) ,
    var( --dashboard-widget-background) 10px,
    var( --dashboard-background) 10px,
    var( --dashboard-background) 23px
    );
    margin: 0;
    left: 0;
    background-repeat: no-repeat;
    border: none;
    width: 100%; 
    height: 100%;
    transition-property: background;
}
.switch-checkbox.error + .onoffswitch-slider .onswitch-inner,
.switch-checkbox.error + .onoffswitch-slider .offswitch-inner {
    display: none;
}
@media (max-width: 35rem){ 
    .switch {
        height: 3rem;
        width: 7rem;
    }
    .switch .onoffswitch-slider .offswitch-inner {
        height: 1.8rem;
        width: 1.8rem;
        border-radius: 3rem;
    }
    .switch .onoffswitch-slider .onswitch-inner {
        margin-left: 21px;
        height: 2rem;
        border-radius: 3rem;
    }
    .switch .onoffswitch-slider {
        width: 2.3rem; 
        height: 2.3rem;
    }
    .switch-checkbox.on.turning + .onoffswitch-slider{
        width: 2.7rem; 
        height: 2.6rem;
    }
    .switch-checkbox.off.turning + .onoffswitch-slider{
        left: 4rem;
        width: 2.7rem;
        height: 2.6rem;
    }
    .switch-checkbox.on.pending + .onoffswitch-slider{
        width: 2.7rem; 
        height: 2.6rem;
    }
    .switch-checkbox.off.pending + .onoffswitch-slider{
        left: 4rem;
        width: 2.7rem; 
        height: 2.6rem;
    }
    .switch-checkbox.on + .onoffswitch-slider{
        left: 4rem;
        width: 2.7rem;
        height: 2.6rem;
    }
}


@media (min-width: 48rem) and (orientation: portrait),
    (min-width: 64rem) and (orientation: landscape) {
        main.dashboard.fullscreen .switch {
            height: 3rem;
            width: 7rem;
        }
        main.dashboard.fullscreen .switch .onoffswitch-slider .offswitch-inner {
            height: 1.8rem;
            width: 1.8rem;
            border-radius: 3rem;
        }
        main.dashboard.fullscreen .switch .onoffswitch-slider .onswitch-inner {
            margin-left: 21px;
            height: 2rem;
            border-radius: 3rem;
        }
        main.dashboard.fullscreen .switch .onoffswitch-slider {
            width: 2.3rem; 
            height: 2.3rem;
        }
        main.dashboard.fullscreen .switch-checkbox.on.turning + .onoffswitch-slider{
            width: 2.7rem; 
            height: 2.6rem;
        }
        main.dashboard.fullscreen .switch-checkbox.off.turning + .onoffswitch-slider{
            left: 4rem;
            width: 2.7rem;
            height: 2.6rem;
        }
        main.dashboard.fullscreen .switch-checkbox.on.pending + .onoffswitch-slider{
            width: 2.7rem; 
            height: 2.6rem;
        }
        main.dashboard.fullscreen .switch-checkbox.off.pending + .onoffswitch-slider{
            left: 4rem;
            width: 2.7rem; 
            height: 2.6rem;
        }
        main.dashboard.fullscreen .switch-checkbox.on + .onoffswitch-slider{
            left: 4rem;
            width: 2.7rem;
            height: 2.6rem;
        }
    }
.automation-preview [data-automation-slider-container] {
  position: relative;
}

.automation-preview [data-automation-slider-overlay] {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: -webkit-transform 300ms ease-out;
  transition: transform 300ms ease-out;
  transition: transform 300ms ease-out, -webkit-transform 300ms ease-out;
  z-index: 3;
}
.automation-preview [data-automation-slider-overlay*="drag"] {
  transition: none;
  touch-action: pan-x;
  z-index: 4;
}
.automation-preview [data-automation-slider-overlay*="slide"],
.automation-preview [data-automation-slider-overlay*="slide"] *,
.automation-preview [data-automation-slider-overlay="back-rest-to-left"],
.automation-preview [data-automation-slider-overlay="back-rest-to-right"]{
  z-index: 2;
}
.automation-preview [data-automation-slider-overlay="rest-to-left"]{
  -webkit-transform: translate(100%);
          transform: translate(100%);
  z-index: 4;
}
.automation-preview [data-automation-slider-overlay="rest-to-right"]{
  -webkit-transform: translate(-100%);
          transform: translate(-100%);
  z-index: 4;
}

.automation-preview [data-automation-slider-left] {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  min-height: 100%;
  z-index: 3;
  transition: -webkit-transform 300ms ease-out;
  transition: transform 300ms ease-out;
  transition: transform 300ms ease-out, -webkit-transform 300ms ease-out;
}
.automation-preview [data-automation-slider-left*="slide"] {
  transition: none;
  touch-action: pan-x;
  z-index: 4;
}
.automation-preview [data-automation-slider-left*="drag"],
.automation-preview [data-automation-slider-left="back-left-to-rest"],
.automation-preview [data-automation-slider-left="back-right-to-rest"] {
  z-index: 2;
}
.automation-preview [data-automation-slider-left="left-to-rest"] {
  -webkit-transform: translate(100%);
          transform: translate(100%);
  z-index: 4;
}
.automation-preview [data-automation-slider-left="right-to-rest"] {
  -webkit-transform: translate(-100%);
          transform: translate(-100%);
  z-index: 4;
}
aside.automation-preview {
    background-color: var(--dashboard-background);
    padding: 0.2rem;
    display: grid;
    grid-template-areas:
        "header"
        "graph";

    grid-column-start: 1;
    grid-column-end: -1;
    position: relative;
    width: calc(100% + 2rem);
    box-sizing: border-box;
}
aside.automation-preview > header {
    grid-area: header;

    display: grid;
    grid-template-areas:
        "title fullscreen-button"
        "device-id fullscreen-button"
        "switch-group switch-group";
    grid-template-columns: 1fr 0.5fr;
    grid-template-rows: 2rem 4rem auto;
    padding: 0.1rem;
}
aside.automation-preview > header > h1 {
    grid-area: title;
    font-size: 1.2rem;
}
aside.automation-preview > header > h4.device-id {
    grid-area: device-id;
    font-weight: 300;
    justify-self: end;
}
aside.automation-preview header > span.fullscreen-button {
    grid-area: fullscreen-button;
    background-image: url(/static/media/normalScreen.c8145bff.svg);
    background-repeat: no-repeat;
    background-size: 1.6rem;
    width: 1.6rem;
    height: 1.6rem;
    justify-self: end;
    margin-top: 0.3rem;
    margin-right: 0.3rem;
}
aside.automation-preview header > span.fullscreen-button.off {
    background-image: url(/static/media/fullScreen.3fadf4dc.svg);
}
aside.automation-preview > header > .switch-group {
    grid-area: switch-group;
    height: -webkit-max-content;
    height: max-content;
    width: -webkit-max-content;
    width: max-content;
}
aside.automation-preview > div.graph {
    grid-area: graph;
}
@media (max-width: 35rem) {
    aside.automation-preview {
        min-height: 18.7rem;
        background-color: var(--dashboard-background);
        padding: 0;
        z-index: 5;
    }
    aside.automation-preview div.graph-preview {
        display: grid;
        grid-template-areas:
            "graph-header"
            "graph";

        box-sizing: border-box;
        min-height: 18.7rem;
        background-color: var(--dashboard-background);
    }
    aside.automation-preview div.graph-preview header {
        grid-area: graph-header;
        display: grid;
        grid-template-areas:
            "title fullscreen-button"
            "device-id fullscreen-button";
        grid-template-columns: 2.8rem auto;
        padding-left: 0.2rem;
        background-color: var(--dashboard-background);
    }
    aside.automation-preview div.graph-preview > header > h1 {
        grid-area: title;
        font-size: 1.2rem;
    }
    aside.automation-preview div.graph-preview > header > h4.device-id {
        grid-area: device-id;
        justify-self: end;
        font-weight: 300;
    }
    main.dashboard.fullscreen aside.automation-preview > header > h4.device-id {
        justify-self: start;
    }
    aside.automation-preview div.graph-preview > div {
        background-color: var(--dashboard-background);
        -webkit-align-self: end;
                align-self: end;
    }
    aside.automation-preview div.automation-action {
        display: grid;
        grid-template-areas:
            "options-header"
            "switch-box";
        background-color: var(--dashboard-background);
        box-sizing: border-box;
        min-height: 18.7rem;
    }
    aside.automation-preview div.automation-action header {
        grid-area: options-header;
        display: grid;
        grid-template-areas:
            "title temperature"
            "device-id temperature";
        grid-template-rows: 3rem auto;
        padding-left: 0.2rem;
        background-color: var(--dashboard-background);
    }
    aside.automation-preview div.automation-action > header > h1 {
        grid-area: title;
        font-size: 1.2rem;
    }
    aside.automation-preview div.automation-action > header > data.temperature {
        grid-area: temperature;
        font-size: 1rem;
        padding: 0.1rem;
        margin-top: 1em;
        justify-self: end;
        font-weight: 400;
    }
    aside.automation-preview div.automation-action > header > h4 {
        grid-area: temperature;
        font-size: 1rem;
        padding: 0.1rem;
        justify-self: end;
        font-weight: 300;
    }
    aside.automation-preview div.automation-action > header > h4.device-id {
        grid-area: device-id;
        justify-self: start;
    }
    aside.automation-preview div.automation-action > header > data time {
        display: grid;
        align-self: start;
        font-weight: 300;
    }
    aside.automation-preview
        div.automation-action
        > header
        > data.temperature::after {
        content: "°C";
        letter-spacing: -0.1em;
        font-weight: 300;
    }
    aside.automation-preview div.automation-action > div.switch-box {
        grid-area: switch-box;
        background-color: var(--dashboard-background);
        padding-left: 0.5rem;
    }
    main.dashboard.fullscreen aside.automation-preview > header {
        grid-template-rows: 4rem 4rem auto;
    }
    main.dashboard.fullscreen aside.automation-preview > header > h1 {
        font-size: 2rem;
    }
}
@media (min-width: 36rem) and (max-width: 52rem), (min-width: 64rem) {
    aside.automation-preview {
        grid-template-areas: "header graph";
        grid-template-columns: -webkit-min-content 1fr;
        grid-template-columns: min-content 1fr;
        grid-column-gap: 1rem;
        z-index: 1;
        font-size: 0.9em;
        padding-bottom: 0.3rem;
        height: 12rem;
    }
    aside.automation-preview > header {
        grid-template-areas:
            "title fullscreen-button"
            "device-id fullscreen-button"
            "switch-group switch-group";
        grid-template-rows: 2rem 4rem auto;
        padding: 0.1rem;
    }
    aside.automation-preview > header > h1 {
        width: 8rem;
    }
    aside.automation-preview > header > h4.device-id {
        grid-area: device-id;
        justify-self: start;
    }
    aside.automation-preview > div.graph {
        -webkit-align-self: end;
                align-self: end;
    }
    main.dashboard aside.automation-preview header > span.fullscreen-button {
        position: absolute;
    }
    main.dashboard.fullscreen
        aside.automation-preview
        header
        > span.fullscreen-button {
        position: relative;
    }
    main.dashboard.fullscreen aside.automation-preview div .data-graph {
        height: 12rem;
    }
}

/* For iPads */
@media (min-width: 48rem) and (orientation: portrait),
    (min-width: 64rem) and (orientation: landscape) {
    aside.automation-preview {
        min-height: 15rem;
    }
    aside.automation-preview > header > h1 {
        font-size: 1.2rem;
    }
    aside.automation-preview > header > h4.device-id {
        font-size: 1rem;
    }
    aside.automation-preview header > span.fullscreen-button {
        background-size: 2.1rem;
        width: 2.1rem;
        height: 2.1rem;
        margin-right: 0;
    }
    aside.automation-preview > div.graph {
        margin-bottom: 0.3rem;
        background-color: var(--dashboard-background);
        -webkit-align-self: end;
                align-self: end;
    }
    main.dashboard.fullscreen aside.automation-preview > header {
        grid-template-rows: 6rem auto;
    }
    main.dashboard.fullscreen aside.automation-preview > header > h1 {
        font-size: 2rem;
    }
    main.dashboard.fullscreen aside.automation-preview div .data-graph {
        font-size: 1.1rem;
    }
    main.dashboard.fullscreen aside.automation-preview div .data-graph {
        height: 20rem;
    }
    aside.automation-preview div .data-graph data.temperature {
        font-size: 3.3em;
        text-align: end;
    }
    aside.automation-preview div .data-graph data.temperature time {
        font-size: 1rem;
    }
    main.dashboard.fullscreen
        aside.automation-preview
        div
        .data-graph
        data.temperature {
        font-size: 4em;
    }
    main.dashboard.fullscreen
        aside.automation-preview
        div
        .data-graph
        data.temperature
        time {
        font-size: 1.5rem;
    }
}

/* Full Screen */
main.dashboard.fullscreen aside.automation-preview {
    grid-template-areas:
        "header"
        "graph";
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    margin-left: 0;
    width: 100vw;
    height: 100vh;
}

@media screen and (orientation: portrait) {
    main.dashboard.fullscreen aside.automation-preview {
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
        -webkit-transform-origin: left top;
                transform-origin: left top;
        height: 100vw;
        width: 100vh;
        overflow-x: hidden;
        position: absolute;
        top: 0;
        left: 100vw;
    }
}

aside.climate-sens-preview {
    background-color: var(--dashboard-background);
    display: grid;
    grid-template-areas:
        "header"
        "graph";

    grid-column-start: 1;
    grid-column-end: -1;
    position: relative;
    width: calc(100% + 2rem);
    box-sizing: border-box;
}
aside.climate-sens-preview header {
    display: grid;
    grid-template-areas:
        "place-name fullscreen-button"
        "device-id fullscreen-button";
    grid-template-columns: auto 2.4rem;
    padding: 0 0.5rem 0 0.5rem;
}
aside.climate-sens-preview header > h3 {
    grid-area: place-name;
    max-width: 11rem;
    overflow: hidden;
    text-overflow: ellipsis;
}
aside.climate-sens-preview header > h4 {
    grid-area: device-id;
}
aside.climate-sens-preview header > span.fullscreen-button {
    grid-area: fullscreen-button;
    background-image: url(/static/media/normalScreen.c8145bff.svg);
    background-repeat: no-repeat;
    background-size: 1.6rem;
    width: 1.6rem;
    height: 1.6rem;
    justify-self: end;
    margin-top: 0.3rem;
    margin-right: 0.3rem;
}
aside.climate-sens-preview header > span.fullscreen-button.off {
    background-image: url(/static/media/fullScreen.3fadf4dc.svg);
}
aside.climate-sens-preview div.data-graph {
    -webkit-align-self: end;
            align-self: end;
}
aside.climate-sens-preview header > h4 {
    grid-area: device-id;
}
@media (max-width: 35rem) {
    aside.climate-sens-preview {
        min-height: 18.7rem;
        background-color: var(--dashboard-background);
    }
}

@media (min-width: 36rem) and (max-width: 52rem), (min-width: 64rem) {
    aside.climate-sens-preview {
        grid-template-areas: "header graph";
        grid-template-columns: -webkit-min-content 1fr;
        grid-template-columns: min-content 1fr;
        grid-column-gap: 1rem;
        z-index: 1;
        font-size: 0.9em;
        padding-bottom: 0.3rem;
        min-height: 15rem;
    }
    aside.climate-sens-preview > header {
        width: 8rem;
    }
    main.dashboard aside.climate-sens-preview header > span.fullscreen-button {
        position: absolute;
    }
    main.dashboard.fullscreen
        aside.climate-sens-preview
        header
        > span.fullscreen-button {
        position: relative;
    }
}
@media (min-width: 48rem) and (orientation: portrait),
    (min-width: 64rem) and (orientation: landscape) {
    aside.climate-sens-preview header > span.fullscreen-button {
        background-size: 2.1rem;
        width: 2.1rem;
        height: 2.1rem;
        margin-right: 0;
    }
    main.dashboard.fullscreen aside.climate-sens-preview header > h3 {
        font-size: 3rem;
        max-width: -webkit-max-content;
        max-width: max-content;
    }
    main.dashboard.fullscreen aside.climate-sens-preview header > h4 {
        font-size: 2rem;
    }
    main.dashboard.fullscreen aside.climate-sens-preview .data-graph {
        height: 20rem;
    }
    aside.climate-sens-preview .data-graph data.temperature {
        font-size: 3.3em;
        text-align: end;
    }
    aside.climate-sens-preview .data-graph data.temperature time {
        font-size: 1rem;
    }
    main.dashboard.fullscreen
        aside.climate-sens-preview
        .data-graph
        data.temperature {
        font-size: 4em;
    }
    main.dashboard.fullscreen
        aside.climate-sens-preview
        .data-graph
        data.temperature
        time {
        font-size: 1.5rem;
    }
}
/* Full Screen */
main.dashboard.fullscreen aside.climate-sens-preview {
    grid-template-areas:
        "header"
        "graph";
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    margin-left: 0;
    width: 100vw;
    height: 100vh;
}
main.dashboard.fullscreen aside.climate-sens-preview > header {
    width: auto;
}

@media screen and (orientation: portrait) {
    main.dashboard.fullscreen aside.climate-sens-preview {
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
        -webkit-transform-origin: left top;
                transform-origin: left top;
        height: 100vw;
        width: 100vh;
        overflow-x: hidden;
        position: absolute;
        top: 0;
        left: 100vw;
    }
}

article.cow {
    --cowhead: url(/static/media/cow.a9ef812b.svg);

    width: 6.4rem;
    height: 6.4rem;

    display: grid;
    grid-template-areas: "name" "number" "badge";
    grid-template-rows: 1fr 3fr 2.3rem;
    -webkit-align-items: center;
            align-items: center;
    justify-items: center;

    border: solid white 0.2rem;
    border-radius: 20%;

    background-image: var(--cowhead), var(--good-cow);
    /*For Safari flushing problem*/
    background-image: url(/static/media/cow.a9ef812b.svg),
        linear-gradient(180deg, #629f26 0%, #146c3c 100%);
    background-blend-mode: overlay, normal;
    will-change: opacity;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;

    margin: 0.5rem;

    transition-property: opacity, background-image;
    transition-duration: 1s;

    cursor: s-resize;
}

article.cow.preview:not(.active) {
    opacity: 0.4;
}
article.cow.active {
    box-shadow: 0 5rem 0 0.4rem var(--dashboard-background),
        0 0 0 0.4rem var(--dashboard-background),
        0 0.5rem 0 0.4rem var(--dashboard-background);
}

article.cow > * {
    margin: 0;
    padding: 0;
}
article.cow > div.badge {
    --size: 2rem;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    width: 100%;
    padding: calc(var(--size) / 10);
    padding-top: 0;
    padding-bottom: calc(var(--size) / 10);
    box-sizing: border-box;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

article.cow > h4 {
    grid-area: number;
    font-size: 2.2em;
    font-weight: 900;
    overflow-x: hidden;
    text-overflow: ellipsis;
    direction: rtl;
    max-width: 100%;
}

.digits-1 article.cow > h4 {
    font-size: 4em;
}
.digits-2 article.cow > h4 {
    font-size: 4em;
}
.digits-3 article.cow > h4 {
    font-size: 2.4em;
}
.digits-4 article.cow > h4 {
    font-size: 2.4em;
}
.digits-5 article.cow > h4 {
    font-size: 2.2em;
    font-weight: 700;
}
.digits-6 article.cow > h4 {
    font-size: 2.2em;
    font-weight: 700;
}

article.cow > h5 {
    grid-area: name;
    font-weight: 500;
    align-self: end;
    font-size: 1em;
}

article.cow.calving {
    background-image: var(--cowhead), var(--calving-cow);
    background-image: url(/static/media/cow.a9ef812b.svg),
        linear-gradient(180deg, #2d9cdb 0%, #134561 100%);
}

article.cow.delivery,
article.cow.labor,
article.cow.fever,
article.cow.hypothermia,
article.cow.sensor-fallen,
article.cow.heat {
    background-image: var(--cowhead), var(--bad-cow);
    /*For Safari flushing problem*/
    background-image: url(/static/media/cow.a9ef812b.svg),
        linear-gradient(180deg, #f3492c 0%, #bc4040 100%);
}

article.cow.heat {
    background-image: var(--cowhead), var(--heat-cow);
    /*For Safari flushing problem*/
    background-image: url(/static/media/cow.a9ef812b.svg),
        linear-gradient(180deg, #f59619 0%, #f55f19 100%);
}

article.cow.potential {
    background-image: var(--cowhead), var(--potential-cow);
    /*For Safari flushing problem*/
    background-image: url(/static/media/cow.a9ef812b.svg),
        linear-gradient(180deg, #3fd1ff 0%, #2d9cdb 100%);
}

article.cow.untracked,
article.cow.dead,
article.cow.pending,
article.cow.tsens-problem {
    background-image: var(--cowhead), var(--dead-cow);
    /*For Safari flushing problem*/
    background-image: url(/static/media/cow.a9ef812b.svg),
        linear-gradient(180deg, #35505a 0%, #556970 100%);
}

article.cow.due {
    background-image: var(--cowhead), var(--due-cow);
    background-image: url(/static/media/cow.a9ef812b.svg),
        linear-gradient(180deg, #83d2ff 0%, #2d9cdb 100%);
}

article.cow.overdue {
    background-image: var(--cowhead), var(--bad-cow-handled);
    background-image: url(/static/media/cow.a9ef812b.svg),
        linear-gradient(180deg, #ac2e10 0%, #551717 100%);
}

article.cow.untracked,
article.cow.dead {
    opacity: 0.5;
}

@media (min-width: 25rem) {
    article.cow {
        width: 7rem;
        height: 7rem;
    }
}

/* For iPads */
@media (min-width: 48rem) and (orientation: portrait),
        (min-width: 64rem) and (orientation: landscape) {
    article.cow {
        width: 9rem;
        height: 9rem;
        border: solid white .25rem;
    }
    article.cow > h5 {
        font-size: 1.5em;
    }
    .digits-4 article.cow > h4,
    .digits-5 article.cow > h4,
    .digits-6 article.cow > h4 {
        font-size: 3em;
    }

    article.cow div.badge {
        --size: 2.6rem;
        padding: calc(var(--size)/3);
        padding-top: 0;
        padding-right: calc(var(--size)/16);
        padding-left: calc(var(--size)/16);
    }
}
div.badge-item {
    --gradient: var(--dead-cow);
    background-image: var(--icon), var(--gradient);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 120%;
    height: var(--size);
    width: var(--size);
    border-radius: 50%;
    border: solid calc(var(--size) / 2 * 0.15) var(--dashboard-text-color);
    box-sizing: border-box;
}

div.badge-item.Tsens {
    --icon: url(/static/media/Tsens.faf7b00a.svg);
}

div.badge-item.earTag {
    --icon: url(/static/media/EarTag.a4d09280.svg);
}

div.badge-item.calf {
    --icon: url(/static/media/Calf.c028e539.svg);
}

div.badge-item.temperature {
    --icon: url(/static/media/Thermometer.3d935352.svg);
    --gradient: var(--good-cow);
}

div.badge-item.estrous {
    --icon: url(/static/media/estrous.e3fa64f4.svg);
    --gradient: var(--good-cow);
    background-size: 130%;
}

div.Tsens.idle,
div.Tsens.assigned,
div.Tsens.pending,
div.Tsens.network-problem {
    --gradient: var(--idle-sensor);
}
div.Tsens.active,
div.Tsens.stable {
    --gradient: var(--stable-sensor);
}
div.Tsens.unstable,
div.Tsens.low-battery {
    --gradient: var(--unstable-sensor);
}
div.Tsens.fault {
    --gradient: var(--bad-sensor);
}

div.earTag.idle,
div.earTag.assigned,
div.earTag.pending,
div.earTag.network-problem {
    --gradient: var(--idle-sensor);
}
div.earTag.active,
div.earTag.stable {
    --gradient: var(--stable-sensor);
}
div.earTag.unstable,
div.earTag.low-battery {
    --gradient: var(--unstable-sensor);
}
div.earTag.fault {
    --gradient: var(--bad-sensor);
}

div.calf.pregnant {
    --gradient: var(--good-cow);
}
div.calf.due {
    --gradient: var(--due-cow);
}

div.calf.overdue {
    --gradient: var(--bad-cow-handled);
}
div.calf.calving {
    --gradient: var(--calving-cow);
}

div.temperature.fever,
div.temperature.hypothermia {
    --gradient: var(--bad-cow);
}

div.estrous.heat {
    --gradient: var(--heat-cow);
}

div.estrous.potential {
    --gradient: var(--potential-cow);
}

header.navbar {
    position: fixed;
    z-index: 99;
    top: 0;
    height: 2.4rem;
    width: 100%;
    background-color: var(--dashboard-background);
    display: grid;
    grid-template-areas: "shortcut-button farm nav";
    grid-template-columns: 3rem auto 3rem;
}
header.navbar * {
    color: inherit;
    font-size: .8rem;
    font-weight: 300;
    text-decoration: none;
    line-height: .8rem;
    margin: 0;
    padding: 0;
    list-style: none;
}
header.navbar > h1 {
    grid-area: farm;
    font-weight: 600;
    padding-top: .8rem;
    margin-left: .8rem;
    width: -webkit-max-content;
    width: max-content;
    justify-self: center;
}
header.navbar > h1 .trademark {
    opacity: .4;
}

header.navbar > button:hover {
    box-shadow: var(--dashboard-text-color) 0 0 0 .125rem;
}

header.navbar i.shortcut-button{
    grid-area: shortcut-button;
    z-index: 99;
    opacity: 1;
    cursor: pointer;
}
header.navbar i.cow-list{
    background: url(/static/media/CowPictogram.fafd4185.svg) no-repeat;
    background-size: 2.2rem;
    background-position: center;
}
header.navbar i.switch-farm{
    background: url(/static/media/SwitchFarm.85891dd6.svg) no-repeat;
    background-size: 2.2rem;
    background-position: center;
}
header.navbar i.notifications{
    background: url(/static/media/Warning.82cf904a.svg) no-repeat;
    background-size: 2.3rem;
    background-position: bottom;
}
header.navbar i.burger{
    grid-area: nav;
    z-index: 99;
    width: 2.4rem;
    height: 2.4rem;
    background: url(/static/media/burgerNav.a2797749.svg) no-repeat;
    background-position: left;
    justify-self: end;
    cursor: pointer;
}
nav.main-menu {
    font-size: 1rem;
    font-weight: 600;
    line-height: .8rem;
    overflow-y: auto;
    border-radius: 1rem 0 0 1rem;
    box-shadow: 0 0 0 150vmax rgba(0,0,0,.5);
}

nav ul {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    list-style-type: none;
    float: right;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100vh;
}

nav.main-menu ul li {
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    background-image: url(/static/media/rightArrow.ec01246a.svg);
    background-size: .5rem;
    background-repeat: no-repeat;
    background-position: 98%;
    -webkit-justify-content: center;
            justify-content: center;
    border-bottom: solid 1px black;
    padding: .8rem;
    width: 100%;
    background-color: var(--dashboard-widget-background);
}
nav.main-menu ul li.farm-name-close {
    display: grid;
    background-image: none;
    background-repeat: no-repeat;
    grid-template-areas: "farm-name-item close-button";
    grid-template-columns: auto 1.9rem;
    height: auto;
    cursor: auto;
    -webkit-justify-content: unset;
            justify-content: unset;
}

nav.main-menu ul li.farm-name-close .farm-name{
    grid-area: farm-name-item;
    background: none;
    font-size: 1.5rem;
    font-weight: 300;
    height: 1rem;
    align-self: center;

}
nav.main-menu ul li span {
    padding-top: .5rem;
    font-size: .9rem;
    font-weight: 300;
    line-height: 1rem;
    width: 90%;
}
nav.main-menu ul li.farm-name-close .close-button{
    grid-area: close-button;
    --close: url(/static/media/CloseButton.be369177.svg);
    background-image: var(--close);
    background-position: top;
    background-size: 2.8rem;
    background-repeat: no-repeat;
    cursor: pointer;
    height: 2.8rem;
    width: 2.8rem;
    padding-top: 0;
    justify-self: end;
    align-self: center;
}
nav.main-menu ul li.log-out-confirm{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    background-image: none;
    -webkit-justify-content: none;
            justify-content: none;
}
nav.main-menu ul li.log-out-confirm button{
    visibility: hidden;
    background: none;
    background-color: var(--dashboard-background);
    -webkit-align-self: center;
            align-self: center;
    width: 40%;
    height: 150%;
    padding: 0;
    margin: 0;
}
nav.main-menu ul li.log-out-confirm h1{
    margin: auto;
    background: none;
    width: 60%;

}
nav.main-menu ul li.log-out-confirm button.logout.visible {
    visibility: visible;
}

nav ul button {
    width: 90%;
    margin: 0 .2rem 0 .2rem;
}
nav ul li h1 {
    font-size: 1rem;
    font-weight: 500;
    color: var(--dashboard-text-color);
    margin: 0;
}
nav::-webkit-scrollbar {
    display: none;
}

nav.main-menu ul li.farm-metrics.disabled *{
    opacity: .5;
}
@media (max-width: 35rem) {
    header.navbar {
        grid-area: header;
        position: fixed;
        z-index: 100;
        grid-template-areas: "shortcut-button farm nav";
        grid-template-columns: 3rem auto 3rem;
        max-width: 100vw;
    }
    nav.main-menu {
        height: 100vh;
        border-radius: 0;
    }
    nav.main-menu ul {
        height: 100%;
    }
    nav.main-menu ul li {
        height: auto;
    }
    nav.main-menu ul li h1 {
        font-size: 1.2rem;
        font-weight: 500;
        color: var(--dashboard-text-color);
        margin: 0;
    }
    nav.main-menu ul li:hover {
        background-color: var(--dashboard-widget-background);
    }
}
nav ul li.help-and-support {
    height: auto;
}

/* For iPads */
@media (min-width: 48rem) and (orientation: portrait),
        (min-width: 64rem) and (orientation: landscape) {
    header.navbar {
        grid-template-areas: "shortcut-button farm nav";
        grid-template-columns: 4rem auto 4.5rem;
        padding: .6rem .9rem 0;
        height: 3rem;
    }
    header.navbar > h1 {
        font-size: 1.5rem;
        font-weight: 400;
        margin-left: 1rem;
    }
    header.navbar > h1 .trademark {
        font-size: 1.3rem;
    }
    header.navbar i.burger{
        margin: 0;
        -webkit-align-self: center;
                align-self: center;
        justify-self: center;
        background-size: 1.6rem;
}
    header.navbar i.shortcut-button{
        width: 2.6rem;
        height: 2.6rem;
        background-size: 2.6rem;
    }
    header.navbar i.notifications{
        background-size: 2.7rem;
        background-position: center;
    }
    nav.main-menu {
        width: 100%;
        border-radius: 0;
    }
    nav.main-menu ul {
        width: 100%;
        height: 100vh;
    }
    nav.main-menu ul li {
        height: auto;
        padding-top: 1rem;
    }
    nav.main-menu ul li.farm-name-close .farm-name{
        font-size: 1.8rem;
    }
    nav.main-menu ul li.farm-name-close .close-button{
        background-size: 3rem;
        height: 3rem;
        width: 3rem;
    }
    nav.main-menu ul li h1 {
        font-size: 1.5rem;
    }
    nav.main-menu ul li span {
        font-size: 1.2rem;
    }
    nav.main-menu ul li.log-out-confirm button{
        width: 40%;
        height: 120%;
        font-size: 1.3rem;
    }
}
@media (orientation: landscape) {
    nav.main-menu ul li:hover {
        background-color: var(--dashboard-widget-background);
    }
}
main.dashboard {
    display: block;
}

main.dashboard > section {
    margin: 1.8rem 0;
    background: var(--dashboard-widget-background);
}

main.dashboard > section.cows {
    grid-area: cows;
}

main.dashboard > section.events {
    grid-area: events;
    background: var(--dashboard-background);
}

main.dashboard > section.note-form {
    grid-area: note-form;
    z-index: 3;
}
main.dashboard.fullscreen {
    position: fixed;
    z-index: 100;
}
@media(max-width: 52rem) {
    /*For Safari slipping problem*/
    main.dashboard {
        overflow: hidden;
    }
    main.navigating {
        position: fixed;
    }
}
/* For iPads */
@media (min-width: 48rem) and (orientation: portrait),
        (min-width: 64rem) and (orientation: landscape) {
    main.dashboard {
        overflow: hidden;
    }
    main.navigating {
        position: fixed;
    }
    main.dashboard > section.cows {
        margin-top: 2.8rem;
    }  
}


::-webkit-scrollbar {
    background: var(--dashboard-widget-background);
}

::-webkit-scrollbar-track {
    background: var(--dashboard-background);
}

::-webkit-scrollbar-thumb {
    background: var(--dashboard-background);
    opacity: .5;
    border: solid 1px #777;
    border-radius: .5rem;
}

::-webkit-scrollbar-corner {
    background-color: var(--dashboard-background);
}

form.login {
    background: var(--dashboard-widget-background);
    padding: 0.5rem;
    display: grid;
    grid-template-areas: "field button";
    grid-template-columns: 1fr 2rem;
    grid-template-rows: 2rem;
    grid-gap: 0.5rem;
}
div.login {
    background: var(--dashboard-widget-background);
    padding: 0.5rem;
    padding-top: 0;
    display: grid;
    grid-template-areas:
        "try-another-email resend-email"
        "open-email open-email";
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 3rem auto;
    grid-gap: 0;
    grid-column-gap: 0;
}
div.login > button.try-another-email {
    background-color: var(--dashboard-background);
    grid-area: try-another-email;
    cursor: pointer;
    align-self: center;
    min-height: 2rem;
    height: auto;
}
div.login > button.resend-email {
    background-color: var(--dashboard-background);
    grid-area: resend-email;
    cursor: pointer;
    align-self: center;
    min-height: 2rem;
    height: auto;
}
div.login > button.open-email {
    background-color: var(--dashboard-background);
    grid-area: open-mail;
    grid-column: 1 / span 2;
    cursor: pointer;
    align-self: center;
    min-height: 2rem;
    height: auto;
}
.login-form-box.pending.retry > div.login > button.resend-email {
    border-radius: 75%;
    background-size: 0;
    border-style: dotted;
    background-position: center;
    border-color: var(--button-action-color);
    border-width: 4.5px;
    width: 2rem;
    height: 2rem;
    justify-self: center;
    -webkit-animation: rotation linear 2s infinite;
            animation: rotation linear 2s infinite;
}
form.login input[type="email"] {
    background: var(--dashboard-background);
    color: inherit;
    border: solid 1px gray;
    border-radius: 0.2rem;
    padding: 0 0.5rem;
    grid-area: field-start / field-start / button-end / button-end;
    outline: none;
    transition: border-color 1s;
    font-size: 0.95rem;
}

form.login input[type="email"]:focus,
.login-form-box.failed > form.login input[type="email"]:focus {
    border-color: white;
}
.login-form-box.failed > form.login input[type="email"] {
    box-shadow: 0 0 0 3px #d30;
    border-color: #d30;
}

form.login input[type="submit"] {
    grid-area: button;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border: solid 1px var(--dashboard-background);
    border-radius: 0.1rem;
    background-color: transparent;
    background-image: url(/static/media/Submit.8dbc3f5e.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1.6rem;
    color: transparent;
    transition-property: all;
    transition-duration: 0.5s;
    align-self: center;
    justify-self: center;
    outline: none;
    opacity: 0.3;
}

form.login:valid input[type="submit"] {
    opacity: 0.7;
}
form.login:valid input[type="submit"]:hover {
    opacity: 1;
}

.login-form-box.pending > form.login input[type="submit"] {
    border-radius: 50%;
    background-size: 0;
    border-style: dotted;
    border-color: var(--button-action-color);
    border-width: 4.5px;
    opacity: 1;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    -webkit-animation: rotation linear 2s infinite;
            animation: rotation linear 2s infinite;
}

.login-form-box.pending > form.login input[type="email"] {
    transition: all 0.5s ease-in;
    padding-left: 90%;
    overflow-x: hidden;
    color: transparent;
}

/* GOOGLE LOGIN*/
.google-login {
    padding: 0 0.5rem 0 0.5rem;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

.google-login > button.google {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    border-radius: 0.4rem;
    line-height: 2rem;
    padding: 0.5rem;
    margin: 0.5rem;
    background-color: var(--dashboard-background);
    border: 3px solid var(--dashboard-widget-background);
    cursor: pointer;
}

.google-login > button.google.disabled {
    opacity: 0.5;
}

.google-login > button.google > span.icon {
    display: inline-block;
    content: "";
    width: 2rem;
    height: 2rem;
    background-image: url(/static/media/Google.fd168f70.svg);
    background-repeat: no-repeat;
    background-size: 1.6rem;
    background-position: left;
    vertical-align: middle;
}
.google-login > button.google > span.text {
    margin-left: 2rem;
    font-size: 1.2em;
    color: var(--dashboard-text-color);
}

.grecaptcha-badge {
    visibility: hidden;
}

@-webkit-keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

/* For iPads */
@media (min-width: 48rem) and (orientation: portrait),
    (min-width: 64rem) and (orientation: landscape) {
    form.login {
        grid-template-columns: 1fr 4rem;
        grid-template-rows: 3rem;
    }
    form.login input[type="email"],
    div.login > button.try-another-email,
    div.login > button.resend-email {
        font-size: 1.4rem;
    }
    form.login input[type="submit"] {
        background-size: 2.5rem;
    }
    .login-form-box.pending > form.login input[type="submit"] {
        border-radius: 90%;
        width: calc(50% - 6px);
        height: calc(80% - 6px);
    }
    .login-form-box.pending.retry > div.login > button.resend-email {
        border-radius: 90%;
        width: calc(2% - 15px);
        height: calc(85% - 6px);
    }
}

aside.modal-login {
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-areas:
        "wicow-logo"
        "login-form-box"
        "version-news";
    grid-template-rows: 9rem -webkit-max-content auto;
    grid-template-rows: 9rem max-content auto;
    background-color: var(--dashboard-background);
    z-index: 5;
    overflow: auto;
    width: 100%;
}
aside.modal-login::after {
    position: fixed;
    background-color: var(--dashboard-background);
    opacity: 0.8;
    width: 100vw;
    height: 100%;
    content: "\00a0";
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
}
aside.modal-login > span {
    grid-area: wicow-logo;
    --wicow-logo: url(/static/media/wiCowLogo.e231f03b.svg);
    background-image: var(--wicow-logo);
    background-repeat: no-repeat;
    background-position: center;
}
aside.modal-login > .login-form-box {
    grid-area: login-form-box;
    background-color: var(--dashboard-widget-background);
    display: grid;
    height: -webkit-max-content;
    height: max-content;
    width: 100%;
}
aside.modal-login > .login-form-box > p {
    width: 95%;
    font-weight: 500;
    font-size: 0.95rem;
    padding-left: 0.8rem;
}
aside.modal-login > .login-form-box > p > span {
    font-weight: 700;
}
aside.modal-login > .login-form-box.pending > p {
    opacity: 0.5;
}
aside.modal-login > .login-form-box.retry > p {
    margin-top: 0.5rem;
}
aside.modal-login > .login-form-box > p > a.mail {
    font-weight: 700;
    color: var(--dashboard-text-color);
}
aside.modal-login .version-news {
    grid-area: version-news;
    display: grid;
    grid-template-areas:
        "news-header"
        "news-list";
    grid-template-rows: -webkit-min-content auto;
    grid-template-rows: min-content auto;
    -webkit-align-content: center;
            align-content: center;
}
aside.modal-login .version-news .news-header {
    grid-area: news-header;
    padding-left: 2rem;
    font-weight: 300;
}
aside.modal-login .version-news .news-list {
    grid-area: news-list;
    font-weight: 500;
    font-size: 1.1rem;
    margin-right: 1rem;
}
aside.modal-login .version-news .news-list li {
    margin-bottom: 0.5rem;
}

aside.modal-login::-webkit-scrollbar {
    display: none;
}

aside.modal-login .version-news .news-list .demo-link {
    color: var(--button-action-color);
}

aside.modal-login .version-news .news-list .demo-link:hover {
    cursor: pointer;
    text-decoration: underline;
}
@media (max-width: 35rem), (orientation: landscape) {
    aside.modal-login {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }
    aside.modal-login > .wicow-tip {
        display: none;
    }
}

/* For iPads */
@media (min-width: 48rem) and (orientation: portrait),
    (min-width: 64rem) and (orientation: landscape) {
    aside.modal-login {
        position: relative;
        width: 100vw;
        height: 100%;
        overflow-x: hidden;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        justify-self: center;
        -webkit-align-self: center;
                align-self: center;
    }
    aside.modal-login > span {
        background-size: 13rem;
    }
    aside.modal-login > .login-form-box > p {
        font-size: 1.4rem;
    }
    aside.modal-login > .wicow-tip {
        display: none;
    }
    aside.modal-login > .wicow-feature {
        display: none;
    }
    aside.modal-login > .version-news {
        grid-row-gap: 2rem;
        font-size: 1.6rem;
    }
    aside.modal-login .version-news .news-list {
        font-size: 1.5rem;
    }
}
@media (min-width: 64rem) and (orientation: landscape) {
    aside.modal-login {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        justify-self: center;
        grid-template-areas:
            "wicow-logo wicow-tip"
            "login-form-box wicow-tip"
            "version-news wicow-tip";
        grid-template-columns: 24rem auto;
        grid-template-rows: 9rem -webkit-max-content 31.5rem;
        grid-template-rows: 9rem max-content 31.5rem;
        grid-column-gap: 1.5rem;
        grid-row-gap: 1.5rem;
        margin-top: 1.5rem;
        -webkit-justify-content: center;
                justify-content: center;
        overflow: auto;
    }
    aside.modal-login::after {
        position: fixed;
        background-color: var(--dashboard-background);
        opacity: 0.8;
        width: 100%;
        height: 100%;
        content: "\00a0";
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: -1;
    }
    aside.modal-login > .version-news {
        background-color: var(--dashboard-widget-background);
        -webkit-align-content: start;
                align-content: start;
        grid-template-columns: auto;
        grid-template-rows: -webkit-min-content auto;
        grid-template-rows: min-content auto;
        grid-row-gap: 0;
        font-size: 1.5rem;
    }
    aside.modal-login .version-news .news-header {
        margin-bottom: 0;
    }
    aside.modal-login .version-news .news-list {
        font-size: 1.4rem;
    }
    aside.modal-login > .wicow-tip {
        grid-area: wicow-tip;
        display: grid;
        grid-template-areas:
            "wicow-tip-image"
            "wicow-tip-header"
            "wicow-tip-explanation";
        grid-template-rows: auto auto auto;
        background-color: var(--dashboard-widget-background);
        -webkit-align-content: center;
                align-content: center;
        -webkit-justify-content: center;
                justify-content: center;
        height: 100%;
    }
    aside.modal-login > .wicow-tip > .wicow-tip-image {
        grid-area: wicow-tip-image;
        -webkit-margin-before: 0;
                margin-block-start: 0;
        -webkit-margin-after: 0;
                margin-block-end: 0;
        --tip3-image: url(/static/media/wiCowTip3Image.ca1b5323.svg);
        background: var(--tip3-image);
        background-repeat: no-repeat;
        background-position: top;
        background-size: 28rem;
        height: 15rem;
        width: 25rem;
        padding-left: 2rem;
        padding-right: 2rem;
        margin-bottom: 3rem;
        justify-self: center;
    }
    aside.modal-login > .wicow-tip > .wicow-tip-header {
        grid-area: wicow-tip-header;
        -webkit-margin-before: 0;
                margin-block-start: 0;
        -webkit-margin-after: 0;
                margin-block-end: 0;
        font-size: 1.6rem;
        font-weight: 300;
        padding-left: 4rem;
        justify-self: start;
        align-self: end;
    }
    aside.modal-login > .wicow-tip > .wicow-tip-explanation {
        grid-area: wicow-tip-explanation;
        -webkit-margin-before: 0;
                margin-block-start: 0;
        -webkit-margin-after: 0;
                margin-block-end: 0;
        margin-top: 0.5rem;
        font-weight: 400;
        font-size: 1.2rem;
        width: 23rem;
        justify-self: center;
    }
    aside.modal-login > div.wicow-feature > p.wicow-feature-explanation > a {
        color: var(--button-action-color);
        font-weight: 600;
        cursor: pointer;
        text-decoration: none;
    }
}

.demo-modal {
    margin: auto;
    background-color: var(--dashboard-widget-background);
    padding: 0.5rem 1rem;
    border: solid 0.3rem white;
    border-top: none;
    position: fixed;
    z-index: 1;
    width: 100%;
    box-sizing: border-box;
    top: 0;
    min-height: 10rem;
    z-index: 999;
    display: block;
}

.demo-modal-disabled {
    display: none;
}

@media (min-width: 24rem) {
    aside.modal-login .demo-modal {
        width: 24rem;
        left: 50vw;
        right: 50vw;
        margin-left: -12rem;
        margin-right: -12rem;
        border-bottom-left-radius: 1.2rem;
        border-bottom-right-radius: 1.2rem;
    }
}

.demo-modal::after {
    position: fixed;
    background-color: var(--dashboard-background);
    opacity: 0.8;
    width: 100vw;
    height: 100%;
    content: "\00a0";
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
}

.demo-modal .demo-close {
    color: #aaa;
    float: right;
    font-size: 2rem;
    font-weight: 700;
}

.demo-modal .demo-close:hover,
.demo-close:focus {
    color: var(--button-action-color);
    cursor: pointer;
}

.demo-modal input {
    background-color: var(--dashboard-background);
    color: inherit;
    border: 1px solid gray;
    padding: 0.5rem;
    font-size: 0.95rem;
    border-radius: 0.2rem;
    width: calc(100% - 1rem);
    outline: none;
    margin-bottom: 0.5rem;
    transition: border-color 0.5s;
}

.demo-modal input:focus {
    border-color: white;
}

.demo-modal input:valid {
    border-color: #629f26;
}

.demo-modal button.demo-farm {
    background-color: var(--button-action-color);
    cursor: pointer;
    min-height: 2rem;
    height: auto;
    margin: 0.5rem 0;
    float: right;
}

.demo-modal button.demo-farm:focus {
    opacity: 0.6;
}

aside.pairing {
    display: grid;

    grid-template-areas: "header" "body" "footer";
    grid-template-rows: 3rem 1fr 3rem;
    padding: 1rem;
    background-color: var(--dashboard-widget-background);
    grid-gap: .5rem;
    gap: .5rem;
    min-height: calc(100vh - 5rem);
    max-height: 100%;
    width: 100vw;
    max-width: 40rem;
    box-sizing: border-box;
    margin: 0 auto;
    border-radius: 0 0 1rem 1rem;
    min-width: 42rem;


    z-index: 500;
    top: 0;
    left: 0;
    right: 0;
    box-shadow: 0 0 0 50vmax rgba(0,0,0,.5), 0 0 2rem black;

}
aside.pairing .pairing-boxes{
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    display: grid;
    grid-gap: .2rem;
    grid-template-rows: repeat(auto-fill, minmax(-webkit-min-content, 2rem));
    grid-template-rows: repeat(auto-fill, minmax(min-content, 2rem));
}

aside.pairing header h1 {
    margin: 0;
    font-size: 2rem;
    font-weight: 300;
}

aside.pairing footer {
    grid-row: -2 / -1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    gap: 1rem;
    justify-self: bottom;
    align-self: end;
}

aside.pairing footer button {
    background-color: var(--dashboard-background);
    height: 2rem;
    border-radius: .25rem;
    color: var(--dashboard-text-color);
    border: none;
    font-size: 1rem;
    
    transition-property: box-shadow;
    transition-duration: .3s;
    
    cursor: pointer;
    
    outline: none;
}

aside.pairing footer button.submit {
    background-color: #2D9CDB;
}
aside.pairing footer button.submit.disabled {
    opacity: .4;
    box-shadow: none;
}


aside.pairing footer button:hover {
    box-shadow: 0 0 0 .25rem;
}

@media (max-width: 35rem) {
    aside.pairing {
        width: 100vw !important;
        height: 100vh !important;
        grid-template-rows: 3rem auto 3rem;
        min-width: 10rem;
    }
    aside.pairing .pairing-boxes::-webkit-scrollbar{
        display: none;
    }
}
@media (orientation: landscape) {
    aside.pairing {
        width: 100vw !important;
        max-width: 100%;
        height: 100vh !important;
    }
    aside.pairing .pairing-boxes{
        height: auto;
    }
}
/* Safari IOS */
@supports (-webkit-overflow-scrolling: touch) {
    aside.pairing {
        min-height: calc(100vh - 10rem);
    }
}
@media (min-width: 48rem) and (orientation: portrait),
        (min-width: 64rem) and (orientation: landscape) {
    aside.pairing {
        width: 100vw !important;
        max-width: 100%;
        height: 100vh !important;
        border-radius: 0;
    }
    aside.pairing {
        grid-template-rows: 5rem auto 4rem;
    
    }
    aside.pairing header h1 {
        font-size: 2.6rem;
    }
    aside.pairing footer button {
        height: 3rem;
        font-size: 1.5rem;
    }
    
}
.pairing-box {
    width: 100%;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 78% 22%;
    grid-template-areas:
        "cow tracking"
        "sensor tracking";
    margin: 0;
    box-sizing: border-box;
    grid-gap: 2px;
    grid-row-end: span 2;
}
.pairing-box.animate {
    opacity: 0;
    transition: opacity 0.5s ease-out;
}
.pairing-box.animate.start {
    opacity: 1;
}

.pairing-box .tracking {
    grid-area: tracking;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.pairing-box .tracking > div {
    background-color: var(--dashboard-background);
    border-radius: 0.3rem;
    background-repeat: no-repeat;
    background-size: 2rem;
    width: 2rem;
    height: 2rem;
    background-position: center;
    background-blend-mode: overlay;
    will-change: opacity;
    cursor: pointer;
    margin-left: 0.2rem;
}

.pairing-box .tracking .pregnancy {
    background-image: url(/static/media/Calf.c028e539.svg);
}
.pairing-box .tracking .estrous {
    background-image: url(/static/media/estrous.e3fa64f4.svg);
    background-position: 0.1rem;
}

.pairing-box .tracking .health {
    background-image: url(/static/media/Thermometer.3d935352.svg);
}

.pairing-box .tracking .pregnancy {
    background-image: url(/static/media/Calf.c028e539.svg);
}
.pairing-box .tracking > .checked {
    background-blend-mode: normal;
}
.pairing-box .tracking > .disabled {
    opacity: 0.5;
    pointer-events: none;
}
.pairing-box .tracking .pregnancy.possible {
    background-image: url(/static/media/CalfDashed.677408dc.svg);
}
.pairing-box .tracking .estrous.possible {
    background-image: url(/static/media/inseminationDashed.8f2995f5.svg);
}
.pairing-box .tracking .health.possible {
    background-image: url(/static/media/ThermometerDashed.a3b28d6f.svg);
    background-size: 0.8rem;
}

.search-box.cow {
    grid-area: cow;
}
.search-box.sensor {
    grid-area: sensor;
}

.pairing-box .selected,
.pairing-box input {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.search-box {
    background-color: transparent;
    margin: 0;
    padding: 0;
    position: relative;
    box-sizing: border-box;
}

.search-box * {
    box-sizing: border-box;
}

.search-box .selected,
.search-box input {
    background-color: var(--dashboard-background);
    border: solid 2px #888;
    padding: 0.25rem 0.5rem;
    color: white;
    margin: 0;
    border-style: none solid;
    outline: none;
    caret-color: #fe0;
    font-size: 1rem;
    display: block;
    width: 100%;
}

.search-box.cow .selected,
.search-box.cow input {
    border-style: solid solid none solid;
    border-radius: 0 0.25rem 0.25rem 0;
}
.search-box.sensor .selected,
.search-box.sensor input {
    border-style: none solid solid solid;
    border-radius: 0 0 0.25rem 0.25rem;
}

.pairing-box.ready .selected {
    border-color: #3a5;
}

.pairing-box .selected,
.pairing-box input {
    padding-left: 2rem;
    background-repeat: no-repeat;
    background-blend-mode: soft-light;
}

.pairing-box .cow .selected,
.pairing-box .cow input {
    background-image: url(/static/media/CowPictogram.fafd4185.svg);
    background-size: 1.6rem;
    background-position: 0.2rem center;
}

.pairing-box .sensor .selected,
.pairing-box .sensor input {
    background-image: url(/static/media/SensorPictogram.459283df.svg);
    background-size: 1.2rem;
    background-position: 0.4rem center;
}

.pairing-box .sensor li .label {
    display: grid;
    grid-template-areas: "sensor info";
    grid-template-columns: 0.5fr 0.5fr;
}
.pairing-box .sensor li .label.active,
.pairing-box .sensor li .label.network-problem,
.pairing-box .sensor li .label.assigned {
    opacity: 0.4;
}

.pairing-box .sensor li .label.temporary-selected {
    opacity: 0.4 !important;
}
.pairing-box .sensor li .label span.info {
    grid-area: info;
    justify-items: end;
    margin-left: 0.5rem;
    font-style: italic;
    font-size: 1em;
    justify-self: end;
}
.pairing-box .sensor li .label span.sensor:before {
    grid-area: sensor;
    width: 1em;
    height: 1em;
    content: "\00a0";
    display: inline-block;
    margin-right: 0.5em;
    background-image: var(--dead-cow);
    /*For Safari flushing problem*/
    background-image: linear-gradient(180deg, #35505a 0%, #556970 100%);
    border-radius: 0.2em;
    vertical-align: middle;
    margin-bottom: 0.1em;
}
.pairing-box .sensor li .label.stable span.sensor:before {
    background-image: var(--stable-sensor);
    /*For Safari flushing problem*/
    background-image: linear-gradient(180deg, #629f26 0%, #146c3c 100%);
}
.pairing-box .sensor li .label.unstable span.sensor:before {
    background-image: var(--unstable-sensor);
    /*For Safari flushing problem*/
    background-image: linear-gradient(180deg, #f0960e 0%, #6c5914 100%);
}
.pairing-box .sensor li .label.network-problem span.sensor:before {
    background-image: var(--bad-sensor);
    /*For Safari flushing problem*/
    background-image: linear-gradient(180deg, #f3492c 0%, #bc4040 100%);
}

.search-box input:focus {
    border-color: #bbb;
}

.search-box:last-child .selected,
.search-box:last-child input {
    border-bottom-style: solid;
    border-radius: 0 0 0.25rem 0.25rem;
}

.search-box:first-child .selected,
.search-box:first-child input {
    border-top-style: solid;
    border-radius: 0.25rem 0.25rem 0 0;
}

.search-box.results input:focus {
    border-bottom-style: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 0px 0 0 2px var(--dashboard-background);
}

ul.search-results {
    position: absolute;
    background: var(--dashboard-background);
    margin: 0;
    padding: 0;
    border: solid 2px #bbb;
    border-radius: 0 0 0.25rem 0.25rem;
    border-top: none;
    list-style: none;
    font-weight: 300;
    z-index: 1000;
    width: 100%;
    box-shadow: 0px 0 0 2px var(--dashboard-background);
    max-height: 25rem;
    overflow: auto;
}

ul.search-results li {
    padding: 0.25rem 0.5rem;
}

ul.search-results li:hover {
    background-color: black;
}

.search-box ul.search-results {
    display: none;
}
.search-box.results input:focus + ul.search-results,
ul.search-results:hover {
    display: block;
}

.required-info {
    display: grid;
    grid-template-areas: "date btn" "text text";
    grid-template-columns: 3fr 1fr;
    grid-gap: 0.3rem;
    gap: 0.3rem;
    height: auto;
    border: 0.4rem solid var(--dashboard-background);
    padding: 0.6rem;
}

.required-info > button {
    margin: 0;
    width: 100%;
}
.required-info > .mobile-datepicker {
    height: 4rem;
}
.required-info > h5 {
    grid-area: text;
    margin: 0;
    color: #f2c94c;
    /* text-align: end; */
    font-size: 0.9rem;
    font-weight: 300;
}
.required-info > h5 > span {
    text-decoration: underline;
}
.required-info > .input-group {
    display: grid;
    grid-template-areas:
        "input"
        "label";
    grid-template-rows: auto auto;
}
.required-info > .input-group > input {
    background-color: var(--dashboard-background);
    background-blend-mode: overlay;
    background-position: 0.2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 0.4rem;
    border: solid 1.4px var(--dashboard-box-border);
    font-size: 1rem;
    color: var(--dashboard-text-color);
    outline: none;
}

.required-info > .input-group span {
    font-weight: 300;
    color: var(--dashboard-text-color);
}

.required-info > .input-group > .DayPickerInput {
    width: 90%;
}
.required-info > .input-group > .DayPickerInput > input {
    width: 100%;
    height: 1.6rem;
}

@media (min-width: 40rem) {
    .pairing-box {
        grid-template-areas: "cow sensor tracking";
        grid-template-rows: 1fr;
        grid-template-columns: 1fr 1fr auto;
        grid-row-end: span 1;
    }

    .search-box.cow .selected,
    .search-box.cow li,
    .search-box.cow input {
        text-align: right;
        padding-right: 1rem;
    }

    .search-box.sensor .selected,
    .search-box.sensor li,
    .search-box.sensor input {
        text-align: left;
        padding-left: 1rem;
    }
    .search-box.cow .selected,
    .search-box.cow input {
        border-style: solid none solid solid;
        border-radius: 0.25rem 0 0 0.25rem;
    }
    .search-box.sensor .selected,
    .search-box.sensor input {
        border-style: solid solid solid none;
        border-radius: 0 0.25rem 0.25rem 0;
    }

    .search-box:last-child .selected,
    .search-box:last-child input {
        border-right-style: solid;
        border-radius: 0 0.25rem 0.25rem 0;
    }

    .search-box:first-child .selected,
    .search-box:first-child input {
        border-left-style: solid;
        border-radius: 0.25rem 0 0 0.25rem;
    }

    .search-box.results input:focus {
        border-bottom-style: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        box-shadow: 0px 0 0 2px var(--dashboard-background);
    }

    .pairing-box .sensor .selected,
    .pairing-box .sensor input {
        background-position: right 0.4rem center;
        padding-right: 2rem;
    }
}

/* For iPads */
@media (min-width: 48rem) and (orientation: portrait),
    (min-width: 64rem) and (orientation: landscape) {
    .pairing-box {
        grid-row-end: span 2;
    }
    .search-box {
        height: 3rem;
        font-size: 1.5rem;
    }
    .search-box .selected,
    .search-box input {
        font-size: 1.5rem;
        padding-top: 0.25rem;
    }
    .pairing-box .cow .selected,
    .pairing-box .cow input {
        background-size: 2.6rem;
    }

    .pairing-box .sensor .selected,
    .pairing-box .sensor input {
        background-size: 1.8rem;
    }
    .pairing-box .tracking > div {
        background-size: 3rem;
        width: 3rem;
        height: 3rem;
    }
}

ul.cow-list {
    padding: 0;
    margin: 0;
    overflow-y: auto;
    overflow-x: hidden;
    border-bottom: solid 2px var(--dashboard-background);
}
li.cow {
    position: relative;
    display: grid;
    grid-template-areas:
        "farmnumber name breed-age sensors tags"
        "farmnumber name lactation sensors tags"
        "identity identity lactation sensors tags";
    grid-template-columns: -webkit-min-content 10rem 7.8rem 5.5rem 9rem;
    grid-template-columns: min-content 10rem 7.8rem 5.5rem 9rem;
    grid-template-rows: 2rem auto 2rem;
    grid-column-gap: 0.25rem;
    -webkit-column-gap: 0.25rem;
            column-gap: 0.25rem;

    background-color: var(--dashboard-widget-background);
    padding: 0.5em 1em;
    padding-left: 3em;
    border-left: solid 10px var(--dashboard-widget-background);
    border-right: solid 10px var(--dashboard-widget-background);

    background-image: url(/static/media/CowPictogram.fafd4185.svg);
    background-repeat: no-repeat;
    background-size: 2em;
    background-position: center left 0.5em;
    background-blend-mode: overlay;
    border-bottom: solid 1px var(--dashboard-background);
}
li.cow.first {
    border-top: solid 2px var(--dashboard-background);
}

li.cow.selected {
    background-blend-mode: normal;
    border-left: solid 10px var(--checked-color);
    border-right: solid 10px var(--checked-color);
    /* For Safari */
    background-blend-mode: color-dodge;
}

li.cow * {
    font-size: 0.8rem;
    font-weight: 300;
    list-style: none;
    margin: 0;
    padding: 0;
    -webkit-align-self: start;
            align-self: start;
    cursor: default;
}

li.cow strong {
    font-weight: 700;
}
li.cow > div.options {
    grid-area: options;
    background: url(/static/media/burgerNav.a2797749.svg) no-repeat;
    background-position: center;
    width: 2.4rem;
    height: 2.4rem;
    cursor: pointer;
}

li.cow > h1 {
    font-size: 1.6rem;
    font-weight: 700;
    grid-area: farmnumber;
    justify-self: start;
}

li.cow > h2 {
    font-size: 1.6em;
    grid-area: name;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
li.cow .tooltip-common .content h5 {
    font-size: 1.2em;
    font-weight: 300;
    padding: 0.1rem 0.4rem 0.1rem 0.4rem;
    cursor: pointer;
}
li.cow .tooltip-common .content h5:hover {
    background-color: var(--button-action-color);
}
li.cow .tooltip-common .content h5:hover:last-child {
    /* border-radius: 0 0 .4rem .4rem; */
}

li.cow > ul > li {
    display: inline-block;
    margin-right: 1rem;
}

li.cow > h3.identity {
    grid-area: identity;
}
li.cow > h3.breed-age {
    grid-area: breed-age;
    justify-self: start;
}
li.cow > h3.lactation {
    grid-area: lactation;
    justify-self: start;
}

li.cow > ul.sensors {
    grid-area: sensors;
    justify-self: start;
}
li.cow > ul.tags {
    grid-area: tags;
    justify-self: start;
}
li.cow > h1 {
    pointer-events: none;
}
li.cow > h3 {
    pointer-events: none;
}

li.cow > ul.sensors li:before {
    width: 1em;
    height: 1em;
    content: "\00a0";
    display: inline-block;
    margin-right: 0.25em;
    background-image: var(--dead-cow);
    /*For Safari flushing problem*/
    background-image: linear-gradient(180deg, #35505a 0%, #556970 100%);
    border-radius: 0.2em;
    vertical-align: middle;
    margin-bottom: 0.1em;
}
li.cow > ul.sensors li.stable:before {
    background-image: var(--good-cow);
    background-image: linear-gradient(180deg, #629f26 0%, #146c3c 100%);
}
li.cow > ul.sensors li.unstable:before {
    background-image: var(--bad-cow);
    background-image: linear-gradient(180deg, #f3492c 0%, #bc4040 100%);
}

li.cow > ul.tags > li:before {
    width: 1em;
    height: 1em;
    content: "\00a0";
    display: inline-block;
    margin-right: 0.25em;
    background-image: var(--dead-cow);
    /*For Safari flushing problem*/
    background-image: linear-gradient(180deg, #35505a 0%, #556970 100%);
    border-radius: 0.2em;
    vertical-align: middle;
    margin-bottom: 0.1em;
}

li.cow > ul.tags > li.due:before {
    background-image: var(--due-cow);
    background-image: linear-gradient(180deg, #83d2ff 0%, #2d9cdb 100%);
}

li.cow > ul.tags > li.tracked:before,
li.cow > ul.tags > li.pregnant:before {
    background-image: var(--good-cow);
    /*For Safari flushing problem*/
    background-image: linear-gradient(180deg, #629f26 0%, #146c3c 100%);
}

li.cow > ul.tags > li.calving:before {
    background-image: var(--calving-cow);
    background-image: linear-gradient(180deg, #2d9cdb 0%, #134561 100%);
}

li.cow > ul.tags > li.hypothermia:before,
li.cow > ul.tags > li.fever:before {
    background-image: var(--bad-cow);
    /*For Safari flushing problem*/
    background-image: linear-gradient(180deg, #f3492c 0%, #bc4040 100%);
}
li.cow > ul.tags li strong {
    margin-right: 0.5rem;
}
@media (max-width: 35rem), (orientation: landscape) {
    ul.cow-list {
        overflow-x: hidden;
        overflow-y: auto;
        border-top: solid 2px var(--dashboard-background);
        border-bottom: solid 2px var(--dashboard-background);
    }
    ul.cow-list::-webkit-scrollbar {
        display: none;
    }
    li.cow {
        display: grid;
        grid-template-areas:
            "farmnumber name . tags"
            "identity identity . tags"
            "identity identity . tags";
        grid-template-columns: auto 11fr 1fr 7.8rem;
        grid-template-rows: 1rem auto 1rem;
        grid-column-gap: 0.25rem;
        -webkit-column-gap: 0.25rem;
                column-gap: 0.25rem;

        background-color: var(--dashboard-widget-background);
        padding: 0.5em 1em;
        padding-left: 3em;
        border-left: solid 10px var(--dashboard-widget-background);
        border-right: solid 10px var(--dashboard-widget-background);

        background-image: url(/static/media/CowPictogram.fafd4185.svg);
        background-repeat: no-repeat;
        background-size: 2em;
        background-position: center left 0.5em;
        background-blend-mode: overlay;
        border-bottom: solid 1px var(--dashboard-background);
        grid-row-gap: 0.5em;
        row-gap: 0.5em;
    }
    li.cow.first {
        border-top: none;
    }
    li.cow-details {
        display: grid;
        grid-template-areas:
            "breed-age-lactation breed-age-lactation"
            "sensors ."
            "sensors .";

        grid-template-columns: 6rem 6rem;
        grid-template-rows: 1rem auto 1rem;
        grid-column-gap: 0.5em;
        -webkit-column-gap: 0.5em;
                column-gap: 0.5em;
        grid-row-gap: 0.5em;
        row-gap: 0.5em;
        padding: 0.3rem 1rem;
        margin-left: 24.2%;
        border-left: solid 5px var(--dashboard-background);
    }
    li.cow-options {
        display: grid;
        grid-template-areas: "edit remove";
        grid-column-gap: 0.5rem;
        -webkit-column-gap: 0.5rem;
                column-gap: 0.5rem;
        padding: 0.3em 0.3em;
        margin-right: 55%;
        border-right: solid 5px var(--dashboard-background);
    }
    li.cow * {
        font-size: 0.7rem;
    }
    li.cow-details * {
        font-size: 0.7rem;
        font-weight: 300;
        list-style: none;
        margin: 0;
        padding: 0;
        -webkit-align-self: start;
                align-self: start;
    }
    li.cow-options * {
        font-size: 0.7rem;
        font-weight: 300;
        list-style: none;
        margin: 0;
        padding: 0;
        -webkit-align-self: start;
                align-self: start;
    }
    li.cow > h1 {
        font-size: 1.3em;
    }
    li.cow > h2 {
        font-size: 1.3em;
        font-weight: 300;
    }
    li.cow > ul.tags > li {
        font-size: 0.8rem;
        margin-right: 0;
    }
    li.cow > ul.tags > li:before {
        margin-bottom: 0.15rem;
    }
    li.cow-details > ul.sensors li:before {
        width: 1em;
        height: 1em;
        content: "\00a0";
        display: inline-block;
        margin-right: 0.25em;
        background-image: var(--dead-cow);
        /*For Safari flushing problem*/
        background-image: linear-gradient(180deg, #35505a 0%, #556970 100%);
        border-radius: 0.2em;
        vertical-align: middle;
        margin-bottom: 0.1em;
    }
    li.cow-details > ul.sensors li.stable:before {
        background-image: var(--good-cow);
        background-image: linear-gradient(180deg, #629f26 0%, #146c3c 100%);
    }
    li.cow-details > ul.sensors li.unstable:before {
        background-image: var(--bad-cow);
        background-image: linear-gradient(180deg, #f3492c 0%, #bc4040 100%);
    }

    li.cow > ul.tags > li > strong {
        font-size: 0.8rem;
        font-weight: 400;
    }
    li.cow > div.options {
        display: none;
    }
    li.cow > h3.identity {
        font-size: 0.8em;
    }
    li.cow-details > h3.breed-age-lactation {
        grid-area: breed-age-lactation;
        font-size: 0.7rem;
        font-weight: 300;
        justify-self: start;
    }
    li.cow-details > h3.breed-age-lactation strong {
        font-weight: 600;
        margin-left: 0.3rem;
    }
    li.cow-details > ul.sensors > li > strong {
        font-weight: 600;
    }
    li.cow-details > ul.sensors {
        grid-area: sensors;
        justify-self: start;
    }
    li.cow-options > button {
        padding: 0;
        margin: 0;
        padding-top: 1.8rem;
        font-size: 0.9rem;
        background-position: top;
        background-repeat: no-repeat;
        background-size: 2rem;
        background-color: var(--dashboard-background);
        border: solid 1.4px var(--dashboard-box-border);
        height: 3rem;
        width: auto;
    }
    li.cow-options > .edit {
        grid-area: edit;
        --edit: url(/static/media/Edit.e29be074.svg);
        background-image: var(--edit);
    }
    li.cow-options > .remove {
        grid-area: remove;
        --remove: url(/static/media/Remove.1d0dc4e8.svg);
        background-image: var(--remove);
    }
    ul.cow-list::-webkit-scrollbar {
        display: none;
    }
}
/* For iPads */
@media (min-width: 48rem) and (orientation: portrait),
    (min-width: 64rem) and (orientation: landscape) {
    ul.cow-list {
        overflow-x: hidden;
        overflow-y: auto;
        border-top: solid 2px var(--dashboard-background);
        border-bottom: solid 2px var(--dashboard-background);
    }
    ul.cow-list::-webkit-scrollbar {
        display: none;
    }
    li.cow {
        display: grid;
        grid-template-areas:
            "farmnumber name tags"
            "identity identity tags"
            "identity identity tags";
        grid-template-columns: auto 1.8fr 3fr;
        grid-template-rows: 2.5rem auto 2.5rem;
        grid-column-gap: 0.8rem;
        -webkit-column-gap: 0.8rem;
                column-gap: 0.8rem;
        background-color: var(--dashboard-widget-background);
        padding: 0.5em 1em;
        padding-left: 5em;
        border-left: solid 10px var(--dashboard-widget-background);
        border-right: solid 10px var(--dashboard-widget-background);
        background-image: url(/static/media/CowPictogram.fafd4185.svg);
        background-repeat: no-repeat;
        background-position: center left 0.5em;
        background-blend-mode: overlay;
        border-bottom: solid 1px var(--dashboard-background);
        background-size: 3.4em;
        grid-row-gap: 0.5em;
        row-gap: 0.5em;
    }
    li.cow.first {
        border-top: none;
    }
    li.cow-details {
        display: grid;
        grid-template-areas:
            "breed-age-lactation breed-age-lactation"
            "sensors ."
            "sensors .";

        grid-template-columns: 1fr 1fr;
        grid-template-rows: 2.5rem auto 2.5rem;
        grid-column-gap: 0.5em;
        -webkit-column-gap: 0.5em;
                column-gap: 0.5em;
        grid-row-gap: 0.5em;
        row-gap: 0.5em;
        padding: 0.3rem 1rem;
        margin-left: 24.2%;
        border-left: solid 5px var(--dashboard-background);
    }
    li.cow * {
        font-size: 1.2rem;
    }
    li.cow-details * {
        font-size: 1.2rem;
        font-weight: 300;
        list-style: none;
        margin: 0;
        padding: 0;
        -webkit-align-self: start;
                align-self: start;
    }
    li.cow > h1 {
        font-size: 1.9em;
    }
    li.cow > h2 {
        font-size: 1.7em;
        font-weight: 300;
    }
    li.cow-options {
        display: grid;
        grid-template-areas: "edit remove";
        grid-column-gap: 0.5rem;
        -webkit-column-gap: 0.5rem;
                column-gap: 0.5rem;
        padding: 0.3em 0.3em;
        margin-right: 55%;
        border-right: solid 5px var(--dashboard-background);
    }
    li.cow-options * {
        font-weight: 300;
        list-style: none;
        margin: 0;
        padding: 0;
        -webkit-align-self: start;
                align-self: start;
    }
    li.cow > ul.tags > li {
        height: 1em;
        margin-right: 0.5rem;
    }
    li.cow > ul.tags > li:before {
        margin-bottom: 0.15rem;
    }
    li.cow-details > ul.sensors li:before {
        width: 1em;
        height: 1em;
        content: "\00a0";
        display: inline-block;
        margin-right: 0.25em;
        background-image: var(--dead-cow);
        /*For Safari flushing problem*/
        background-image: linear-gradient(180deg, #35505a 0%, #556970 100%);
        border-radius: 0.2em;
        vertical-align: middle;
        margin-bottom: 0.1em;
    }
    li.cow > ul.tags > li,
    li.cow > ul.tags > li > strong,
    li.cow > h3.identity,
    li.cow-details > h3.breed-age-lactation,
    li.cow-details > h3.breed-age-lactation strong,
    li.cow-details > ul.sensors > li > strong {
        font-size: 1.3rem;
        font-weight: 400;
    }
    li.cow-details > ul.sensors li.stable:before {
        background-image: var(--good-cow);
        background-image: linear-gradient(180deg, #629f26 0%, #146c3c 100%);
    }
    li.cow-details > ul.sensors li.unstable:before {
        background-image: var(--bad-cow);
        background-image: linear-gradient(180deg, #f3492c 0%, #bc4040 100%);
    }

    li.cow > div.options {
        display: none;
    }
    li.cow-details > h3.breed-age-lactation {
        grid-area: breed-age-lactation;
        justify-self: start;
    }
    li.cow-details > h3.breed-age-lactation strong {
        font-weight: 600;
        margin-left: 0.3rem;
    }
    li.cow-details > ul.sensors > li > strong {
        font-weight: 600;
    }
    li.cow-details > ul.sensors {
        grid-area: sensors;
        justify-self: start;
    }
    li.cow-options > button {
        padding: 0;
        margin: 0;
        padding-top: 4rem;
        font-size: 1.2rem;
        background-size: 4.5rem;
        background-position: top;
        background-repeat: no-repeat;
        background-color: var(--dashboard-background);
        border: solid 1.4px var(--dashboard-box-border);
        height: 3rem;
        width: auto;
        height: 6rem;
    }
    li.cow-options > .edit {
        grid-area: edit;
        --edit: url(/static/media/Edit.e29be074.svg);
        background-image: var(--edit);
    }
    li.cow-options > .remove {
        grid-area: remove;
        --remove: url(/static/media/Remove.1d0dc4e8.svg);
        background-image: var(--remove);
    }
    ul.cow-list::-webkit-scrollbar {
        display: none;
    }
}

.cow-list [data-slider-container] {
    position: relative;
    overflow: hidden;
    height: 4rem;
    border-bottom: solid 1px var(--dashboard-background);
  }
  
.cow-list [data-slider-overlay] {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: -webkit-transform 400ms ease-out;
    transition: transform 400ms ease-out;
    transition: transform 400ms ease-out, -webkit-transform 400ms ease-out;
  }
.cow-list [data-slider-overlay*="drag"] {
    transition: none;
  }
  
.cow-list [data-slider-overlay="left"] {
    -webkit-transform: translate(45%);
            transform: translate(45%);
  }
  
.cow-list [data-slider-overlay="right"] {
    -webkit-transform: translate(-75%);
            transform: translate(-75%);
  }
.cow-list [data-slider-right] {
    border: solid 4px var(--dashboard-background);
  }
.cow-list [data-slider-left] {
    border: solid 4px var(--dashboard-background);
}
@media (min-width: 52rem) {
  .cow-list [data-slider-overlay="right"] {
    -webkit-transform: translate(-75.5%);
            transform: translate(-75.5%);
  }
}

/* For iPads */
@media (min-width: 48rem) and (orientation: portrait),
        (min-width: 64rem) and (orientation: landscape) {
  .cow-list [data-slider-container] {
    height: 7rem;
  }
}
/* .filter-box{
    display: grid;
    grid-template-areas: "query icon";
    grid-template-columns: 1fr 2rem;
    grid-auto-rows: minmax(2rem, auto);
    background-color: var(--dashboard-background);
    border: solid 2px #E0E0E0;
    padding: .4rem .5rem;
    color: white;
    margin: 0;
    border-radius: .4rem;
} */
.filter-box {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    background-color: var(--dashboard-background);
    border: solid 2px #e0e0e0;
    padding: 0.4rem 0.5rem;
    color: white;
    margin: 0;
    border-radius: 0.4rem;
    cursor: text;
    /* justify-content: space-between; */
}

.filter-box.open {
    border-bottom-style: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.filter-box div.query {
    width: -webkit-max-content;
    width: max-content;
    margin: 0.1rem;
}
.filter-box i.filter-icon {
    background: url(/static/media/Filter.05d21411.svg) no-repeat;
    background-size: 2rem;
    background-position: center;
    background-color: var(--dashboard-background);
    background-blend-mode: overlay;
    width: 1.8rem;
    height: 1.8rem;
    margin-left: auto;
    -webkit-order: 2;
            order: 2;
    cursor: pointer;
    -webkit-align-self: center;
            align-self: center;
}
.filter-box.pending i.filter-icon {
    border-radius: 50%;
    background-size: 0;
    border-style: dashed;
    border-color: #379;
    border-width: 3px;
    width: 1.4rem;
    height: 1.4rem;
    -webkit-animation: rotation linear 2s infinite;
            animation: rotation linear 2s infinite;
}
.filter-box i.filter-icon.tag-selected {
    grid-area: icon;
    background: url(/static/media/Done.991b2e2c.svg) no-repeat;
    background-size: 1.6rem;
    background-position: center;
    width: 1.8rem;
    height: 1.8rem;
    justify-self: end;
}
.filter-box i.filter-icon.selected {
    background-blend-mode: normal;
}

.filter-box input,
.filter-results input {
    /* display: block; */
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    outline: none;
    border: none;
    color: white;
    height: 100%;
    -webkit-align-self: center;
            align-self: center;
    font-size: 1rem;
}

.filter-box input.tag-param-input {
    display: inline-block;
    width: 1rem;
    cursor: pointer;
    text-align: center;
}

.filter-box input.tag-param-input.digits-1 {
    width: 1rem;
}
.filter-box input.tag-param-input.digits-2 {
    width: 1.2rem;
}
.filter-box input.tag-param-input.digits-3 {
    width: 2rem;
}

.filter-results input.tag-param-input {
    display: inline-block;
    width: 1rem;
    opacity: 0.5;
}
.filter-results input.tag-param-input.digits-1 {
    width: 1rem;
}
.filter-results input.tag-param-input.digits-2,
.filter-results input.tag-param-input.digits-3 {
    width: 2rem;
}

.filter-box div.query.new input {
    display: inline-block;
    margin-top: 0.3rem;
}

tag,
sort {
    display: inline-block;
    background-color: var(--tag-color);
    -webkit-align-self: center;
            align-self: center;
    border-radius: 0.3rem;
    text-align: center;
    /* margin: .2rem .3rem .3rem .2rem; */
    padding: 0 0.3rem;
    padding-bottom: 0.2rem;
}
sort {
    cursor: pointer;
    background-color: var(--tag-sort-color);
}

parameter {
    background-color: var(--tag-parameter-color);
    padding: 0 0.3rem;
    padding-bottom: 0.2rem;
}
parameter span.following {
    opacity: 0.4;
}

logic {
    background-color: var(--tag-logic-color);
    padding: 0 0.3rem;
    padding-bottom: 0.2rem;
}

.filter-box tag .remove::before,
.filter-box sort .remove::before {
    content: "\00d7";
}

.filter-box tag.ascending:before,
.filter-box sort.ascending:before {
    content: "\25b2 ";
    font-size: 0.8em;
    opacity: 0.4;
}

.filter-box tag.descending:before,
.filter-box sort.descending:before {
    content: "\25bc ";
    font-size: 0.8em;
    opacity: 0.4;
}

.filter-results sort:before {
    content: "\25b2\25bc ";
    font-size: 0.6em;
    opacity: 0.4;
    vertical-align: middle;
}

.filter-box tag span.remove,
.filter-box sort span.remove {
    content: "\00d7";
    margin: 0 0 0 0.3rem;
    opacity: 0.4;
    cursor: pointer;
}
.filter-box tag span.remove:hover,
.filter-box sort span.remove:hover {
    opacity: 0.8;
}

ul.filter-results,
div.filter-options {
    position: absolute;
    background: var(--dashboard-background);
    margin: 0;
    padding: 0;
    border: solid 2px #bbb;
    border-radius: 0 0 0.25rem 0.25rem;
    border-top: none;
    list-style: none;
    z-index: 1000;
    width: 99%;
    width: 26.8rem;
    box-shadow: 0px 0 0 2px var(--dashboard-background);
    right: 0;
    left: 0;
    width: calc(100% - 2.25rem);
    margin-left: auto !important;
    margin-right: auto !important;
    top: -0.8rem;
}

ul.filter-results li {
    padding: 0.25rem 0.5rem;
    list-style: none;
}

ul.filter-results li.hover {
    background-color: black;
}
ul.filter-results li p {
    margin: 0;
}

ul.filter-results li tag {
    padding: 0 0.6rem 0 0.6rem;
    padding-bottom: 0.2rem;
}
ul.filter-results li logic {
    border-radius: 0.3rem;
}
ul.filter-results li parameter {
    border-radius: 0.3rem;
}

.filter-box div.query *:first-child,
.filter-box div.query.new *:first-child {
    border-radius: 0.3rem 0 0 0.3rem;
}
.filter-box div.query *:last-child,
.filter-box div.query.new *:not(input):last-child {
    border-radius: 0 0.3rem 0.3rem 0;
}

.filter-box div.query.single-element * {
    border-radius: 0.3rem;
}
.filter-options button:disabled {
    opacity: 0.5;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* For iPads */
@media (min-width: 48rem) and (orientation: portrait),
    (min-width: 64rem) and (orientation: landscape) {
    .filter-box div.query.new input {
        width: auto !important;
        font-size: 1.25rem;
    }
}

main.cow-list {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    
    position: relative;
    max-width: 60rem;
    max-height: 50rem;
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;

    background-color: var(--dashboard-widget-background);
    display: grid;
    grid-template-areas:    "mobile-header mobile-header"
                            "filter add-cow"
                            "results ."
                            "body body"
                            "footer footer";
    grid-template-columns: 1fr 4rem;
    grid-template-rows:  4rem auto auto 1fr 2rem 2rem;
}
main.cow-list > .mobile-header{
    grid-area: mobile-header;
    display: grid;
    grid-template-areas: "farm-name close-button";
    grid-template-columns: auto 3.4rem;
}

main.cow-list > .mobile-header > .close-button {
    grid-area: close-button;
    background-image: url(/static/media/CloseButton.afff8ee2.svg);
    background-repeat: no-repeat;
    background-size: 2.8rem;
    margin-top: 1rem;
    margin-right: .4rem;
    cursor: pointer;
}
main.cow-list > .mobile-header > .farm-name{
    grid-area: farm-name;
    margin: 0 0 0 1.4rem;
    padding-top: .8rem;
    font-size: 2rem;
    font-weight: 300;
}
main.cow-list i.add-cow{
    grid-area: add-cow;
    background: url(/static/media/AddButton.350c65ce.svg) no-repeat;
    background-size: 2.8rem;
    background-position: center;
    margin-right: .6rem;
    margin-top: .8rem;
    margin-bottom: .8rem;
    cursor: pointer;
}

main.cow-list > .filter-box {
    grid-area: filter;
    margin: 1rem;
}

main.cow-list > .filter-results,
main.cow-list > .filter-options{
    grid-area: results;
    margin: 0;
    padding: 0;
    margin-left: 3.8rem;
}
main.cow-list > .filter-options {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;

}
main.cow-list > .filter-options button{
    min-width: 7rem;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    margin: .2rem 1rem .2rem 1rem;
    font-size: .8em;
    background-color: var(--dashboard-widget-background);
}
main.cow-list > .filter-options button.tag {
    background-color: var(--tag-color);
}
main.cow-list > .filter-options button.logic {
    background-color: var(--tag-logic-color);
}
main.cow-list > .filter-options button.sort {
    background-color: var(--tag-sort-color);
}


main.cow-list > ul.cow-list {
    grid-area: body;
    overflow: hidden auto;
    /* For Safari Browsers*/
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    max-height: 100%;
}
main.cow-list > .footer{
    grid-area: footer;
}

main.cow-list > .footer > h6.info{
    position: absolute;
    margin-top: -1.2rem;
    width: 100%;
    justify-self: center;
    background-color: #fff;
    color: black;
    font-size: 1.2rem;
    text-align: center;
}
main.cow-list > .footer > h6.info > a{
    color: var(--button-action-color);
    cursor: pointer;
    text-decoration: underline;
}
main.cow-list .footer button {
    -webkit-align-self: center;
            align-self: center;
    width: 95%;
    min-height: 2rem;
    height: auto;
}


@media (max-width: 35rem) {
    main.cow-list {
    grid-template-areas:    "mobile-header mobile-header"
                            "filter add-cow"
                            "results ."
                            "body body"
                            "footer footer";
    grid-template-columns: 1fr 3.3rem;
    grid-template-rows:  4rem auto auto 1fr 2rem 2rem;
    border-radius: 0;
    background-color: var(--dashboard-widget-background);
    }
    main.cow-list > .filter-box {
        grid-area: filter;
        margin: 1rem;
    }
    main.cow-list > .mobile-header{
        grid-area: mobile-header;
        display: grid;
        grid-template-areas: "farm-name close-button";
        grid-template-columns: auto 3.4rem;
    }
    main.cow-list > .mobile-header > .close-button {
        grid-area: close-button;
        background-image: url(/static/media/CloseButton.afff8ee2.svg);
        background-repeat: no-repeat;
        background-size: 2.8rem;
        margin-top: 1rem;
    }
    main.cow-list > .mobile-header > .farm-name{
        grid-area: farm-name;
        margin: 0 0 0 1.4rem;
        padding-top: .8rem;
        font-size: 2rem;
        font-weight: 300;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    main.cow-list > .filter-options {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;

    }
    main.cow-list > .filter-options button.tag {
        min-width: 6rem;
        -webkit-flex-grow: 1;
                flex-grow: 1;
        margin: .2rem 1rem .2rem 1rem;
        font-size: .8em;
    }
    main.cow-list .footer button {
        width: 90%;
    }
    main.cow-list i.add-cow{
        background-position: center;
        margin-right: .6rem;
    }
}
/* Safari IOS */
@supports (-webkit-overflow-scrolling: touch) {
    main.cow-list {
        max-height: 43rem;
    }
}

/* For iPads */
@media (min-width: 48rem) and (orientation: portrait),
        (min-width: 64rem) and (orientation: landscape) {
    main.cow-list {
        max-width: 100%;
        grid-template-areas:    "mobile-header mobile-header"
                                "filter add-cow"
                                "results ."
                                "body body"
                                "footer footer";
        grid-template-columns: 1fr 4rem;
        grid-template-rows:  4rem auto auto 1fr 2rem 2rem;
    }
    main.cow-list::-webkit-scrollbar{
        display: none;
    }
    main.cow-list > .mobile-header{
        grid-template-columns: auto 4rem;
    }
    main.cow-list > .mobile-header > .close-button {
        background-size: 3rem;
    }
    main.cow-list > .mobile-header > .farm-name{
        padding-top: 0;
        font-size: 2.8rem;
    }
    main.cow-list i.add-cow{
        background-size: 3rem;
        margin-right: .8rem;
    }

    main.cow-list > .filter-options button{
        font-size: 1.2em;
    }
    main.cow-list > .footer > h6.info{
        margin-top: -1.6rem;
        height: 2.1rem;
        font-size: 1.3rem;
    }
    main.cow-list .footer button {
        height: 2.7rem;
        font-size: 1.3rem;
        width: 96%;
    }
}
main.your-profile {
    position: relative;
    margin: 0;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--dashboard-widget-background);
    z-index: 100;
    border-radius: 0;
    display: grid;
    grid-gap: 1rem;
    padding: 1rem;
    grid-template-areas:
        "header close-button"
        "language language"
        "name-surname name-surname"
        "email-address email-address"
        "number-input number-input"
        "call-contract call-contract"
        "warning-new-user warning-new-user"
        "update update";
    grid-template-columns: auto 3rem;
    grid-template-rows: 5rem 5rem 5rem 5rem 1fr 1fr 1fr 1fr;
    border-radius: 1rem 0 0 1rem;
    box-shadow: 0 0 0 150vmax rgba(0, 0, 0, 0.5);
}
main.your-profile .close-button {
    grid-area: close-button;
    --close: url(/static/media/CloseButton.be369177.svg);
    background-image: var(--close);
    background-position: right;
    background-size: 2.8rem;
    background-repeat: no-repeat;
    padding: 0;
    margin-top: 1rem;
    margin-bottom: 1rem;
    cursor: pointer;
}
main.your-profile h1.header {
    grid-area: header;
    margin: 0;
    font-size: 3rem;
    font-weight: 300;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
main.your-profile > label.call-contract {
    grid-area: call-contract;
    justify-self: end;
    font-size: 0.9em;
    font-style: italic;
}
main.your-profile > label.warning-new-user {
    grid-area: warning-new-user;
    justify-self: center;
    font-size: 0.9em;
    font-style: italic;
}
main.your-profile > label.call-contract strong {
    font-weight: bold;
}
main.your-profile > button.update {
    grid-area: update;
    align-self: end;
}
main.your-profile > button.update:disabled {
    background-color: var(--dashboard-background);
    border: solid var(--button-action-color) 0.1rem;
}
main.your-profile > .name-surname {
    grid-area: name-surname;
    display: grid;
    grid-template-areas: "first-name last-name";
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0.1rem;
}
main.your-profile > .language {
    grid-area: language;
    display: grid;
    grid-template-areas:
        "languageEn languageDe languageTr languageIt languageDa languagePt languageEs languageBg languageRu languageFr"
        "lang lang lang lang lang lang lang lang lang lang";
    grid-template-columns: 3rem 3rem 3rem 3rem 3rem 3rem 3rem 3rem 3rem 2.8rem;
    grid-template-rows: 1fr 2fr;
    height: 2.6rem;
    width: -webkit-min-content;
    width: min-content;
    border: solid 3px var(--dashboard-background);
    border-radius: 0.4rem;
}
main.your-profile > .input-group.email-address {
    grid-area: email-address;
}
main.your-profile > .number-input {
    grid-area: number-input;
    display: grid;
    grid-template-areas: "phone-number";
    grid-template-columns: 1fr;
}

main.your-profile > .name-surname > .input-group.first-name {
    grid-area: first-name;
}
main.your-profile > .name-surname > .input-group.last-name {
    grid-area: last-name;
}
main.your-profile > .number-input > .input-group.phone-number {
    grid-area: phone-number;
}

main.your-profile > .language > button {
    font-size: 0.9rem;
    font-weight: 250;
    padding: 0;
    margin: 0;
    margin-top: 0.1rem;
    margin-left: 0.2rem;
    background-color: var(--dashboard-background);
    width: 2.5rem;
    height: 2.4rem;
}
main.your-profile > .language > button.selected {
    border: solid white 0.04rem;
}
main.your-profile > .language > button:active {
    opacity: 0.5;
}
main.your-profile > .language > .lang {
    grid-area: lang;
    font-size: 0.9rem;
    font-weight: 300;
}
main.your-profile > .name-surname .input-group,
main.your-profile > .input-group.email-address,
main.your-profile > .number-input .input-group {
    display: grid;
    grid-template-areas:
        "input"
        "label";
    grid-template-rows: 2.6rem 1rem;
}
main.your-profile > .name-surname > .input-group.first-name input {
    text-align: right;
    padding-right: 0.5rem;
}
main.your-profile > .name-surname > .input-group > input,
main.your-profile > .input-group.email-address > input,
main.your-profile > .number-input > .input-group > input {
    background-color: var(--dashboard-background);
    border-radius: 0.4rem;
    border: solid 1.4px rgb(224, 224, 224, 0.7);
    font-size: 1rem;
    padding-left: 0.5rem;
    color: var(--dashboard-text-color);
    width: 100%;
    box-sizing: border-box;
    outline: none;
}
main.your-profile > .name-surname > .input-group span,
main.your-profile > .input-group.email-address span,
main.your-profile > .number-input > .input-group span {
    font-size: 0.9rem;
    font-weight: 300;
    color: var(--dashboard-text-color);
    width: 100%;
    box-sizing: border-box;
}
main.your-profile > .name-surname > .input-group.first-name span {
    text-align: right;
    padding-right: 0.3rem;
}
main.your-profile > .name-surname > .input-group.last-name span {
    padding-left: 0.3rem;
}
/* left */
main.your-profile > .name-surname > .input-group.last-name input,
main.your-profile > .number-input > button {
    border-radius: 0 0.4rem 0.4rem 0;
    border-left-color: var(--dashboard-widget-background);
}
main.your-profile > .name-surname > .input-group.last-name.validate input {
    border-radius: 0 0.4rem 0 0;
}
/* right */
main.your-profile > .name-surname > .input-group.first-name input {
    border-radius: 0.4rem 0 0 0.4rem;
    border-right-color: var(--dashboard-widget-background);
}
main.your-profile > .name-surname > .input-group.first-name.validate input,
main.your-profile > .number-input > .input-group.phone-number.validate input {
    border-radius: 0.4rem 0 0 0;
}
/* left and right */
main.your-profile > .input-group.email-address.validate input {
    border-radius: 0.4rem 0.4rem 0 0;
}
@media (max-width: 35rem) {
    main.your-profile h1.header {
        -webkit-align-self: start;
                align-self: start;
        font-size: 2rem;
    }
    main.your-profile .close-button {
        margin: 0;
    }
    main.your-profile{
        position: relative;
        top: 0;
        grid-template-areas:"header close-button"
                            "language language"
                            "name-surname name-surname"
                            "email-address email-address"
                            "number-input number-input"
                            "call-contract call-contract"
                            "warning-new-user warning-new-user"
                            "update update";
        grid-template-columns: auto 3rem;
        grid-template-rows: 3rem 7rem 5rem 5rem 5rem 0.4rem 0.4rem 1fr;
        transition: none;
    }
    main.your-profile.open{
        -webkit-transform: none;
                transform: none;
    }
    main.your-profile > label.warning-new-user{
        font-size: .8em;
    }
    main.your-profile > button {
        height: 2.3rem;
    }
    main.your-profile > .language {
        grid-template-areas:
            "languageEn languageDe languageTr languageIt languageDa languagePt"
            "languageEs languageBg languageRu languageFr . ."
            "lang lang lang lang lang lang";
        grid-template-columns: 3rem 3rem 3rem 3rem 3rem 2.8rem;
        grid-template-rows: 1fr 1fr;
        height: 5.2rem;
        width: -webkit-min-content;
        width: min-content;
        margin-top: 1.5rem;
        margin-left: 0;
        margin-bottom: 1.5rem;
    }
    main.your-profile > .language > .lang {
        justify-self: left;
        padding-top: 0.5rem;
    }
    main.your-profile > .number-input {
        grid-template-rows: 4.6rem 1rem;
    }
    main.your-profile > .name-surname .input-group,
    main.your-profile > .input-group.email-address,
    main.your-profile > .number-input .input-group {
        height: 4.4rem;
        margin-top: 2rem;
    }
    main.your-profile > .number-input > button {
        margin-top: 2rem;
    }
}

/* For iPads */
@media (min-width: 48rem) and (orientation: portrait),
        (min-width: 64rem) and (orientation: landscape) {
    main.your-profile {
        grid-template-columns: auto 3.65rem;
        grid-template-rows: 6rem 6.5rem 5.5rem 5.5rem 1fr 1fr 1fr 1fr;
    }
    main.your-profile .close-button{
        background-size: 3rem;
        height: 3.1rem;
        width: 3.1rem;
    }
    main.your-profile > button{
        height: 3rem;
        font-size: 1.4rem;
    } 
    main.your-profile h1.header{
        font-size: 2.8rem;
    }
    main.your-profile > label.call-contract,
    main.your-profile > label.warning-new-user{
        font-size: 1.2em;
    }
     main.your-profile > .language{
        grid-template-columns: 3.8rem 3.8rem 3.8rem 3.8rem 3.6rem;
        height: 3.5rem;
    }
    main.your-profile > .language > button {
        font-size: 1.3rem;
        width: 3.3rem;
        height: 3.2rem;
    }
    main.your-profile > .language > .lang {
        font-size: 1.3rem;
    }
    main.your-profile > .name-surname .input-group,
    main.your-profile > .input-group.email-address,
    main.your-profile > .number-input .input-group{
        grid-template-rows: 2.8rem 1rem;
    }
        main.your-profile > .name-surname > .input-group > input,
        main.your-profile > .input-group.email-address > input,
        main.your-profile > .number-input > .input-group > input{
        height: 2.9rem;
        font-size: 1.4rem;
    }
    main.your-profile > .name-surname > .input-group span,
    main.your-profile > .input-group.email-address span,
    main.your-profile > .number-input > .input-group span{
        font-size: 1.3rem;
    }
}

main.cow-modal{
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 1rem 1rem 1rem 1rem;
    
    max-width: 60rem;
    max-height: 35rem;
    margin: 0 auto;
    box-sizing: border-box;
    overflow: hidden;
    padding-top: 1rem;
    background-color: var(--dashboard-widget-background);

    display: grid;
    grid-template-areas:
        "head head"
        "body body"
        "submit submit";
    grid-template-rows: 4rem 7fr 5rem;
    box-shadow: 0 0 0 150vmax rgba(0, 0, 0, 0.5);
}
main.cow-modal > button.submit:disabled {
    opacity: 0.4;
    cursor: auto;
}
main.cow-modal > .head {
    grid-area: head;
    display: grid;
    grid-template-areas: "header close-button";
    grid-template-columns: auto 3.5rem;
}
main.cow-modal > .head > h6 {
    grid-area: header;
    margin: 0 0 0 0;
    padding-top: 0.3rem;
    padding-left: 1rem;
    font-size: 2rem;
    font-weight: 300;
}

main.cow-modal > .head > .close-button {
    grid-area: close-button;
    background-image: url(/static/media/CloseButton.afff8ee2.svg);
    background-repeat: no-repeat;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    background-size: 2.8rem;
    cursor: pointer;
}
main.cow-modal button.submit {
    grid-area: submit;
}

main.cow-modal > #cow-form {
    grid-area: body;
    display: grid;
    grid-template-areas:
        "farm-number farm-number name name name name name name"
        "identity identity identity birth birth birth breed breed"
        "reproductive reproductive reproductive reproductive . . . ."
        "lactation lactation calving calving calving insemination insemination insemination"
        "pregnancy pregnancy . . . . . ."
        "info info info info info info info info";
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: 4.4rem 4.4rem 2rem 4.4rem auto;
    grid-row-gap: 1.4rem;
    padding: 1.4rem;
}
main.cow-modal > #cow-form .input-group.farm-number {
    grid-area: farm-number;
}
main.cow-modal > #cow-form .input-group.name {
    grid-area: name;
}
main.cow-modal > #cow-form .input-group.identity {
    grid-area: identity;
}
main.cow-modal > #cow-form .input-group.birth {
    grid-area: birth;
}
main.cow-modal > #cow-form .input-group.breed {
    grid-area: breed;
}
main.cow-modal > #cow-form .input-group.identity {
    grid-area: identity;
}
main.cow-modal > #cow-form .input-group.calving {
    grid-area: calving;
}
main.cow-modal > #cow-form .input-group.insemination {
    grid-area: insemination;
}
main.cow-modal > #cow-form .input-group.lactation {
    grid-area: lactation;
}
main.cow-modal > #cow-form .input-group.pregnancy {
    grid-area: pregnancy;
}
main.cow-modal > #cow-form .reproductive {
    grid-area: reproductive;
}
main.cow-modal > #cow-form h6.info {
    grid-area: info;
}

main.cow-modal > #cow-form h6.info {
    width: 110%;
    min-height: 2rem;
    height: -webkit-max-content;
    height: max-content;
    justify-self: center;
    background-color: #fff;
    color: black;
    font-size: 1.2rem;
    text-align: center;
    margin: 0;
}

main.cow-modal > #cow-form h6.info a {
    cursor: pointer;
    text-decoration: underline;
    color: var(--button-action-color);
}

main.cow-modal > #cow-form .input-group {
    display: grid;
    grid-template-areas:
        "input"
        "label";
    grid-template-rows: 2.6rem 1rem;
}
/* main.cow-modal > #cow-form .input-group.validate input,
main.cow-modal > #cow-form .input-group.validate.breed .custom-select{
    border: solid 1.8px var(--tag-logic-color);
} */
main.cow-modal > #cow-form .input-group span {
    font-weight: 300;
    color: var(--dashboard-text-color);
}
main.cow-modal > #cow-form .input-group.validate > .validation-wrapper > span {
    width: 100%;
    box-sizing: border-box;
}
main.cow-modal > #cow-form .input-group > input {
    background-color: var(--dashboard-background);
    background-blend-mode: overlay;
    background-position: 0.2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 0.4rem;
    border: solid 1.4px var(--dashboard-box-border);
    font-size: 1rem;
    color: var(--dashboard-text-color);
    outline: none;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
}

main.cow-modal > #cow-form .input-group.identity > input {
    text-transform: uppercase;
}
main.cow-modal > #cow-form .input-group.birth .DayPickerInput input,
main.cow-modal > #cow-form .input-group.calving .DayPickerInput input,
main.cow-modal > #cow-form .input-group.insemination .DayPickerInput input {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    outline: none;
}

/* left */
main.cow-modal > #cow-form .input-group.birth .DayPickerInput input,
main.cow-modal > #cow-form .input-group.calving .DayPickerInput input,
main.cow-modal > #cow-form .input-group.insemination .DayPickerInput input,
main.cow-modal > #cow-form .input-group.name input {
    border-radius: 0 0.4rem 0.4rem 0;
    border-left-color: var(--dashboard-widget-background);
}

main.cow-modal
    > #cow-form
    .input-group.insemination.validate
    .DayPickerInput
    input,
main.cow-modal > #cow-form .input-group.name.validate input {
    border-radius: 0 0.4rem 0 0;
}
main.cow-modal
    > #cow-form
    .input-group.insemination.validate
    .DayPickerInput
    input,
main.cow-modal > #cow-form .input-group.name.validate input {
    border-radius: 0 0.4rem 0 0;
}

/* right */
main.cow-modal > #cow-form .input-group.farm-number input,
main.cow-modal > #cow-form .input-group.lactation input,
main.cow-modal > #cow-form .input-group.identity input {
    border-radius: 0.4rem 0 0 0.4rem;
    border-right-color: var(--dashboard-widget-background);
}

main.cow-modal > #cow-form .input-group.farm-number.validate input,
main.cow-modal > #cow-form .input-group.lactation.validate input,
main.cow-modal > #cow-form .input-group.identity.validate input {
    border-radius: 0.4rem 0 0 0;
}

/* left & right */
main.cow-modal > #cow-form .input-group.birth .DayPickerInput input,
main.cow-modal > #cow-form .input-group.calving .DayPickerInput input {
    border-radius: 0 0 0 0;
    border-right-color: var(--dashboard-widget-background);
    border-left-color: var(--dashboard-widget-background);
}

/* span */

main.cow-modal > #cow-form .input-group.validate .DayPickerInput span,
main.cow-modal > #cow-form .input-group.validate span {
    border-radius: 0 0 0.4rem 0.4rem;
}

main.cow-modal > #cow-form input.left {
    border-radius: 0 0.4rem 0.4rem 0;
    border-left-color: var(--dashboard-widget-background);
}
main.cow-modal > #cow-form input.right {
    border-radius: 0.4rem 0 0 0.4rem;
    border-right-color: var(--dashboard-widget-background);
}
main.cow-modal > #cow-form input.left.right {
    border-radius: 0 0 0 0;
    border-right-color: var(--dashboard-widget-background);
}

main.cow-modal > #cow-form .input-group.breed .custom-select {
    border-radius: 0 0.4rem 0.4rem 0;
    border-left-color: var(--dashboard-widget-background);
    padding: 0;
}

main.cow-modal > #cow-form .input-group.pregnancy input {
    cursor: pointer;
    height: 2rem;
    width: 1.5rem;
    margin: 0 0.4rem 0.4rem 0;
    border: 1.4px solid var(--dashboard-box-border);
    -webkit-appearance:none;
    -webkit-transition:box-shadow 200ms;
    border-radius:0.4rem;
}

main.cow-modal > #cow-form .input-group.pregnancy input[type="checkbox"]:not(:disabled):hover {
    border-color:#007dc6;
 }

main.cow-modal > #cow-form .input-group.pregnancy input[type="checkbox"]:active:not(:disabled) {
    border-color:#007dc6;
}

main.cow-modal > #cow-form .input-group.pregnancy input[type="checkbox"]:focus {
    outline:none;
}

main.cow-modal > #cow-form .input-group.pregnancy input[type="checkbox"]:checked {
    border-color: var(--dashboard-box-border);
    background-color:#007dc6;
}

main.cow-modal > #cow-form .input-group.pregnancy input[type="checkbox"]:checked:before {
    content:'';
    display:block;
    width:0.5rem;
    height:1rem;
    border:solid #fff;
    border-width:0 2px 2px 0;
    -webkit-transform:rotate(45deg);
    transform:rotate(45deg);
    margin-top: 0.25rem;
    margin-left: -0.25rem;
}

main.cow-modal
    > #cow-form
    .input-group.breed
    .custom-select
    > div.custom-select__menu {
    margin: 0.1rem;
    margin-top: 0rem;
}
main.cow-modal > #cow-form .reproductive {
    grid-area: reproductive;
    font-size: 1.8rem;
    font-weight: 100;
}
main.cow-modal > #cow-form input:focus {
    border: solid 1px #2684ff;
}
main.cow-modal > #cow-form .input-group.birth .DayPickerInput input:focus {
    border-bottom: none;
}
main.cow-modal > #cow-form .input-group.calving .DayPickerInput input:focus,
main.cow-modal
    > #cow-form
    .input-group.insemination
    .DayPickerInput
    input:focus {
    border-top: none;
}
main.cow-modal > #cow-form .input-group.breed .custom-select:focus-within {
    border-bottom: none;
}

.DayPicker {
    font-size: 0.96rem;
  }
@media (max-width: 42rem){
    main.cow-modal{
        z-index: 501;
        max-width: 100%;
        max-height: 100%;
        border-radius: 0;
        grid-template-rows: 4rem 26rem 5rem;
    }
    main.cow-modal > .head > .close-button {
        margin-top: 0.3rem;
        margin-bottom: 0.3rem;
    }
    main.cow-modal > #cow-form {
        grid-template-areas:
            "farm-number name name name"
            "identity identity identity identity"
            "birth birth breed breed"
            "reproductive reproductive reproductive reproductive"
            "lactation calving calving calving"
            "insemination insemination insemination insemination"
            "pregnancy pregnancy . ."
            "info info info info";
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(3, 1fr) 3rem repeat(3, 1fr);
        grid-row-gap: 0;
    }
    main.cow-modal > #cow-form .input-group {
        height: 4.4rem;
    }
    main.cow-modal > #cow-form .input-group span {
        font-size: 0.7rem;
    }
    main.cow-modal > #cow-form .input-group.identity {
        width: 100%;
    }

    main.cow-modal > #cow-form .input-group input,
    main.cow-modal > #cow-form .input-group .custom-select {
        background: none;
        background-color: var(--dashboard-background);
        padding-right: 0.8rem;
        padding-left: 0.8rem;
        width: 100%;
        box-sizing: border-box;
    }
    main.cow-modal > #cow-form .input-group.birth .DayPickerInput input,
    main.cow-modal > #cow-form .input-group.calving .DayPickerInput input,
    main.cow-modal > #cow-form .input-group.insemination .DayPickerInput input {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
    }
    /* left */
    main.cow-modal > #cow-form .input-group.farm-number input,
    main.cow-modal > #cow-form .input-group.lactation input,
    main.cow-modal > #cow-form .input-group.birth .DayPickerInput input {
        border-radius: 0.4rem 0 0 0.4rem;
        border-right-color: var(--dashboard-widget-background);
        border-left-color: var(--dashboard-box-border);
    }
    /* right */
    main.cow-modal > #cow-form .input-group.name input,
    main.cow-modal > #cow-form .input-group.calving .DayPickerInput input {
        border-radius: 0 0.4rem 0.4rem 0;
        border-left-color: var(--dashboard-widget-background);
        border-right-color: var(--dashboard-box-border);
    }
    /* left & right */
    main.cow-modal > #cow-form .input-group.identity input,
    main.cow-modal > #cow-form .input-group.insemination .DayPickerInput input {
        border-radius: 0.4rem 0.4rem 0.4rem 0.4rem;
        border-left-color: var(--dashboard-box-border);
        border-right-color: var(--dashboard-box-border);
    }

    main.cow-modal > #cow-form .input-group .label {
        padding-left: 0.4rem;
    }
    main.cow-modal > #cow-form .input-group.lactation .label {
        padding-left: 0;
    }
    main.cow-modal > #cow-form h6.info {
        font-size: 0.9rem;
        width: 120%;
    }
    main.cow-modal > #cow-form .input-group input:focus {
        border: solid 1px #2684ff;
    }
    main.cow-modal
        > #cow-form
        .input-group.breed
        .custom-select
        > div.custom-select__menu {
        margin-top: 0.4rem;
    }
    main.cow-modal > #cow-form .input-group.birth .DayPickerInput input:focus,
    main.cow-modal > #cow-form .input-group.calving .DayPickerInput input:focus,
    main.cow-modal
        > #cow-form
        .input-group.insemination
        .DayPickerInput
        input:focus {
        border: solid 1px #2684ff;
        border-bottom: none;
    }
    .DayPicker {
        font-size: 0.7rem;
    }
    div.top .DayPickerInput-Overlay {
        border: 2px solid #bbb;
        border-top: none;
    }
}

@media (max-width: 42rem) and (max-height: 70rem) {
    main.cow-modal {
        height: 100%;
        width: 100%;
        top: 0;
        grid-template-rows: -webkit-max-content 7fr 5rem;
        grid-template-rows: max-content 7fr 5rem;
    }
    main.cow-modal > #cow-form {
        padding-top: 1rem;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(3, 3.6rem) 2rem repeat(3, 3.6rem);
        grid-row-gap: 0;
    }
    main.cow-modal > .head > .close-button {
        height: 2.8rem;
    }
    main.cow-modal > #cow-form .input-group {
        grid-template-rows: 2rem 0.5rem;
    }
    main.cow-modal > #cow-form .reproductive {
        font-size: 1.2rem;
        font-weight: 100;
    }
    main.cow-modal > #cow-form .input-group .label {
        padding-left: 0.4rem;
        font-size: 0.68rem;
    }
    main.cow-modal > #cow-form h6.info {
        font-size: 0.8rem;
    }
    main.cow-modal
        > #cow-form
        .input-group.breed
        .custom-select
        .custom-select__control {
        height: 1rem !important;
    }
    main.cow-modal
        > #cow-form
        .input-group.breed
        .custom-select
        .css-yk16xz-control {
        min-height: 0;
    }
    main.cow-modal > #cow-form .input-group.breed *:not(.label) {
        font-size: 0.8rem;
    }
    main.cow-modal > #cow-form .input-group .DayPickerInput {
        height: 2rem;
    }
    main.cow-modal
        > #cow-form
        .input-group
        .DayPickerInput
        .DayPickerInput-OverlayWrapper {
        margin-top: 0rem;
    }
    main.cow-modal > #cow-form h6.info {
        font-size: 0.8rem;
    }
    main.cow-modal button.button {
        font-size: 0.9rem;
    }
}

/* For iPads */
@media (min-width: 48rem) and (orientation: portrait),
        (min-width: 64rem) and (orientation: landscape) {
    main.cow-modal{
        max-width: 100%;
        grid-template-rows: 5rem 7fr 5rem;
    }
    main.cow-modal > #cow-form{
        grid-template-rows: 5rem 5rem 3rem 5rem auto;
    }
    main.cow-modal > .head {
        grid-area: head;
        display: grid;
        grid-template-areas:  "header close-button";
        grid-template-columns: auto 4.5rem;
    }
    main.cow-modal > .head > h6{
        padding-left: 1.5rem;
        font-size: 2.3rem;
    }
    main.cow-modal button.submit{
        font-size: 1.4rem;
        height: 3rem;
    }
    main.cow-modal > .head > .close-button {
        margin-bottom: 0;
        background-size: 3rem;
        height: 3rem;
        width: 3rem;
    }
    main.cow-modal > #cow-form .reproductive{
        font-size: 2rem;
    }
    main.cow-modal > #cow-form .input-group{
        grid-template-rows: 3.2rem 1rem;
    }
    main.cow-modal > #cow-form .input-group span{
        font-weight: 300;
        font-size: 1.2rem;
    }
    main.cow-modal > #cow-form .input-group input,
    main.cow-modal > #cow-form .input-group .custom-select{
        padding-right: .8rem;
        padding-left: .8rem;
        width: 100%;
        box-sizing: border-box;
        font-size: 1.3rem;
    }
    main.cow-modal > #cow-form h6.info{
        height: 2.1rem;
        font-size: 1.3rem;
    }
}

aside.modal {
    margin: auto;
    background-color: var(--dashboard-widget-background);
    padding: .5rem 1rem;
    border: solid 1rem var(--dashboard-background);
    border-top: none;
    position: fixed;
    z-index: 1;
    width: 100%;
    box-sizing: border-box;
    top: 0;
    min-height: 10rem;
    z-index: 999;
}

@media (min-width: 24rem) {
    aside.modal {
        width: 24rem;
        left: 50vw;
        right: 50vw;
        margin-left: -12rem;
        margin-right: -12rem;
        border-bottom-left-radius: 1.2rem;
        border-bottom-right-radius: 1.2rem;
    }
}

aside.modal::after {
    position: fixed;
    background-color: var(--dashboard-background);
    opacity: .8;
    width: 100vw;
    height: 100%;
    content: "\00a0";
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
}

aside.error.modal h2 {
    font-weight: 300;
    font-size: .8rem;
    letter-spacing: .1rem;
    text-align: center;
}

aside.error.modal p {
    text-align: center;
}

aside.error.modal a {
    text-decoration: underline;
    cursor: pointer;
}


aside.modal {
    margin: auto;
    background-color: var(--dashboard-widget-background);
    padding: .5rem 1rem;
    border: solid 1rem var(--dashboard-background);
    border-top: none;
    position: fixed;
    z-index: 1;
    width: 100%;
    box-sizing: border-box;
    top: 0;
    min-height: 10rem;
    z-index: 999;
}

@media (min-width: 24rem) {
    aside.modal {
        width: 24rem;
        left: 50vw;
        right: 50vw;
        margin-left: -12rem;
        margin-right: -12rem;
        border-bottom-left-radius: 1.2rem;
        border-bottom-right-radius: 1.2rem;
    }
}

aside.modal::after {
    position: fixed;
    background-color: var(--dashboard-background);
    opacity: .8;
    width: 100vw;
    height: 100%;
    content: "\00a0";
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
}

aside.error.modal h2 {
    font-weight: 300;
    font-size: .8rem;
    letter-spacing: .1rem;
    text-align: center;
}

aside.error.modal p {
    text-align: center;
}

aside.error.modal a {
    text-decoration: underline;
    cursor: pointer;
}


main.notification-settings {
    position: relative;
    margin: 0;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--dashboard-widget-background);
    z-index: 100;
    border-radius: 0;
    display: grid;
    overflow-y: auto;
    grid-gap: .5rem;
    padding: 1rem;
    grid-template-areas:"header close-button"
                        "explanation explanation"
                        "vacation-mode  vacation-mode"
                        "calving-due calving-due"
                        "imminent-calving imminent-calving"
                        "calving-overdue calving-overdue"
                        "fever-hypothermia fever-hypothermia";
    grid-template-columns: auto 8rem;
    grid-template-rows: 4.5rem 2.5rem 7.5rem 8rem 9rem 9rem 6rem;
    border-radius: 1rem 0 0 1rem;
    box-shadow: 0 0 0 150vmax rgba(0, 0, 0, .5)
}
main.notification-settings .close-button{
    grid-area: close-button;
    --close: url(/static/media/CloseButton.be369177.svg);
    background-image: var(--close);
    background-position: right;
    background-size: 2.8rem;
    background-repeat: no-repeat;
    margin-left: 5.2rem;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
    cursor: pointer;
}
main.notification-settings h1.header{
    grid-area: header;
    margin: 0;
    margin-top: .5rem;
    font-size: 2rem;
    font-weight: 300;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
main.notification-settings > div.explanation{
    grid-area: explanation;
    font-size: .9em;
    font-weight: 300;
}
main.notification-settings > div .buttons{
    grid-area: buttons;
    display: -webkit-flex;
    display: flex;
}
main.notification-settings > .vacation-mode{
    grid-area: vacation-mode;
    display: grid;
    grid-template-areas:    "vacation-header vacation-header" 
                            "vacation-button vacation-mode-explanation"
                            "vacation-button vacation-mode-explanation";
    grid-template-columns: 5rem 1fr;
    grid-template-rows: 4.5rem 1fr 1fr;
    grid-column-gap: 0.1rem;
 }
 main.notification-settings > .calving-due{
    grid-area: calving-due;
    display: grid;
    grid-template-areas:    "calving-due-header calving-due-header calving-due-header calving-due-header"
                            "buttons buttons buttons buttons"
                            "calving-due-explanation calving-due-explanation calving-due-explanation calving-due-explanation";
    grid-template-columns: 3.5rem 8.5rem 6rem auto;
    grid-template-rows: 4.5rem 1fr 1fr;
    grid-column-gap: 0.1rem;
    grid-row-gap: .5rem;
 }
 main.notification-settings > .imminent-calving{
    grid-area: imminent-calving;
    display: grid;
    grid-template-areas:    "imminent-calving-header imminent-calving-header imminent-calving-header imminent-calving-header"
                            "buttons buttons buttons buttons"
                            "imminent-calving-explanation imminent-calving-explanation imminent-calving-explanation imminent-calving-explanation";
    grid-template-columns: 3.5rem 8.5rem 6rem auto;
    grid-template-rows: 4.5rem 1fr 1fr;
    grid-column-gap: 0.1rem;
    grid-row-gap: .5rem;
 }
 main.notification-settings > .calving-overdue{
    grid-area: calving-overdue;
    display: grid;
    grid-template-areas:    "calving-overdue-header calving-overdue-header calving-overdue-header calving-overdue-header"
                            "buttons buttons buttons buttons"
                            "calving-overdue-explanation calving-overdue-explanation calving-overdue-explanation calving-overdue-explanation";
    grid-template-columns: 3.5rem 8.5rem 6rem auto;
    grid-template-rows: 4.5rem 1fr 1fr;
    grid-row-gap: .5rem;
    grid-column-gap: 0.1rem;
 }
 main.notification-settings > .fever-hypothermia{
    grid-area: fever-hypothermia;
    display: grid;
    grid-template-areas:    "fever-hypothermia-header fever-hypothermia-header fever-hypothermia-header fever-hypothermia-header"
                            "buttons buttons buttons buttons"
                            "fever-hypothermia-explanation fever-hypothermia-explanation fever-hypothermia-explanation fever-hypothermia-explanation";
    grid-template-columns: 3.5rem 8.5rem 6rem auto;
    grid-template-rows: 4.5rem 1fr 1fr;
    grid-column-gap: 0.1rem;
    grid-row-gap: .5rem;
 }
 main.notification-settings > .vacation-mode > .vacation-header{
    grid-area: vacation-header;
    font-size: 2rem;
    font-weight: 300;
     }
main.notification-settings > .vacation-mode > .vacation-button{ grid-area: vacation-button; }

main.notification-settings > .calving-due > .calving-due-header{
    font-size: 2rem;
    font-weight: 300;
    grid-area: calving-due-header;
 }
main.notification-settings > .imminent-calving > .imminent-calving-header{
    font-size: 2rem;
    font-weight: 300;
    grid-area: imminent-calving-header; 
}
main.notification-settings > .calving-overdue > .calving-overdue-header{ 
    font-size: 2rem;
    font-weight: 300;
    grid-area: calving-overdue-header; 
}
main.notification-settings > .fever-hypothermia > .fever-hypothermia-header{ 
    grid-area: fever-hypothermia-header;
    font-size: 2rem;
    font-weight: 300;
 }

main.notification-settings > .calving-due > .call{ grid-area: calving-due-call; }
main.notification-settings > .imminent-calving > .call{ grid-area: imminent-calving-call; }
main.notification-settings > .calving-overdue > .call{ grid-area: calving-overdue-call; }
main.notification-settings > .fever-hypothermia > .call{ grid-area: fever-hypothermia-call; }
main.notification-settings > .calving-due > .notifications{ grid-area: calving-due-notifications; }
main.notification-settings > .imminent-calving > .notifications{ grid-area: imminent-calving-notifications; }
main.notification-settings > .calving-overdue > .notifications{ grid-area: calving-overdue-notifications; }
main.notification-settings > .fever-hypothermia > .notifications{ grid-area: fever-hypothermia-notifications; }
main.notification-settings > .calving-due > .email{ grid-area: calving-due-email; }
main.notification-settings > .imminent-calving > .email{ grid-area: imminent-calving-email; }
main.notification-settings > .calving-overdue > .email{ grid-area: calving-overdue-email; }
main.notification-settings > .fever-hypothermia > .email{ grid-area: fever-hypothermia-email; }

main.notification-settings > .vacation-mode > .vacation-mode-explanation{ 
    grid-area: vacation-mode-explanation;
    font-size: 0.9rem;
    font-weight: 300;
 }
main.notification-settings > .calving-due > .calving-due-explanation{ 
    grid-area: calving-due-explanation;
    font-size: 0.9rem;
    font-weight: 300;
    justify-self: left;
 }
main.notification-settings > .imminent-calving > .imminent-calving-explanation{ 
    grid-area: imminent-calving-explanation;
    font-size: 0.9rem;
    font-weight: 300;
    justify-self: left;
 }
main.notification-settings > .calving-overdue > .calving-overdue-explanation{ 
    grid-area: calving-overdue-explanation;
    font-size: 0.9rem;
    font-weight: 300;
    justify-self: left;
 }
main.notification-settings > .fever-hypothermia > .fever-hypothermia-explanation{ 
    grid-area: fever-hypothermia-explanation;
    font-size: 0.9rem;
    font-weight: 300;
    justify-self: left; 
}


main.notification-settings > .vacation-mode > button{
    --vacation: url(/static/media/Sun.7a4ee721.svg);
    background-image: var(--vacation);
    background-position: top;
    background-size: 3rem;
    background-repeat: no-repeat;
    font-size: 0.8rem;
    font-weight: 300;
    padding: 0;
    padding-top: 3rem;
    margin: 0;
    width: 4rem;
    height: 4.5rem;
}
main.notification-settings .buttons  > .call{
    --phoneCall: url(/static/media/Phone.df4a9203.svg);
    background-image: var(--phoneCall);
    background-position: top;
    background-repeat: no-repeat;
    font-size: 0.8rem;
    font-weight: 300;
    padding: 0;
    padding-top: 1.6rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin: 0;
    width: -webkit-max-content;
    width: max-content;
    height: 3rem;
}
main.notification-settings .buttons  > .notifications {
    --notifications: url(/static/media/Warning.82cf904a.svg);
    background-image: var(--notifications);
    background-position: top;
    background-size: 2.3rem;
    background-repeat: no-repeat;
    font-size: 0.8rem;
    font-weight: 300;
    padding: 0;
    padding-top: 1.6rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin: 0;
    margin-left: 1rem;
    width: -webkit-max-content;
    width: max-content;
    height: 3rem;
}
main.notification-settings .buttons  > .email {
    --mails: url(/static/media/Envelope.780e673f.svg);
    background-image: var(--mails);
    background-position: top;
    background-repeat: no-repeat;
    font-size: 0.8rem;
    font-weight: 300;
    padding: 0;
    padding-top: 1.6rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin: 0;
    margin-left: 1rem;
    width: -webkit-max-content;
    width: max-content;
    height: 3rem;
}
main.notification-settings > div > .deactived,
main.notification-settings .buttons > .deactived {
    background-color: var(--dashboard-background);
    border: solid white .08rem;
}
main.notification-settings > div > .pending,
main.notification-settings .buttons > .pending {
    background-color: var(--dashboard-widget-background);
    border: dashed var( --checked-color) .08rem;
}
main.notification-settings > div > .disabled,
main.notification-settings .buttons  > .disabled {
    background-color: var(--dashboard-background);
    border: solid white .08rem;
    opacity: .5;
}

main.notification-settings::-webkit-scrollbar{
    display: none;
}
@media (max-width: 35rem) {
    main.notification-settings h1.header{
        -webkit-align-self: start;
                align-self: start;
        margin-top: 0;
    }
    main.notification-settings .close-button{
        margin: 0;
    }
    main.notification-settings{
        position: relative;
        top: 0;
        overflow-y: auto;
        grid-row-gap: 1.8rem;
        grid-template-areas:"header close-button"
                            "explanation explanation"
                            "vacation-mode  vacation-mode"
                            "calving-due calving-due"
                            "imminent-calving imminent-calving"
                            "calving-overdue calving-overdue"
                            "fever-hypothermia fever-hypothermia";
        grid-template-columns: auto 2.8rem;
        grid-template-rows: 2.7rem 2.5rem 5.3rem 6rem 6.5rem 7.5rem 6rem;
        transition: none;
    }
    main.notification-settings > .vacation-mode > .vacation-header{
        font-size: 1rem;
        font-weight: 500;
    }
    
    main.notification-settings > .calving-due > .calving-due-header{
        font-size: 1rem;
        font-weight: 500;
     }
    main.notification-settings > .imminent-calving > .imminent-calving-header{
        font-size: 1rem;
        font-weight: 500;
    }
    main.notification-settings > .calving-overdue > .calving-overdue-header{ 
        font-size: 1rem;
        font-weight: 500;
    }
    main.notification-settings > .fever-hypothermia > .fever-hypothermia-header{ 
        font-size: 1rem;
        font-weight: 500;
     }
     main.notification-settings > .vacation-mode{
        grid-template-rows: 2.5rem 1fr 1fr;
     }
    main.notification-settings > .calving-due{
        grid-template-columns: 3.5rem 8.5rem 6rem auto;
        grid-template-rows: 2.3rem 1fr 1fr;
     }
     main.notification-settings > .imminent-calving{
        grid-template-columns: 3.5rem 8.5rem 6rem auto;
        grid-template-rows: 2.3rem 1fr 1fr;
        justify-self: start;
     }
     main.notification-settings > .calving-overdue{
        grid-template-columns: 3.5rem 8.5rem 6rem auto;
        grid-template-rows: 2.3rem 1fr 1fr;
     }
     main.notification-settings > .fever-hypothermia{
        grid-template-columns: 3.5rem 8.5rem 6rem auto;
        grid-template-rows: 2.3rem 1fr 1fr;
        justify-self: start;
     }
     main.notification-settings::-webkit-scrollbar{
         display: none;
     }
}

/* For iPads */
@media (min-width: 48rem) and (orientation: portrait),
        (min-width: 64rem) and (orientation: landscape) {
    main.notification-settings{
        grid-row-gap: 2rem;
        grid-template-columns: auto 10rem;
        grid-template-rows: 4.5rem 1.5rem 8.5rem 10rem 11rem 11rem auto;
    }
    main.notification-settings .close-button{
        background-size: 3rem;
        width: 3rem;
        height: 3rem;
        margin-top: 1rem;
        margin-bottom: 0;
    }
    main.notification-settings h1.header{
        font-size: 2.3rem;
    }

    main.notification-settings > div.explanation{
        font-size: 1.3rem;
    }
    main.notification-settings > .vacation-mode{
        grid-template-columns: 6.5rem 1fr;
        grid-template-rows: 5rem 1fr 1fr;
     }
     main.notification-settings > .calving-due{
        grid-template-columns: 5rem 10.5rem 6rem auto;
        grid-template-rows: 4.5rem 1fr 1fr;
        grid-column-gap: 0.1rem;
        grid-row-gap: 1rem;
     }
     main.notification-settings > .imminent-calving{
        grid-template-columns: 5rem 10.5rem 6rem auto;
        grid-template-rows: 4.5rem 1fr 1fr;
        grid-column-gap: 0.1rem;
        grid-row-gap: 1rem;
     }
     main.notification-settings > .calving-overdue{
        grid-template-columns: 5rem 10.5rem 6rem auto;
        grid-template-rows: 4.5rem 1fr 1fr;
        grid-row-gap: 1rem;
     }
     main.notification-settings > .fever-hypothermia{
        grid-template-columns: 5rem 10.5rem 6rem auto;
        grid-template-rows: 4.5rem -webkit-max-content 3rem;
        grid-template-rows: 4.5rem max-content 3rem;
        grid-row-gap: 1rem;
     }
    main.notification-settings > .vacation-mode > .vacation-header,
    main.notification-settings > .calving-due > .calving-due-header,
    main.notification-settings > .imminent-calving > .imminent-calving-header,
    main.notification-settings > .calving-overdue > .calving-overdue-header,
    main.notification-settings > .fever-hypothermia > .fever-hypothermia-header{
        font-size: 2rem;
    }
    main.notification-settings > .vacation-mode > .vacation-mode-explanation,
    main.notification-settings > .calving-due > .calving-due-explanation,
    main.notification-settings > .imminent-calving > .imminent-calving-explanation,
    main.notification-settings > .calving-overdue > .calving-overdue-explanation,
    main.notification-settings > .fever-hypothermia > .fever-hypothermia-explanation{ 
        font-size: 1.2rem;
     }
    main.notification-settings > .vacation-mode > button{
        background-size: 3.5rem;
        font-size: 1rem;
        width: 5rem;
        height: 5.5rem;
    }
    main.notification-settings .buttons  > .call{
        font-size: 1rem;
        background-size: 2.5rem;
        font-weight: 300;
        padding-top: 1.6rem;
        height: 4.5rem;
    }
    main.notification-settings .buttons  > .notifications {
        background-size: 2.7rem;
        font-size: 1rem;
        height: 4.5rem;
    }
    main.notification-settings .buttons  > .email {
        font-size: 1rem;
        background-size: 2.3rem;
        padding-top: 1.6rem;
        height: 4.5rem;
    }
}
main.dashboard-settings {
    position: relative;
    margin: 0;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--dashboard-widget-background);
    z-index: 100;
    border-radius: 0;
    display: grid;
    grid-row-gap: 1rem;
    padding: 1rem;
    overflow-y: auto;
    grid-template-areas:    "header close-button"
                            "shortcut-buttons shortcut-buttons"
                            "auto-sort-buttons auto-sort-buttons";
    grid-template-columns: auto 7rem;
    grid-template-rows: 4rem 10rem 6rem;
    border-radius: 1rem 0 0 1rem;
    box-shadow: 0 0 0 150vmax rgba(0,0,0,.5)
}
main.dashboard-settings   .close-button{
    grid-area: close-button;
    --close: url(/static/media/CloseButton.be369177.svg);
    background-image: var(--close);
    background-position: right;
    background-size: 2.8rem;
    background-repeat: no-repeat;
    margin-left: 4rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    cursor: pointer;
}
main.dashboard-settings   h1.header{
    grid-area: header;
    margin: 0;
    margin-top: .5rem;
    font-size: 2rem;
    font-weight: 300;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
main.dashboard-settings  > .shortcut-buttons{
    grid-area: shortcut-buttons;
    display: grid;
    grid-template-areas:"shortcut-header"
                       "buttons1"
                       "shortcut-button-explanation";
    grid-template-rows: 4rem auto 2rem;
}
main.dashboard-settings  > .shortcut-buttons > .shortcut-header{
    grid-area: shortcut-header;
    font-weight: 400;
}
main.dashboard-settings  > .shortcut-buttons > .buttons{
    grid-area: buttons1;
    display: grid;
    grid-template-areas:"cowList switchFarm notifications";
    grid-template-columns: 5rem 6rem auto;
    border: solid 3px var(--dashboard-background);
    border-radius: .4rem .4rem .4rem .4rem;
    grid-column-gap: 1rem;
    margin: auto;
    margin-left: 0;
    padding: .15rem;
}
main.dashboard-settings  > .shortcut-buttons > .buttons > button{
    background-color: var(--dashboard-background);
    border: solid white .05rem;
}
main.dashboard-settings  > .shortcut-buttons > .buttons > button.selected{
    background-color: var(--button-action-color);
    border: none;
}
main.dashboard-settings  > .shortcut-buttons > .buttons > button.disabled{
    background-color: var(--dashboard-widget-background);
    opacity: .5;
    cursor: auto;
    border: solid var(--dashboard-background) .05rem;
}
main.dashboard-settings  > .shortcut-buttons > .buttons > .cow-list{
    grid-area: cowList;
    --cowList: url(/static/media/CowList.6d91d820.svg);
    background-image: var(--cowList);
    background-repeat: no-repeat;
    background-position: top;
    background-size: 3.5rem;
    padding-top: 3rem;
    width: 5.5rem;
    font-size: .9rem;
    height: 5rem;
    font-weight: 300;
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
main.dashboard-settings  > .shortcut-buttons > .buttons > .switch-farm{
    grid-area: switchFarm;
    --switch: url(/static/media/SwitchFarm.85891dd6.svg);
    background-image: var(--switch);
    background-repeat: no-repeat;
    background-position: top;
    background-size: 3.5rem;
    padding-top: 3rem;
    width: 6.5rem;
    font-size: .9rem;
    height: 5rem;
    font-weight: 300;
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
main.dashboard-settings  > .shortcut-buttons > .buttons > .notifications{
    grid-area: notifications;
    --notifications: url(/static/media/Warning.82cf904a.svg);
    background-image: var(--notifications);
    background-repeat: no-repeat;
    background-position: top;
    background-size: 3.5rem;
    padding-top: 3rem;
    width: 9rem;
    font-size: .9rem;
    height: 5rem;
    font-weight: 300;
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

main.dashboard-settings  > .shortcut-buttons > .shortcut-button-explanation{
    grid-area: shortcut-button-explanation;
    font-weight: 300;
}
main.dashboard-settings  > .auto-sort-buttons{
    grid-area: auto-sort-buttons;
    display: grid;
    grid-template-areas:"auto-sort-header"
                       "buttons2"
                       "auto-sorting-explanation"
                       "style-explanation";
    grid-template-rows: 4rem auto auto 1.7rem;
}

main.dashboard-settings  > .auto-sort-buttons > .buttons{
    grid-area: buttons2;
    display: grid;
    grid-template-areas:"tracking priority farmNumber events";
    grid-template-columns: 5rem 4.5rem 6rem auto;
    grid-column-gap: 1rem;
    border-radius: .4rem .4rem .4rem .4rem;
    border: solid 3px var(--dashboard-background);
    margin: auto;
    margin-left: 0;
    padding: .15rem;
}
main.dashboard-settings  > .auto-sort-buttons > .buttons > button{
    background-color: var(--dashboard-background);
    border: solid white .05rem;
}
main.dashboard-settings  > .auto-sort-buttons > .buttons > button.selected{
    background-color: var(--button-action-color);
    border: none;
}
main.dashboard-settings  > .auto-sort-buttons > .buttons > button.disabled{
    background-color: var(--dashboard-widget-background);
    border: solid 1px var(--dashboard-background);
}
main.dashboard-settings  > .auto-sort-buttons > .buttons > .tracking{
    grid-area: tracking;
    --tracking: url(/static/media/Tracking.15837496.svg);
    background-image: var(--tracking);
    background-repeat: no-repeat;
    background-position: top;
    background-size: 3.5rem;
    padding-top: 3rem;
    font-size: .9rem;
    width: 5.5rem;
    height: 5rem;
    font-weight: 300;
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
main.dashboard-settings  > .auto-sort-buttons > .buttons > .priority{
    grid-area: priority;
    --priority: url(/static/media/Warning.82cf904a.svg);
    background-image: var(--priority);
    background-repeat: no-repeat;
    background-position: top;
    background-size: 3.5rem;
    padding-top: 3rem;
    width: 5rem;
    font-size: .9rem;
    height: 5rem;
    font-weight: 300;
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
main.dashboard-settings  > .auto-sort-buttons > .buttons > .farm-number{
    grid-area: farmNumber;
    --farmNumber: url(/static/media/FarmNumber.9b7948fe.svg);
    background-image: var(--farmNumber);
    background-repeat: no-repeat;
    background-position: top;
    background-size: 3.5rem;
    padding-top: 3rem;
    width: 6.5rem;
    font-size: .8rem;
    height: 5rem;
    font-weight: 300;
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
main.dashboard-settings  > .auto-sort-buttons > .buttons > .events{
    grid-area: events;
    --events: url(/static/media/Events.4688d913.svg);
    background-image: var(--events);
    background-repeat: no-repeat;
    background-position: top;
    background-size: 3.5rem;
    padding-top: 3rem;
    width: 6rem;
    font-size: .9rem;
    height: 5rem;
    font-weight: 300;
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

main.dashboard-settings  > .auto-sort-buttons > .auto-sort-header{
    grid-area: auto-sort-header;
    font-weight: 400;
}
main.dashboard-settings  > .auto-sort-buttons > .auto-sorting-explanation{
    grid-area: auto-sorting-explanation;
}
main.dashboard-settings  > .auto-sort-buttons > .style-explanation{
    grid-area: style-explanation;
}
main.dashboard-settings  > .auto-sort-buttons > label{
    font-weight: 300;
}
main.dashboard-settings::-webkit-scrollbar {
    display: none;
}
@media (max-width: 35rem) {
    main.dashboard-settings h1.header{
        -webkit-align-self: start;
                align-self: start;
        margin-top: 0;
        font-size: 2rem;
    }
    main.dashboard-settings .close-button{
        margin: 0;
    }
    main.dashboard-settings {
        position: relative;
        top: 0;
        overflow-y: auto;
        grid-row-gap: 2rem;
        grid-template-areas:    "header close-button"
                                "shortcut-buttons shortcut-buttons"
                                "auto-sort-buttons auto-sort-buttons";
        grid-template-columns: auto 3rem;
        grid-template-rows: 2.7rem 10rem 6rem;
        transition: none;
    }
    main.dashboard-settings  > .shortcut-buttons{
        grid-template-rows: 4rem auto auto;
    }
    main.dashboard-settings  > .auto-sort-buttons{
        grid-template-rows: 4rem auto auto;
    }
    main.dashboard-settings  > .shortcut-buttons > .buttons{
        grid-template-columns: 4.3rem 5.3rem auto;
    }
    main.dashboard-settings  > .auto-sort-buttons > .buttons{
        grid-template-columns: 3.8rem 3.3rem 5.8rem auto;
    }
    main.dashboard-settings  > .auto-sort-buttons > .buttons > .tracking{
        background-size: 3rem;
        width: 4.5rem;
        font-size: .8rem;
        font-weight: 300;
    }
    main.dashboard-settings  > .auto-sort-buttons > .buttons > .priority{
        background-size: 3rem;
        width: 4rem;
        font-size: .8rem;
        font-weight: 300;
    }
    main.dashboard-settings  > .auto-sort-buttons > .buttons > .farm-number{
        background-size: 3rem;
        width: 6.5rem;
        font-size: .8rem;
        font-weight: 300;
    }
    main.dashboard-settings  > .auto-sort-buttons > .buttons > .events{
        background-size: 3rem;
        width: 5rem;
        font-size: .8rem;
        font-weight: 300;
    }
    main.dashboard-settings  > .shortcut-buttons > .buttons > .cow-list{
        background-size: 3rem;
        width: 5rem;
        height: 4.5rem;
        font-size: .8rem;
        font-weight: 300;
    }
    main.dashboard-settings  > .shortcut-buttons > .buttons > .switch-farm{
        background-size: 3rem;
        width: 6rem;
        height: 4.5rem;
        font-size: .8rem;
        font-weight: 300;
    }
    main.dashboard-settings  > .shortcut-buttons > .buttons > .notifications{
        background-size: 3rem;
        width: 8rem;
        height: 4.5rem;
        font-size: .8rem;
        font-weight: 300;
    }
}

/* For iPads */
@media (min-width: 48rem) and (orientation: portrait),
        (min-width: 64rem) and (orientation: landscape) {
    main.dashboard-settings {
        grid-template-rows: 4rem 14rem 6rem;
    }
    main.dashboard-settings   .close-button{
        background-size: 3rem;
        width: 3rem;
        height: 3rem;
        margin-left: 2.8rem;
        margin-top: 1rem;
        margin-bottom: 0;
    }
    main.dashboard-settings  > .auto-sort-buttons{
        grid-template-rows: 4rem auto 2.5rem 1.7rem;
    }
    main.dashboard-settings  > .shortcut-buttons > .buttons{
        grid-template-columns: 6rem 6rem auto;
    }
    main.dashboard-settings  > .auto-sort-buttons > .buttons{
        grid-template-columns: 6.5rem 5.5rem 7rem auto;
    }
    main.dashboard-settings  h1.header{
        font-size: 2.3rem;
    }
    main.dashboard-settings  > .shortcut-buttons > .shortcut-header,
    main.dashboard-settings  > .auto-sort-buttons > .auto-sort-header{
        font-size: 1.5rem;
    }
    main.dashboard-settings  > .auto-sort-buttons > .style-explanation,
    main.dashboard-settings  > .auto-sort-buttons > .auto-sorting-explanation,
    main.dashboard-settings  > .shortcut-buttons > .shortcut-button-explanation{
        font-size: 1.2rem;
    }
    main.dashboard-settings  > .shortcut-buttons > .buttons > .cow-list,
    main.dashboard-settings  > .shortcut-buttons > .buttons > .switch-farm,
    main.dashboard-settings  > .shortcut-buttons > .buttons > .notifications,
    main.dashboard-settings  > .auto-sort-buttons > .buttons > .tracking,
    main.dashboard-settings  > .auto-sort-buttons > .buttons > .priority,
    main.dashboard-settings  > .auto-sort-buttons > .buttons > .farm-number,
    main.dashboard-settings  > .auto-sort-buttons > .buttons > .events{
        background-size: 4rem;
        font-size: 1.2rem;
        height: 7rem;
    }
    main.dashboard-settings  > .shortcut-buttons > .buttons > .cow-list,
    main.dashboard-settings  > .shortcut-buttons > .buttons > .switch-farm{
        width: 6.5rem;
    }
    main.dashboard-settings  > .shortcut-buttons > .buttons > .notifications{
        width: 9rem;
    }
    main.dashboard-settings  > .auto-sort-buttons > .buttons > .tracking,
    main.dashboard-settings  > .auto-sort-buttons > .buttons > .events{
        width: 7rem;
    }
    main.dashboard-settings  > .auto-sort-buttons > .buttons > .priority{
        width: 6rem;
    }
    main.dashboard-settings  > .auto-sort-buttons > .buttons > .farm-number{
        width: 7.5rem;
    }
}
main.switch-farm {
    position: relative;
    margin: 0;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--dashboard-widget-background);
    z-index: 100;
    border-radius: 0;
    display: grid;
    grid-row-gap: 2rem;
    padding-left: 0;
    grid-template-areas:
        "header close-button"
        "farms farms";
    grid-template-columns: auto 4rem;
    grid-template-rows: 3rem 1fr;
    border-radius: 1rem 0 0 1rem;
    box-shadow: 0 0 0 150vmax rgba(0, 0, 0, 0.5);
}
main.switch-farm .close-button {
    grid-area: close-button;
    --close: url(/static/media/CloseButton.be369177.svg);
    background-image: var(--close);
    background-position: right;
    background-size: 2.8rem;
    background-repeat: no-repeat;
    margin: 0;
    padding: 0;
    margin-top: 1rem;
    padding-top: 2.8rem;
    margin-right: 1rem;
    margin-left: 0.2rem;
    cursor: pointer;
}
main.switch-farm h1.header {
    grid-area: header;
    margin: 0;
    align-self: start;
    font-size: 2rem;
    padding-top: 1rem;
    padding-left: 1rem;
    font-weight: 300;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
main.switch-farm > .farms {
    grid-area: farms;
}
main.switch-farm > .farms > .farm-list {
    grid-area: farm-list;
    padding: 0;
    margin: 0;
    border-top: solid 4px var(--dashboard-background);
    border-bottom: solid 4px var(--dashboard-background);
    font-size: 0.7rem;
    font-weight: 300;
    overflow-y: auto;
    height: 38.5rem;
    border-radius: 0;
}
li.farm {
    display: grid;
    grid-template-areas: "listItem";
    grid-template-columns: 1fr;
    font-size: 1rem;
    font-weight: 300;
    padding-left: 1.5rem;
    border-right: solid 0.6rem var(--dashboard-widget-background);
    border-bottom: solid 1px var(--dashboard-background);
}
li.farm a {
    grid-area: listItem;
    display: grid;
    grid-template-areas:
        "problem-count farm-name-location"
        "problem-count total-cow"
        "problem-count last-visit";
    grid-template-columns: 4rem auto;
    grid-template-rows: 1.5rem 1.5rem 3.5rem;
    color: var(--dashboard-text-color);
    text-decoration: none;
    opacity: 1;
}
li.farm.selected {
    border-right: solid 0.6rem var(--checked-color);
}
li.farm:hover a {
    opacity: 1;
}
li.farm a h3 {
    color: var(--dashboard-text-color);
    text-decoration: none;
    font-size: 0.8rem;
    font-weight: 300;
}
li.farm a h3 strong {
    font-weight: 400;
    font-size: 0.9rem;
}
li.farm a .farm-name-location {
    grid-area: farm-name-location;
}
li.farm a .total-cow {
    grid-area: total-cow;
}
li.farm a .last-visit {
    grid-area: last-visit;
}
li.farm a .problem-count {
    grid-area: problem-count;
    background-image: var(--dead-cow);
    background-position: center;
    width: 3rem;
    height: 3rem;
    text-align: center;
    display: inline-block;
    border-radius: 0.2em;
    align-self: center;
    font-size: 2rem;
    font-weight: 400;
}
li.farm a .problem-count.stable {
    background-image: var(--good-cow);
}
li.farm a .problem-count.error {
    background-image: var(--bad-cow);
}
main.switch-farm > .farms > .farm-list::-webkit-scrollbar {
    display: none;
}
main.switch-farm::-webkit-scrollbar {
    display: none;
}
@media (max-width: 35rem) {
    main.switch-farm h1.header {
        -webkit-align-self: start;
                align-self: start;
    }
    main.switch-farm {
        position: relative;
        top: 0;
        overflow-y: auto;
        grid-template-areas:
            "header close-button"
            "farms farms";
        grid-template-columns: auto 4rem;
        grid-template-rows: 3rem 1fr;
        transition: none;
    }
    main.switch-farm > .farms > .farm-list {
        height: 30rem;
        min-height: 99%;
        border-radius: 0;
    }
}

/* For iPads */
@media (min-width: 48rem) and (orientation: portrait),
    (min-width: 64rem) and (orientation: landscape) {
    main.switch-farm {
        grid-template-columns: auto 4.5rem;
        grid-template-rows: 3.5rem 1fr;
        border-radius: 0;
        box-shadow: 0 0 0 150vmax rgba(0, 0, 0, 0.5);
    }
    main.switch-farm .close-button {
        background-size: 3rem;
        margin-top: 1.5rem;
        padding-top: 3rem;
        margin-right: 1.4rem;
        margin-left: 0rem;
    }
    main.switch-farm h1.header {
        font-size: 2.3rem;
    }
    main.switch-farm > .farms > .farm-list {
        min-height: 99%;
    }
    li.farm a {
        grid-template-rows: 1.5rem 1.5rem 4.5rem;
    }
    li.farm a h3 {
        font-size: 1.1rem;
    }
    li.farm a h3 strong {
        font-size: 1.2rem;
    }
}

main.sensor-management {
    position: relative;
    max-width: 100%;
    margin: 0;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--dashboard-widget-background);
    z-index: 100;
    border-radius: 0;
    display: grid;
    grid-row-gap: 1rem;
    padding-top: 1rem;
    grid-template-areas:
        "header close-button"
        "sensors sensors";
    grid-template-rows: 4rem 4rem;
    grid-template-columns: auto 4rem;
    border-radius: 1rem 0 0 1rem;
    box-shadow: 0 0 0 150vmax rgba(0, 0, 0, 0.5);
}
main.sensor-management .close-button {
    grid-area: close-button;
    --close: url(/static/media/CloseButton.be369177.svg);
    background-image: var(--close);
    background-position: right;
    background-size: 2.8rem;
    background-repeat: no-repeat;
    margin: 0;
    padding: 0;
    margin-right: 1rem;
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
    margin-left: 0.2rem;
    cursor: pointer;
}
main.sensor-management h1.header {
    grid-area: header;
    margin: 0;
    padding-top: 0.5rem;
    padding-left: 1rem;
    font-size: 2rem;
    font-weight: 300;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
main.sensor-management > .sensors {
    grid-area: sensors;
}
main.sensor-management > .sensors > .sensor-list {
    grid-area: sensor-list;
    background-color: var(--dashboard-widget-background);
    border-radius: 0;
    padding: 0;
    margin: 0;
    border-top: solid 4px var(--dashboard-background);
    border-bottom: solid 4px var(--dashboard-background);
    font-size: 0.7rem;
    font-weight: 300;
    overflow-y: auto;
    height: 37.5rem;
}
li.sensor {
    display: grid;
    grid-template-areas:
        "device sensorHeader sensorHeader"
        "device tags tags"
        "device tags tags";
    grid-template-columns: 4rem auto 3rem;
    grid-template-rows: 2.2rem 2rem 1.8rem;
    font-size: 1rem;
    font-weight: 300;
    padding-left: 0.6rem;
    border-bottom: solid 1px var(--dashboard-background);
}
li.sensor .sensor-id-type {
    grid-area: sensorHeader;
    margin: 0;
    margin-top: 0.5rem;
    color: var(--dashboard-text-color);
    text-decoration: none;
    font-size: 1rem;
    font-weight: 300;
}
li.sensor .sensor-id-type .session-cow {
    font-style: italic;
    opacity: 0.8;
}
li.sensor h3 strong {
    font-weight: 400;
    font-size: 1.3rem;
    margin-right: 0.3rem;
}

li.sensor .device {
    grid-area: device;

    background-position: center;
    width: 3rem;
    height: 3rem;
    text-align: center;
    border-radius: 0.2em;
    align-self: center;
    font-size: 2rem;
    font-weight: 400;
}
li.sensor .device.eartag {
    background-image: url(/static/media/EarTag.a4d09280.svg);
}
li.sensor .device.supervisor {
    background-image: url(/static/media/RepeaterSupervisor.86014434.svg);
    background-size: 2.3rem;
    background-repeat: no-repeat;
}
li.sensor .device.repeater {
    background-image: url(/static/media/RepeaterSupervisor.86014434.svg);
    background-size: 2.3rem;
    background-repeat: no-repeat;
}
li.sensor .device.tsens {
    background-image: url(/static/media/Tsens.c3ad9573.svg);
    background-size: 2.3rem;
    background-repeat: no-repeat;
}
li.sensor .tags {
    padding: 0;
    margin: 0;
    grid-area: tags;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column wrap;
            flex-flow: column wrap;
}
li.sensor .tags li {
    list-style: none;
    font-size: 0.7rem;
    font-weight: 300;
}
li.sensor .tags li strong {
    font-size: 1rem;
    font-weight: 400;
}
li.sensor .tags li::before {
    width: 1em;
    height: 1em;
    content: "\00a0";
    display: inline-block;
    margin-right: 0.3rem;
    margin-bottom: 0.4rem;
    /*For Safari flushing problem*/
    background-image: linear-gradient(180deg, #35505a 0%, #556970 100%);
    border-radius: 0.2em;
    vertical-align: middle;
    font-size: 1rem;
    font-weight: 300;
}

li.sensor .tags li.active::before,
li.sensor .tags li.stable::before {
    background-image: linear-gradient(180deg, #629f26 0%, #146c3c 100%);
}

li.sensor .tags li.fault::before,
li.sensor .tags li.network-problem::before,
li.sensor .tags li.unstable::before {
    background-image: linear-gradient(180deg, #f3492c 0%, #bc4040 100%);
}
li.sensor .tags li.low-battery::before {
    background-image: linear-gradient(180deg, #f0960e 0%, #6c5914 100%);
}

main.sensor-management > .sensors > .sensor-list::-webkit-scrollbar {
    display: none;
}
main.sensor-management::-webkit-scrollbar {
    display: none;
}
@media (max-width: 35rem) {
    main.sensor-management h1.header {
        -webkit-align-self: start;
                align-self: start;
        padding-top: 1rem;
        padding-left: 1rem;
        font-size: 2rem;
    }
    main.sensor-management .close-button {
        margin: 0;
        padding: 0;
        padding-top: 3rem;
        margin-top: 1rem;
        margin-right: 1rem;
        margin-left: 0.2rem;
    }
    main.sensor-management {
        position: relative;
        top: 0;
        bottom: 0;
        margin-left: 0;
        grid-row-gap: 2rem;
        border-radius: 0;
        padding: 0;
        overflow-y: auto;
        grid-template-areas:
            "header close-button"
            "sensors sensors";
        grid-template-columns: auto 4rem;
        grid-template-rows: 3rem 1fr;
        transition: none;
    }
    main.sensor-management > .sensors > .sensor-list {
        min-height: 99%;
        border-radius: 0;
    }
}

/* For iPads */
@media (min-width: 48rem) and (orientation: portrait),
    (min-width: 64rem) and (orientation: landscape) {
    main.sensor-management {
        grid-template-rows: 5rem 1fr;
        grid-template-columns: auto 5rem;
    }
    main.sensor-management .close-button {
        background-size: 3rem;
        margin-right: 1.2rem;
        margin-left: 0.7rem;
    }
    main.sensor-management h1.header {
        font-size: 2.3rem;
    }
    main.sensor-management > .sensors > .sensor-list {
        width: 100%;
        min-height: 99%;
    }
    li.sensor {
        grid-template-columns: 4rem auto 3rem;
        grid-template-rows: 3rem 3rem 2.6rem;
    }
    li.sensor .sensor-id-type {
        font-size: 1.4rem;
    }
    li.sensor h3 strong {
        font-weight: 400;
        font-size: 1.6rem;
    }
    li.sensor .tags {
        grid-template-rows: 2rem;
        grid-row-gap: 0.3rem;
    }
    li.sensor .tags li strong {
        font-size: 1.4rem;
        font-weight: 400;
    }
    li.sensor .tags li::before {
        width: 1.4em;
        height: 1.4em;
        margin-right: 0.5rem;
        margin-bottom: 0.8rem;
    }
}

main.farm-settings {
    position: relative;
    margin: 0;
    box-sizing: border-box;
    overflow-y: auto;
    background-color: var(--dashboard-widget-background);
    z-index: 100;
    border-radius: 0;
    display: grid;
    grid-row-gap: 1.5rem;
    grid-template-areas:"header close-button"
                       "farm-name-box farm-name-box"
                       "users users";
    grid-template-rows: 4.2rem 3rem 1fr;
    grid-template-columns: auto 4rem;
    border-radius: 1rem 0 0 1rem;
    box-shadow: 0 0 0 150vmax rgba(0,0,0,.5)
}
main.farm-settings .close-button{
    grid-area: close-button;
    --close: url(/static/media/CloseButton.be369177.svg);
    background-image: var(--close);
    background-position: right;
    background-size: 2.8rem;
    background-repeat: no-repeat;
    margin-bottom: max-content;
    margin-top: 1rem;
    margin-right: 1rem;
    margin-left: .2rem;
    cursor: pointer;
}
main.farm-settings button.disabled{
    opacity: .5;
}
main.farm-settings h1.header{
    grid-area: header;
    margin: 0;
    font-size: 2.5rem;
    font-weight: 300;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: 1rem;
    padding-left: 1rem;
}
main.farm-settings > .farm-name-box {
    grid-area: farm-name-box;
    display: grid;
    grid-template-areas: "farm-name farmName-button";
    grid-template-columns: 1fr auto;
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
}
main.farm-settings > .users > .invite-new-user{
    grid-area: invite-new-user;
    display: grid;
    grid-template-areas: "new-user invite-button";
    grid-template-columns: 1fr auto;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    align-self: end;
}

main.farm-settings > .farm-name-box > .input-group.farm-name{
    grid-area: farm-name;
    display: grid;
    grid-template-areas: "input"
                         "label";
    grid-template-rows: 2.3rem 1rem;
}
main.farm-settings > .users > .invite-new-user > .input-group.invite-user {
    grid-area: new-user;
    display: grid;
    grid-template-areas: "input"
                         "label";
    grid-template-rows: 2.3rem 1rem;
}

main.farm-settings > .farm-name-box > .input-group.farm-name input,
main.farm-settings > .users > .invite-new-user > .input-group.invite-user input{
    background-color: var(--dashboard-background);
    border-radius: .4rem;
    border: solid 1.4px rgb(224, 224, 224, .7);
    font-size: 1.5rem;
    padding-left: 0.5rem;
    color: var(--dashboard-text-color);
    width: 100%;
    height: 2.3rem;
    box-sizing: border-box;
    outline: none;
 }
 main.farm-settings > .users > .invite-new-user > .input-group.invite-user input{
     font-size: 1.1rem;
 }
 main.farm-settings > .farm-name-box > .input-group.farm-name.validate input,
 main.farm-settings > .users > .invite-new-user > .input-group.invite-user.validate input{
    border-radius: .4rem 0 0 0;
}
 main.farm-settings > .farm-name-box > button {
    grid-area: farmName-button;
    padding: 0;
    margin: 0;
    --arrow: url(/static/media/rightArrow.ec01246a.svg);
    background-image: var(--arrow);
    background-color: var(--dashboard-background);
    border: solid 1.4px rgb(224, 224, 224, .7);
    background-position: center;
    background-repeat: no-repeat;
    width: 2.5rem;
    height: 2.3rem;
}
main.farm-settings > .farm-name-box > button.done,
main.farm-settings > .users > .invite-new-user > button.done {
    background-color: #629F26;
    border-color: var(--button-action-color);
}
main.farm-settings > .farm-name-box > button.pending,
main.farm-settings > .users > .invite-new-user > button.pending {
    background-color: var(--dashboard-widget-background);
    border: dashed 1.4px var(--button-action-color);
}
main.farm-settings > .farm-name-box > button.failed,
main.farm-settings > .users > .invite-new-user > button.failed {
    border: solid 1.4px #d30;
}
main.farm-settings > .users > .invite-new-user > .input-group.invite-user.validate{
    font-size: 0.9rem;
    font-weight: 300;
    color: var(--dashboard-text-color);
    width: 100%;
    box-sizing: border-box;
}
main.farm-settings > .users{
    grid-area: users;
    display: grid;
    grid-template-areas: "users-header"
                            "user-list"
                            "invite-new-user";
    grid-template-rows: 4rem auto 4rem;
    grid-row-gap: 1rem;
}
main.farm-settings > .users > .users-header{
    grid-area: users-header;
    margin: 0;
    font-size: 3rem;
    font-weight: 200;
    padding-left: 1rem;
}
main.farm-settings > .users > .user-list{
    grid-area: user-list;
    padding: 0;
    margin: 0;
    border-top: solid 4px var(--dashboard-background);
    border-bottom: solid 4px var(--dashboard-background);
    font-size: .7rem;
    font-weight: 300;
    overflow: auto;
    height: 23rem;
}
li.user{
    display: grid;
    position: relative;
    grid-template-areas:
        "name-surname name-surname name-surname"
        "email-phone email-phone email-phone"
        "owner-user owner-user owner-user";
    grid-template-columns: 6rem 7rem 7rem;
    grid-template-rows: 2rem 1.5rem 3rem;
    font-weight: 300;

    padding-left: 4rem;
    background-repeat: no-repeat;
    background-size: 3rem;
    background-color: var(--dashboard-widget-background);
    background-position: left;
    border-bottom: solid 1px var(--dashboard-background);
}
li.user.will-call{
    background-image: url(/static/media/Phone.df4a9203.svg);
}
li.user .name-surname{
    grid-area: name-surname;
    font-size: 1.5rem;
    width: -webkit-max-content;
    width: max-content;
    font-weight: 300;
    pointer-events: none;
}
li.user .email-phone{
    grid-area: email-phone;
    font-size: .9rem;
    font-weight: 300;
    width: -webkit-max-content;
    width: max-content;
    opacity: .7;
    pointer-events: none;
}
li.user .showing-owner-user{
    grid-area: owner-user;
    font-size: .8rem;
    width: -webkit-max-content;
    width: max-content;
    font-weight: 300;
    opacity: .7;
    pointer-events: none;
}
li.user-options{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    grid-column-gap: .5rem;
    font-weight: 300;
    margin-right: 55.1%;
    height: 5.2rem;
    border-right: solid 3px var(--dashboard-background);
    min-width: 10rem;
}
li.user-options * {
    margin: 0;
    padding: 0;
    margin-top: .4rem;
    padding-top: 2.3rem;
    height: 4.5rem;
    font-weight: 200;
    list-style: none;
    font-size: .9rem;
    background-size: 3rem;
    background-position: top;
    background-repeat: no-repeat;
    background-color: var(--dashboard-background);
    border: solid 1.4px rgb(224, 224, 224, .7);
    width: 100%;
}
li.user-options > .removing {
    --remove: url(/static/media/Cross.1d0dc4e8.svg);
    background-image: var(--remove);
    margin-left: .3rem;
    margin-right: .3rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
li.user-options > .call-redirect {
    background-image: url(/static/media/Phone.df4a9203.svg);
    margin-left: .3rem;
    margin-right: .3rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
main.farm-settings > .users > .invite-new-user > button {
    grid-area: invite-button;
    padding: 0;
    margin: 0;
    --userEmail: url(/static/media/Envelope.780e673f.svg);
    background-image: var(--userEmail);
    background-color: var(--dashboard-background);
    border: solid 1.4px rgb(224, 224, 224, .7);
    background-position: center;
    background-repeat: no-repeat;
    width: 2.5rem;
    height: 2.3rem;
}

main.farm-settings > .users > .invite-new-user > button,
main.farm-settings > .farm-name-box > button{
    border-radius: 0 .4rem .4rem 0;
    border-left-color: var(--dashboard-background);
}
main.farm-settings > .users > .invite-new-user > .input-group.invite-user input,
main.farm-settings > .farm-name-box > .input-group.farm-name input{
    border-radius: .4rem 0 0 .4rem;
    border-right-color: var(--dashboard-background);
}
main.farm-settings > .users > .invite-new-user > .input-group.invite-user span,
main.farm-settings > .farm-name-box > .input-group.farm-name span {
    font-size: 0.9rem;
    font-weight: 300;
    color: var(--dashboard-text-color);
    width: 100%;
    box-sizing: border-box;
    opacity: .6;
}
main.farm-settings > .users > .user-list::-webkit-scrollbar {
    display: none;
}
main.farm-settings::-webkit-scrollbar{
    display: none;
}

@media (max-width: 35rem) {
    main.farm-settings h1.header{
        -webkit-align-self: start;
                align-self: start;
        font-size: 2rem;
    }
    main.farm-settings .close-button{
        padding-top: 3rem;
    }
    main.farm-settings{
        position: relative;
        top: 0;
        overflow-y: auto;
        grid-row-gap: 2.5rem;
        grid-template-areas:"header close-button"
                            "farm-name-box farm-name-box"
                            "users users";
        grid-template-columns: auto 4rem;
        grid-template-rows: 3rem 3rem 1fr;
        transition: none;
    }
    main.farm-settings.open{
        -webkit-transform: none;
                transform: none;
    }
     main.farm-settings::-webkit-scrollbar{
         display: none;
     }
     main.farm-settings > .users{
        grid-template-rows: 4rem auto 1rem;
        grid-row-gap: 0rem;
    }
    main.farm-settings > .users > .users-header{
        font-size: 2rem;
        font-weight: 300;
    }
    main.farm-settings > .users > .user-list{
        height: 25rem;
        max-height: 80%;
        min-width: 100%;
    }
    li.user{
        grid-template-columns: 6rem 6rem auto;
        grid-template-rows: 2rem 1.5rem auto;
        height: 5.5rem;
    }
    li.user * {
        margin-top: .4rem;
    }
}


/* For iPads */
@media (min-width: 48rem) and (orientation: portrait),
        (min-width: 64rem) and (orientation: landscape) {
    main.farm-settings{
        position: relative;
        top: 0;
        overflow-y: auto;
        grid-row-gap: 2.5rem;
        grid-template-areas:"header close-button"
                            "farm-name-box farm-name-box"
                            "users users";
        grid-template-columns: auto 5rem;
        grid-template-rows: 3rem 4rem 1fr;
        transition: none;
    }
    main.farm-settings.open{
        -webkit-transform: none;
                transform: none;
    }
     main.farm-settings::-webkit-scrollbar{
         display: none;
     }
    main.farm-settings h1.header{
        -webkit-align-self: start;
                align-self: start;
        font-size: 2.3rem;
    }
    main.farm-settings .close-button{
        background-size: 3rem;
        width: 3rem;
        height: 3rem;
        margin-top: 1.5rem;
        margin-left: 0;
    }
    main.farm-settings > .users > .users-header{
        font-size: 2rem;
        font-weight: 300;
    }

    main.farm-settings > .farm-name-box > .input-group.farm-name,
    main.farm-settings > .users > .invite-new-user > .input-group.invite-user{
        grid-template-rows: 2.8rem 2rem;
    }
    main.farm-settings > .farm-name-box > button,
    main.farm-settings > .users > .invite-new-user > button {
        width: 4rem;
        height: 2.8rem;
    }
    main.farm-settings > .farm-name-box > button {
        background-size: .7rem;
    }
    main.farm-settings > .users > .invite-new-user > button {
        background-size: 2.6rem;
    }
    main.farm-settings > .users{
        grid-template-rows: 3.5rem auto 3rem;
        grid-row-gap: 0rem;
    }
    main.farm-settings > .farm-name-box > .input-group.farm-name input,
    main.farm-settings > .users > .invite-new-user > .input-group.invite-user input{
        font-size: 1.6rem;
        height: 2.8rem;
    }
    main.farm-settings > .farm-name-box > .input-group.farm-name span,
    main.farm-settings > .users > .invite-new-user > .input-group.invite-user span{
        font-size: 1.3rem;
    }
    main.farm-settings > .users > .user-list{
        height: 40rem;
        max-height: 90%;
        min-width: 100%;
    }
    li.user{
        grid-template-columns: 6rem 6rem auto;
        grid-template-rows: 2.5rem 2rem auto;
        height: 7rem;
        background-size: 3.5rem;
    }
    li.user * {
        margin-top: .4rem;
    }
    li.user .name-surname{
        font-size: 1.8rem;
    }
    li.user .email-phone{
        font-size: 1.2rem;
    }
    li.user .showing-owner-user{
        font-size: 1.2rem;
    }
    li.user-options{
        height: 6.7rem;
    }
    li.user-options * {
        padding-top: 3.3rem;
        height: 6rem;
        font-size: 1.2rem;
        background-size: 3.5rem;
    }
}
@media (min-width: 64rem) and (orientation: landscape) {
    main.farm-settings > .users > .user-list{
        height: 30rem;
        max-height: 80%;
    }
}
.user-list [data-slider-container] {
    position: relative;
    overflow: hidden;
    height: 5.5rem;
    border-bottom: solid 1px var(--dashboard-background);
  }
  
.user-list [data-slider-overlay] {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: -webkit-transform 400ms ease-out;
    transition: transform 400ms ease-out;
    transition: transform 400ms ease-out, -webkit-transform 400ms ease-out;
  }
.user-list [data-slider-overlay*="drag"] {
    transition: none;
    touch-action: pan-x;
  }
  
.user-list [data-slider-overlay="left"] {
    -webkit-transform: translate(45%);
            transform: translate(45%);
  }
  
.user-list [data-slider-left] {
    border: solid 3px var(--dashboard-background);
  }

  /* For iPads */
  @media (min-width: 48rem) and (orientation: portrait),
  (min-width: 64rem) and (orientation: landscape) {
    .user-list [data-slider-container] {
      height: 7rem;
    }
  }
aside.error-message {
    display: grid;
    grid-template-areas: "header"
                        "message"
                        "ok";
    grid-template-rows: 1fr auto 1fr;
    margin: 0;
    padding: 0;
    background-color: var(--dashboard-widget-background);
    border: solid 1rem var(--dashboard-background);
    border-top: none;
    border-bottom-left-radius: 1.2rem;
    border-bottom-right-radius: 1.2rem;
    position: absolute;
    width: 24rem;
    box-sizing: border-box;
    top: 0;
    left: 50vw;
    right: 50vw;
    margin-left: -12rem;
    margin-right: -12rem;
    min-height: 10rem;
    z-index: 999;
    box-shadow: 0 0 0 200vh rgba(0,0,0,.5), 0 0 .3rem black;
}
aside.confirm-message {
    display: grid;
    grid-template-areas: "header header"
                        "message message"
                        "cancel confirm";
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;
    margin: 0;
    padding: 0;
    background-color: var(--dashboard-widget-background);
    border: solid 1rem var(--dashboard-background);
    border-top: none;
    border-bottom-left-radius: 1.2rem;
    border-bottom-right-radius: 1.2rem;
    position: absolute;
    width: 24rem;
    box-sizing: border-box;
    top: 0;
    left: 50vw;
    right: 50vw;
    margin-left: -12rem;
    margin-right: -12rem;
    min-height: 10rem;
    z-index: 999;
    box-shadow: 0 0 0 200vh rgba(0,0,0,.5), 0 0 .3rem black;
}
aside.confirm-message button{
    -webkit-align-self: center;
            align-self: center;
}
aside.confirm-message button.cancel{
    grid-area: cancel;
    background-color: var(--dashboard-background);
}
aside.confirm-message button.confirm{
    grid-area: confirm;
}


aside.error-message::after {
    position: fixed;
    background-color: var(--dashboard-background);
    opacity: .05;
    width: 100vw;
    border-radius: 1rem;
    height: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
    z-index: -1;
}

aside.error-message h2 {
    grid-area: header;
    font-weight: 300;
    font-size: 1rem;
    letter-spacing: .1rem;
    text-align: center;
}

aside.error-message p {
    grid-area: message;
    text-align: center;
    max-width: 22rem;
}

aside.error-message p a {
    text-decoration: underline;
    cursor: pointer;
}
aside.error-message button.ok{
    grid-area: ok;
}
aside.confirm-message::after {
    position: fixed;
    background-color: var(--dashboard-background);
    opacity: .05;
    width: 100vw;
    border-radius: 1rem;
    height: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
    z-index: -1;
}

aside.confirm-message h2 {
    grid-area: header;
    font-weight: 400;
    font-size: 1rem;
    letter-spacing: .1rem;
    text-align: center;
}

aside.confirm-message p {
    grid-area: message;
    text-align: center;
}

aside.confirm-message p a {
    text-decoration: underline;
    cursor: pointer;
}

/* @media (max-width: 35rem) {
    aside.confirm-message,
    aside.error-message {
        
    }
} */


/* For iPads */
@media (min-width: 48rem) and (orientation: portrait),
        (min-width: 64rem) and (orientation: landscape) {
    aside.confirm-message,
    aside.error-message {
        margin-left: -18rem;
        margin-right: -18rem;
        left: 50%;
        right: 50%;
        width: 36rem;
        min-height: 20rem;
    }
    aside.confirm-message h2,
    aside.error-message h2 {
        font-size: 1.4rem;
    }
    aside.confirm-message p{
        font-size: 1.2rem;
    }
    aside.confirm-message button,
    aside.error-message button{
        height: 2.7rem;
        font-size: 1.3rem;
    }
    aside.error-message p {
        font-size: 1.2rem;
        max-width: -webkit-max-content;
        max-width: max-content;
    }
}
.metric-container {
    display: grid;
    grid-template-areas: "title info"
                         "metrics metrics";
    grid-template-columns: 1fr -webkit-max-content;
    grid-template-columns: 1fr max-content;
    background-color: var(--dashboard-background);
    background-color: #333;
    color: var(--dashboard-text-color);
    color: #F7F7F7;
    padding: .5rem;
    border-radius: .5rem;
    --border-radius-metric: .3rem;
}
.metric-container .title{
    grid-area: title;
    margin-bottom: .5rem;
    font-weight: 00;
}
.metric-container .info{
    grid-area: info;
    margin-bottom: .5rem;
    margin-right: .3rem;
    justify-self: end;
}
.metrics {
    grid-area: metrics;
    display: grid;
    grid-gap: .25rem;
    width: auto;
    grid-template-columns: repeat(12, minmax(1rem, 1fr));
    background-color: #333;
}
.metrics div { padding: .5rem; font-weight: 300; }

.metrics div:first-child {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.metrics.two-rows-three-metrics{
    grid-template-rows: repeat(2, 2rem);
}
.metrics.two-rows-three-metrics div:first-child {
    grid-area: 1 / 1 / 3 / 7;
    background-color: #CA0566;
    border-top-left-radius: var(--border-radius-metric);
    border-bottom-left-radius: var(--border-radius-metric);
}
.metrics.two-rows-three-metrics div:nth-child(2) {
    grid-area: 1 / 7 / 2 / 13;
    background-color: #FF7D46;
    color: var(--dashboard-background);
    border-top-right-radius: var(--border-radius-metric);
}
.metrics.two-rows-three-metrics div:nth-child(3) {
    grid-area: 2 / 7 / 3 / 13;
    background-color: #FFE458;
    color: var(--dashboard-background);
    border-bottom-right-radius: var(--border-radius-metric);
}

.metrics.three-rows-three-metrics {
    grid-template-rows: repeat(3, 2rem);
}
.metrics.three-rows-three-metrics div:first-child {
    grid-area: 1 / 1 / 3 / 13;
    background-color: #582261;
    border-top-left-radius: var(--border-radius-metric);
    border-top-right-radius: var(--border-radius-metric);
}
.metrics.three-rows-three-metrics div:nth-child(2) {
    grid-area: 3 / 1 / 4 / 7;
    background-color: #CA0566;
    border-bottom-left-radius: var(--border-radius-metric);
}
.metrics.three-rows-three-metrics div:nth-child(3) {
    grid-area: 3 / 7 / 4 / 13;
    background-color: #FF7D46;
    color: var(--dashboard-background);
    border-bottom-right-radius: var(--border-radius-metric);
}

.metrics.four-rows-four-metrics {
    grid-template-rows: repeat(4, 2rem);
}
.metrics.four-rows-four-metrics div:first-child {
    grid-area: 1 / 1 / 5 / 7;
    background-color: #582261;
    border-top-left-radius: var(--border-radius-metric);
    border-bottom-left-radius: var(--border-radius-metric);
}
.metrics.four-rows-four-metrics div:nth-child(2) {
    grid-area: 1 / 7 / 3 / 13;
    background-color: #CA0566;
    border-top-right-radius: var(--border-radius-metric);
}
.metrics.four-rows-four-metrics div:nth-child(3) {
    grid-area: 3 / 7 / 4 / 13;
    background-color: #FF7D46;
    color: var(--dashboard-background);
}
.metrics.four-rows-four-metrics div:nth-child(4) {
    grid-area: 4 / 7 / 5 / 13;
    background-color: #FFE458;
    color: var(--dashboard-background);
    border-bottom-right-radius: var(--border-radius-metric);
}

.metrics.one-row-two-metrics {
    grid-template-rows: repeat(1, 2rem);
}
.metrics.one-row-two-metrics div:first-child {
    grid-area: 1 / 1 / 1 / 7;
    background-color: #CA0566;
    border-top-left-radius: var(--border-radius-metric);
    border-bottom-left-radius: var(--border-radius-metric);
    
}
.metrics.one-row-two-metrics div:nth-child(2) {
    grid-area: 1 / 7 / 1 / 13;
    background-color: #582261;
    border-top-right-radius: var(--border-radius-metric);
    border-bottom-right-radius: var(--border-radius-metric);
}


.metrics.one-row-one-metric {
    grid-template-rows: repeat(1, 2rem);
}
.metrics.one-row-one-metric div:first-child {
    grid-area: 1 / 1 / 1 / 13;
    background-color: #582261;
    border-top-right-radius: var(--border-radius-metric);
    border-bottom-right-radius: var(--border-radius-metric);
    border-top-left-radius: var(--border-radius-metric);
    border-bottom-left-radius: var(--border-radius-metric);
}

/* Pie Chart */
.metric-container .pie {
    grid-area: metrics;
    display: grid;
    grid-template-areas: "chart info";
    grid-template-columns: 3fr 2fr;
    /* background-color: var(--dashboard-widget-background); */
    border-radius: var(--border-radius-metric);
    width: 100%;
    height: -webkit-min-content;
    height: min-content;
}
/* For iPads */
@media (min-width: 48rem) and (orientation: portrait),
        (min-width: 64rem) and (orientation: landscape) {
            .metric-container .title,
            .metric-container .info,
            .metrics {
                font-size: 1.2rem;
            }
        }

.metric-container .pie .chart {
    /* align-self: start; */
    grid-area: chart;
    display: inline-block;
}

.metric-container .pie .legend {
    grid-area: info;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    width: 100%;
}

.metric-container .pie .chart svg{
    height: 13rem;
}

.metric-container .pie .legend > ul > li {
    list-style: none;
}

.metric-container .pie .legend > ul > li:before {
    width: 1em;
    height: 1em;
    content: "";
    display: inline-block;
    margin-right: .25em;
    background-image: linear-gradient(180deg, #35505A 0%, #556970 100%);
    border-radius: .2em;
}

.metric-container .pie .legend > ul > li.C-FF7D46:before {
    background-image: linear-gradient(180deg, #FF7D46 0%, #FF7D46 100%);
}

.metric-container .pie .legend > ul > li.C-582261:before {
    background-image: linear-gradient(180deg, #582261 0%, #582261 100%);
}

.metric-container .pie .legend > ul > li.C-CA0566:before {
    background-image: linear-gradient(180deg, #CA0566 0%, #CA0566 100%);
}
.metric-container .pie .legend > ul > li.C-FFE458:before {
    background-image: linear-gradient(180deg, #FFE458 0%, #FFE458 100%);
}

.metric-container .pie .legend > ul > li.lowlight {
    opacity: .5;
}

ul.metric-navigation {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    border: .2rem solid var(--dashboard-background);
    background-color: var(--dashboard-background);
    border-radius: 6px 6px 0 0;
    list-style-type: none;
    width: 50%;
    justify-self: center;
    margin: 0 auto;
    padding: 0;
    font-weight: 300;
}

ul.metric-navigation > li{
    width: 100%;
    text-align: center;
    opacity: .5;
    cursor: pointer;
    font-size: 100;
    background-color: var(--dashboard-background);
}

ul.metric-navigation > li.selected{
    font-weight: 400;
    opacity: 1;
}


@media (max-width: 35rem) {
    .metric-container .pie {
        grid-template-areas: "chart" "info";
        grid-template-columns: 1fr;
        grid-template-rows: 2fr 1fr;
        height: 18rem;
    }
    .metric-container .pie .legend {
        -webkit-align-items: center;
                align-items: center;
    }
    .metric-container .pie .legend ul {
        padding: 0;
        margin: 0;
    }
    .metric-container .pie .legend ul > li {
        display: inline-block;
        margin-right: 1rem;
    }
}




ul.dates {
    display: -webkit-flex;
    display: flex;
    margin: .5rem 1rem;
    padding: 0;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    border: .2rem solid var(--dashboard-background);
    border-radius: 6px;
    list-style-type: none;
    grid-gap: .2rem;
    gap: .2rem;
    height: -webkit-min-content;
    height: min-content;
}
ul.dates li {
    width: 100%;
    margin: .1rem;
    background-color: var(--dashboard-background);
    border-radius: 3px;
    color: var(--dashboard-text-color);
    border: .05rem solid var(--dashboard-box-border);
    -webkit-justify-content: center;
            justify-content: center;
    padding: .2rem .4rem;
    text-align: center;
    cursor: pointer;
}

ul.dates.loading > li {
    opacity: .5;
    pointer-events: none;
}

ul.dates li.selected {
    background-color: var(--button-action-color);
}

/* For iPads */
@media (min-width: 48rem) and (orientation: portrait),
        (min-width: 64rem) and (orientation: landscape) {
            ul.dates {
                font-size: 1.2rem;
            }
        }
main.farm-metrics {
    position: relative;
    margin: 0;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    background-color: var(--dashboard-widget-background);
    z-index: 100;
    display: grid;
    grid-row-gap: 2rem;
    padding-left: 0;
    grid-template-areas: "header close-button"
                         "body body";
    grid-template-columns: auto 4rem;
    grid-template-rows: 3rem 1fr;
    box-shadow: 0 0 0 150vmax rgba(0, 0, 0, .5);
    overflow: hidden;
}
main.farm-metrics .close-button{
    grid-area: close-button;
    --close: url(/static/media/CloseButton.be369177.svg);
    background-image: var(--close);
    background-position: right;
    background-size: 2.8rem;
    background-repeat: no-repeat;
    margin: 0;
    padding: 0;
    margin-top: 1rem;
    padding-top: 2.8rem;
    margin-right: 1rem;
    margin-left: .2rem;
    cursor: pointer;
}
main.farm-metrics h1.header{
    grid-area: header;
    margin: 0;
    align-self: start;
    font-size: 2rem;
    padding-top: 1rem;
    padding-left: 1rem;
    font-weight: 300;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
main.farm-metrics > .body {
    grid-area: body;
    margin: 0 .5rem 0 .5rem;
    overflow-y: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    /* display: grid; */
}
main.farm-metrics > .body > div {
    margin-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 3px solid var(--dashboard-background);
}
main.farm-metrics > .body::-webkit-scrollbar{
    display: none;
}

main.farm-metrics > .body > div > h3 {
    color: var(--dashboard-text-color);
    text-decoration: none;
    font-size: 1rem;
    font-weight: 300;
    padding: 0 .5rem 0 .5rem;
}

main.farm-metrics > .body > div > h3 > span {
    display: block;
}

main.farm-metrics > .body > div > h3 > span:first-child {
    margin-bottom: 1rem;
}

main.farm-metrics > .body > p {
    margin-left: 1rem;
}

main.farm-metrics p.error{
    opacity: .6;
    font-weight: 300;
}

@media (min-width: 52rem) {
    main.farm-metrics {
        width: 40rem;
        top: -.8rem;
        height: 56.5rem;
        border-radius: 1rem 0 0 1rem;
    }
    main.farm-metrics  > .farms > .farm-list{
        height: 42.7rem;
        border-radius: 0 0 0 1rem;
    }
}

/* For iPads */
@media (min-width: 48rem) and (orientation: portrait),
        (min-width: 64rem) and (orientation: landscape) {
            main.farm-metrics {
                width: 100%;
                height: 100vh;
                top: 0;
                border-radius: 0;
                grid-template-rows: 4rem 1fr;
            }
            main.farm-metrics .close-button{
                background-size: 3rem;
                margin-top: 1.5rem;
                margin-left: 0rem;
            }
            main.farm-metrics h1.header{
                font-size: 2.5rem;
            }
            main.farm-metrics > .body > div > h3,
            main.farm-metrics > .body > p {
                font-size: 1.2rem;
            }
        }
main.health-report {
    position: relative;
    margin: 0;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    background-color: var(--dashboard-widget-background);
    z-index: 100;
    display: grid;
    grid-row-gap: 2rem;
    padding-left: 0;
    grid-template-areas:
        "header close-button"
        "body body";
    grid-template-columns: auto 4rem;
    grid-template-rows: 3rem 1fr;
    box-shadow: 0 0 0 150vmax rgba(0, 0, 0, 0.5);
    overflow: hidden;
}
main.health-report .close-button {
    grid-area: close-button;
    --close: url(/static/media/CloseButton.be369177.svg);
    background-image: var(--close);
    background-position: right;
    background-size: 2.8rem;
    background-repeat: no-repeat;
    margin: 0;
    padding: 0;
    margin-top: 1rem;
    padding-top: 2.8rem;
    margin-right: 1rem;
    margin-left: 0.2rem;
    cursor: pointer;
}
main.health-report h1.header {
    grid-area: header;
    margin: 0;
    align-self: start;
    font-size: 2rem;
    padding-top: 1rem;
    padding-left: 1rem;
    font-weight: 300;
    white-space: nowrap;
    text-overflow: ellipsis;
}
main.health-report > .body {
    grid-area: body;
    margin: 0 0.5rem 0 0.5rem;
    overflow-y: auto;
}
main.health-report > .body > div {
    margin-top: 1rem;
}
main.health-report > .body > .report-list {
    overflow-x: hidden;
    overflow-y: auto;
}
main.health-report > .body::-webkit-scrollbar,
main.health-report > .body > .report-list::-webkit-scrollbar {
    display: none;
}

main.health-report > .body > p {
    margin-left: 1rem;
}
main.health-report p.error {
    opacity: 0.6;
    font-weight: 300;
}

ul.report-list {
    padding: 0;
    margin: 0;
    overflow-y: auto;
    overflow-x: hidden;
    border-bottom: solid 2px var(--dashboard-background);
    height: 100%;
}

ul.report-list > li {
    position: relative;
    display: grid;
    grid-template-areas: "name pre-partum post-partum estrous";
    grid-template-columns: 20% 1fr 1fr 1fr;
    grid-column-gap: 0.5rem;
    -webkit-column-gap: 0.5rem;
            column-gap: 0.5rem;
    height: -webkit-min-content;
    height: min-content;

    background-color: var(--dashboard-widget-background);
    padding: 0.5em 0.5rem;
    border-left: solid 10px var(--dashboard-widget-background);
    border-right: solid 10px var(--dashboard-widget-background);
    border-bottom: solid 1px var(--dashboard-background);
}
ul.report-list > li.columns:first-child > span:first-child {
    grid-area: name;
}
ul.report-list > li.columns:first-child > span:nth-child(2) {
    grid-area: pre-partum;
}
ul.report-list > li.columns:first-child > span:nth-child(3) {
    grid-area: post-partum;
}
ul.report-list > li.columns:first-child > span:nth-child(4) {
    grid-area: estrous;
}

ul.report-list > li.columns > span {
    display: -webkit-flex;
    display: flex;
    justify-self: center;
    font-weight: 400;
    text-align: center;
    line-height: 100%;
}

ul.report-list > li.report-item:nth-child(2) {
    border-top: solid 2px var(--dashboard-background);
}
ul.report-list > li.report-item > h3 {
    grid-area: name;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin: 0;
    padding: 0;
}
ul.report-list > li.report-item > h3 > span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 300;
}
ul.report-list > li.report-item > h3 > strong {
    font-weight: 900;
}
ul.report-list > li.report-item > div.pre-partum {
    grid-area: pre-partum;
}
ul.report-list > li.report-item > div.post-partum {
    grid-area: post-partum;
}
ul.report-list > li.report-item > div.estrous {
    grid-area: estrous;
}

ul.report-list > li.report-item > div {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    grid-row-gap: 0.3em;
    row-gap: 0.3em;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

ul.report-list > li.report-item > .pre-partum > label,
ul.report-list > li.report-item > .post-partum > label,
ul.report-list > li.report-item > .estrous > label {
    display: block;
    border-radius: 0.3rem;
    min-width: 3.4rem;
    max-width: 5rem;
    height: 1.8rem;
    line-height: 1.8rem;
    text-align: center;
    /* justify-content: center; */
    background: linear-gradient(180deg, #f32c2c 0%, #bc4040 100%);
    font-weight: 400;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    overflow-x: hidden;
    text-overflow: ellipsis;
    direction: rtl;
    white-space: nowrap;
}

ul.report-list > li.report-item label strong {
    font-weight: 900;
}

@media (min-width: 52rem) {
    main.health-report {
        width: 40rem;
        top: -0.8rem;
        height: 56.5rem;
        border-radius: 1rem 0 0 1rem;
    }
}

main.potential-calvings {
    display: grid;
    grid-template-areas:
        'header'
        'body'
        'footer';
    grid-template-rows: 6rem 1fr 6rem;
    transition: none;
    height: 100vh;
    background-color: var(--dashboard-widget-background);
}

main.potential-calvings > .header {
    grid-area: header;
    display: grid;
    grid-template-areas: 'head close-button';
    grid-template-columns: 3fr 1fr;
    padding: 1rem;
}

main.potential-calvings > .header h1,
main.potential-calvings > .header h5 {
    grid-area: head;
    margin: 0;
    font-weight: 300;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

main.potential-calvings > .header > .close-button {
    grid-area: close-button;
    --close: url(/static/media/CloseButton.be369177.svg);
    background-image: var(--close);
    background-position: right;
    background-size: 2.8rem;
    background-repeat: no-repeat;
    cursor: pointer;
    width: 2.8rem;
    height: 2.8rem;
    justify-self: end;
}

main.potential-calvings > .potential-list {
    grid-area: body;
    background-color: var(--dashboard-background);
    overflow-y: auto;
}

main.potential-calvings > .potential-list > ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
main.potential-calvings > .potential-list > ul > li {
    display: grid;
    grid-template-areas: "check cow sensor"
                         "check tag sensor";
    grid-template-columns: 5rem 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    background-color: var(--dashboard-widget-background);
    border-top: solid 2px var(--dashboard-background);
    /* border-bottom: solid 2px var(--dashboard-background); */
    --row-height: 5rem;
    height: var(--row-height);
}
main.potential-calvings > .potential-list > ul > li.paired {
    opacity: 0.5;
}
main.potential-calvings > .potential-list > ul > li.paired > p {
    -webkit-align-self: center;
            align-self: center
}
main.potential-calvings > .potential-list > ul > li > p {
    margin: 0;
    -webkit-align-self: flex-end;
            align-self: flex-end;
    /* line-height: var(--row-height); */
}
main.potential-calvings > .potential-list > ul > li > div.tag {
    grid-area: tag;
    font-size: .9em;
    margin-top: .2em;
}
main.potential-calvings > .potential-list > ul > li > div.tag > strong {
    font-weight: 400;
} 
main.potential-calvings > .potential-list > ul > li > div.tag.pregnant::before {
    width: 1em;
    height: 1em;
    content: "\00a0";
    display: inline-block;
    margin-right: .25em;
    background-image: var(--good-cow);
    border-radius: .2em;
    vertical-align: middle;
    margin-bottom: .3em;
}
main.potential-calvings > .potential-list > ul > li > div.tag > span {
    font-weight: 300;
    margin-left: .3rem;
}
main.potential-calvings > .potential-list > ul > li > .sensor {
    grid-area: sensor;
    align-self: center;
    opacity: 0.5;
    font-weight: 300;
}

main.potential-calvings > .potential-list > ul > li > .sensor > p {
    margin: 0;
}

main.potential-calvings > .potential-list > ul > li:before {
    grid-area: check;
    content: '';
    display: inline-block;
    --size: 2rem;
    width: var(--size);
    height: var(--size);
    border: solid 1px var(--dashboard-text-color);
    border-radius: 0.4rem;
    margin-left: 1.6rem;
    margin-top: calc((var(--row-height) - var(--size)) / 2);
}
main.potential-calvings > .potential-list > ul > li.paired:before,
main.potential-calvings > .potential-list > ul > li.unavailable:before {
    border: none;
}
main.potential-calvings > .potential-list > ul > li.in-list:before {
    background-color: var(--checked-color);
}
main.potential-calvings > .footer {
    display: grid;
    justify-items: center;
    -webkit-align-items: center;
            align-items: center;
    border-top: solid 2px var(--dashboard-background);
}

main.potential-calvings > .footer > button {
    width: 90%;
    margin: 0;
}
main.potential-calvings > .footer > button.disabled {
    opacity: 0.5;
}
@media (min-width: 35rem) {
    main.potential-calvings {
        width: 40rem;
        top: -0.8rem;
        height: 56.5rem;
        max-height: 90vh;
        overflow-y: auto;
        box-shadow: 0 0 0 150vmax rgba(0, 0, 0, 0.5);
        border-radius: 1rem 0 0 1rem;
    }
}

div.tippy-popper.shepherd,
div.tippy-tooltip {
    background: transparent;
    border-radius: 0.5rem;
    overflow: visible;
}
div#tippy-45.tippy-tooltip,
div#tippy-47.tippy-tooltip,
div#tippy-48.tippy-tooltip {
    max-width: 520px !important;
}
.shepherd-content {
    background-color: var(--dashboard-background);
    font: inherit;
    font-size: 1rem;
    padding: 1rem;
    border: solid 0.1rem var(--signal-color);
    border-radius: 0.5rem;
    color: var(--dashboard-text-color);
    box-shadow: 0 0 0 0.5rem #0009;
}

button.shepherd-button {
    background-color: var(--dashboard-widget-background);
    color: var(--dashboard-text-color);
    font: inherit;
    border: none;
    border-radius: 0.25rem;
    box-sizing: border-box;
    min-height: 3rem;
    padding: 0.5rem 1rem;
    margin-right: 1rem;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    white-space: nowrap;
}
button.shepherd-button.disabled {
    opacity: 0.5;
    pointer-events: none !important;
}
button.shepherd-button:last-child {
    margin-right: 0;
}

button.shepherd-button-primary {
    background-color: var(--button-action-color);
}

footer.shepherd-footer {
    margin-top: 1rem;
    display: -webkit-flex;
    display: flex;
}

.shepherd-modal-target.shepherd-enabled.shepherd-target {
    outline: dashed 2px var(--signal-color);
    outline-offset: 4px;
}

.shepherd-modal-target.shepherd-enabled.shepherd-target:not(.can-click) * {
    pointer-events: none !important;
}

.shepherd-modal-target.shepherd-enabled.shepherd-target.can-click {
    pointer-events: all !important;
    z-index: 300;
}
@media (pointer: fine) {
    button.shepherd-button {
        min-height: 2rem;
    }
}
@media (pointer: coarse) and (max-width: 35rem) {
    .tippy-tooltip footer.shepherd-footer {
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
        grid-gap: 1rem;
        gap: 1rem;
    }
    .tippy-tooltip button.shepherd-button {
        margin-right: 0;
    }
}
.shepherd.tippy-popper[x-placement^="top"] .tippy-tooltip .tippy-arrow {
    border-top-color: var(--signal-color);
}
.shepherd.tippy-popper[x-placement^="bottom"] .tippy-tooltip .tippy-arrow {
    border-bottom-color: var(--signal-color);
}
.shepherd.tippy-popper[x-placement^="left"] .tippy-tooltip .tippy-arrow {
    border-left-color: var(--signal-color);
}
.shepherd.tippy-popper[x-placement^="right"] .tippy-tooltip .tippy-arrow {
    border-right-color: var(--signal-color);
}

